export default {
  KUNCIE_WEB: process.env.VUE_APP_URL || 'https://www.kuncie.com',
  KUNCIE_WEB_LEARN: process.env.VUE_APP_LEARN_URL || 'https://learn.kuncie.com',
  KUNCIE_PREMIUM: process.env.VUE_APP_KUNCIE_PREMIUM_URL,
  KUNCIE_WEB_ASSETS: process.env.VUE_APP_ASSETS_URL,
  PIXEL_NINJA: 'https://pixelninja.id/',
  EMAIL_SUPPORT: 'mailto:support@kuncie.com',
  SUBMIT_IDEA: 'https://kuncie-user.ideas.aha.io/',
  WHATSAPP_SUPPORT: 'https://wa.me/6281197408888',
  DOWNLOAD_APP: 'https://app.adjust.com/j8mph5o?campaign=Homepage',
};
