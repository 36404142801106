import { IOTPStatus } from '@/interfaces/premium/AuthInterface';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  authId: string;
  phoneNumber: string;
  accessToken: string;
  refreshToken: string;
  status: LoadState;
  statusOTP: IOTPStatus;
}

export default {
  authId: '',
  phoneNumber: '',
  accessToken: '',
  refreshToken: '',
  status: 'idle',
  statusOTP: IOTPStatus.IDLE,
} as State;
