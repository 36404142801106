import type { State } from './state';

export default {
  certificateList(state: State) {
    return state.certificateList;
  },
  hasCertificates(state: State) {
    return state.certificateList.length > 0;
  },
  isLoading(state: State) {
    return state.loadState === 'loading';
  },
  isSuccess(state: State) {
    return state.loadState === 'success';
  },
};
