import numberToMoney from '@/utils/helpers/number-to-money';
import type { State } from './state';

export default {
  getCartInfo(state: State) {
    return state.cartInfo;
  },
  getCartItems(state: State) {
    return state.cartInfo.items ?? [];
  },
  getCartItemsProductIds(state: State) {
    return state.cartInfo.items.map(item => item.product_id) ?? [];
  },
  getCartItemsTotalItems(state: State) {
    return state.cartInfo.summary.total_items ?? 0;
  },
  getCartItemsTotalProductPrice(state: State) {
    return state.cartInfo.summary.total_product_price.value;
  },
  getCartItemsTotalProductPriceIdr(state: State, getters: any) {
    return getters.getCartItemsTotalProductPrice > 0 ? numberToMoney('IDR', getters.getCartItemsTotalProductPrice) : 0;
  },
  getCartItemsTotalNetPrice(state: State) {
    return state.cartInfo.summary.total_net_price.value;
  },
  getCartItemsTotalNetPriceIdr(state: State, getters: any) {
    return getters.getCartItemsTotalNetPrice > 0 ? numberToMoney('IDR', getters.getCartItemsTotalNetPrice) : 0;
  },
  getCartItemsTotalStrikethroughPrice(state: State) {
    return state.cartInfo.summary.total_strikethrough_price.value;
  },
  getCartItemsTotalStrikethroughPriceIdr(state: State, getters: any) {
    return getters.getCartItemsTotalStrikethroughPrice > 0
      ? numberToMoney('IDR', getters.getCartItemsTotalStrikethroughPrice)
      : 0;
  },
  getCartItemsTotalCouponDiscount(state: State) {
    return state.cartInfo.summary.total_coupon_discount.value;
  },
  getCartItemsTotalCouponDiscountIdr(state: State, getters: any) {
    return getters.getCartItemsTotalCouponDiscount > 0
      ? numberToMoney('IDR', getters.getCartItemsTotalCouponDiscount)
      : 0;
  },
  // ORDER
  getOrderData(state: State) {
    return state.orderData;
  },
  getOrderItems(state: State) {
    return state.orderData.items ?? [];
  },
  getOrderItemsProductIds(state: State) {
    return state.orderData.items.map(item => item.product_id) ?? [];
  },
  getOrderItemsTotalItems(state: State) {
    return state.orderData.summary.total_items ?? 0;
  },
  getOrderItemsTotalProductPrice(state: State) {
    return state.orderData.summary.total_product_price.value;
  },
  getOrderItemsTotalProductPriceIdr(state: State, getters: any) {
    return getters.getOrderItemsTotalProductPrice > 0
      ? numberToMoney('IDR', getters.getOrderItemsTotalProductPrice)
      : 0;
  },
  getOrderItemsTotalNetPrice(state: State) {
    return state.orderData.summary.total_net_price.value;
  },
  getOrderItemsTotalNetPriceIdr(state: State, getters: any) {
    return getters.getOrderItemsTotalNetPrice > 0 ? numberToMoney('IDR', getters.getOrderItemsTotalNetPrice) : 0;
  },
  getOrderItemsTotalStrikethroughPrice(state: State) {
    return state.orderData.summary.total_strikethrough_price.value;
  },
  getOrderItemsTotalStrikethroughPriceIdr(state: State, getters: any) {
    return getters.getOrderItemsTotalStrikethroughPrice > 0
      ? numberToMoney('IDR', getters.getOrderItemsTotalStrikethroughPrice)
      : 0;
  },
  getOrderItemsTotalCouponDiscount(state: State) {
    return state.orderData.summary.total_coupon_discount.value;
  },
  getOrderItemsTotalCouponDiscountIdr(state: State, getters: any) {
    return getters.getOrderItemsTotalCouponDiscount > 0
      ? numberToMoney('IDR', getters.getOrderItemsTotalCouponDiscount)
      : 0;
  },
  getLoadState(state: State) {
    return state.loadState;
  },
  isLoading(state: State) {
    return state.loadState === 'loading';
  },
};
