import { AxiosError } from 'axios';

type Errors = {
  message: string;
  path: string[];
  extensions: { code: string };
};

type ErrorResponse = {
  errors: Errors[];
};

export default (
  error: AxiosError | ErrorResponse | any | any[] | Error | null,
  defaultErrorMessage = 'Terjadi kesalahan, silakan coba beberapa saat lagi.',
): string => {
  if (error && error.response.data.message) {
    return `${error.response.data.message}`
  }
  if (error && error instanceof Error) {
    return (error as Error).message || defaultErrorMessage;
  }
  return defaultErrorMessage;
};

// TODO: need to find out how to extract error code
