import type {
  Mentor,
  MentorSearch,
  PaginationResponse,
  SearchProductCatalogItem,
} from '@kunciehub/api-v2-web-js-client';
import type { State, Status } from './state';

export const SET_MENTOR = 'SET_MENTOR';
export const SET_MENTOR_LIST = 'SET_MENTOR_LIST';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_STATUS = 'SET_STATUS';
export const SET_MENTOR_PRODUCTS = 'SET_MENTOR_PRODUCTS';

export default {
  [SET_MENTOR](state: State, payload: Mentor) {
    state.mentor = payload;
  },
  [SET_MENTOR_LIST](state: State, payload: Array<MentorSearch>) {
    state.mentorList = payload;
  },
  [SET_PAGINATION](state: State, payload: PaginationResponse) {
    state.pagination = payload;
  },
  [SET_STATUS](state: State, payload: Status) {
    state.status = payload;
  },
  [SET_MENTOR_PRODUCTS](state: State, payload: Array<SearchProductCatalogItem>) {
    state.mentorProducts = payload;
  },
};
