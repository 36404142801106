import { IStatus } from '@/interfaces/premium/StatusInterface';
import type { State } from './state';

export default {
  getOrders(state: State) {
    return state.orders;
  },
  getDetailOrder(state: State) {
    return state.order;
  },
  isLoading(state: State) {
    return state.status === 'loading';
  },
  isSuccess(state: State) {
    return state.status === 'success';
  },
  isCreateOrderLoading(state: State) {
    return state.createOrderStatus === 'loading';
  },
  isCreateOrderSuccess(state: State) {
    return state.createOrderStatus === 'success';
  },
  paymentUrl(state: State) {
    return state.checkOrderInvoice?.payment_url;
  },
  isCheckOrderCompleted(state: State) {
    return state.checkOrderInvoice?.is_completed;
  },
  isCheckOrderLoading(state: State) {
    return state.checkOrderStatus === 'loading';
  },
  getPagination(state: State) {
    return state.pagination;
  },
  isLoadCreatePayment(state: State) {
    return state.createPaymentStatus === IStatus.LOADING;
  },
};
