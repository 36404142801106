/* eslint-disable object-curly-newline */
import { useToast } from 'vue-toastification';
import { Commit, Dispatch } from 'vuex';
import { ISetUserInfo } from '@/interfaces/premium/UserInterface';
import { showErrorMessage } from '@/utils/helpers/constant-api';
import { getAccessToken, getRefreshToken, getTokenId } from '@/utils/helpers/token';
import userApi from '@/api/modules/user';
import tracker from '@/utils/helpers/tracker';
import { setupAPI } from '@/plugins/api';
import { setUserEmail, setUserPhone } from '@/utils/helpers/identity';
import { SET_REGISTERED_USER, SET_STATUS, SET_USER_META, SET_USER_PROFILE } from './mutations';

const api = setupAPI();
const toast = useToast();

export default {
  async createUserMeta({ commit }: { commit: Commit }) {
    commit(SET_STATUS, 'loading');
    try {
      const res = await userApi.createUserMeta();
      const { user_id, user_info } = res.data.data;
      localStorage.setItem('userId', user_id);
      commit(SET_USER_META, res.data.data);
      commit(SET_USER_PROFILE, user_info);
      commit(SET_REGISTERED_USER, user_info.name !== '' || user_info.billing_email !== '');
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      toast.error(showErrorMessage(error.status));
      commit(SET_STATUS, 'error');
      throw error;
    }
  },
  async setUserInfo({ commit }: { commit: Commit }, payload: ISetUserInfo) {
    commit(SET_STATUS, 'loading');
    try {
      await userApi.setUserInfo(payload);
      tracker.track('register_click', {
        email: payload.billing_email,
        nama_lengkap: payload.name,
        register_status: 'success',
        error_message: null,
      });
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      toast.error(showErrorMessage(error.status));
      tracker.track('register_click', {
        email: payload.billing_email,
        nama_lengkap: payload.name,
        register_status: 'failed',
        error_message: showErrorMessage(error.status),
      });
      commit(SET_STATUS, 'error');
      throw error;
    }
  },
  async getUser({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    const token = getAccessToken();
    const refreshToken = getRefreshToken();
    const tokenId = getTokenId();

    if (!token && !refreshToken && !tokenId) return;
    dispatch(
      'auth/changeToken',
      {
        token,
        refreshToken,
        tokenId,
      },
      { root: true },
    );

    const { data: authMeResponse } = await api.auths.authMe();

    commit(SET_USER_META, {
      user_info: authMeResponse.data,
    });
    commit(SET_USER_PROFILE, authMeResponse.data);
    commit(SET_REGISTERED_USER, true);

    localStorage.setItem('userId', authMeResponse.data?.user_id || '');

    setUserEmail(authMeResponse.data?.email || authMeResponse.data?.billing_email || '')
    setUserPhone(authMeResponse.data?.phone_number || '')
  },
  async onboardMe() {
    await api.onboards.onboardMe();
  },
};
