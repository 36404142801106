import { useToast } from 'vue-toastification';
import { Commit } from 'vuex';
import { setupAPI } from '@/plugins/api';
import { SET_CERTIFICATE_LIST, SET_LOAD_STATE } from '@/store/premium/modules/myCertificates/mutations';
import errorToString from '@/utils/helpers/error-to-string';

const api = setupAPI();
const toast = useToast();

export default {
  async fetchMyCertificates({ commit }: { commit: Commit }) {
    try {
      commit(SET_LOAD_STATE, 'loading');
      const results = await api.myCertificates.getAllMyCertificates();
      const { data } = results.data;
      commit(SET_CERTIFICATE_LIST, data);
      commit(SET_LOAD_STATE, 'success');
    } catch (error) {
      toast.error(errorToString(error));
      commit(SET_LOAD_STATE, 'error');
    }
  },
};
