import {
  SearchOrdersItem,
  PaginationResponseV2,
  GetOrderByIdData,
  CheckStatusOrderData,
} from '@kunciehub/api-v2-web-js-client';
import { Status } from '@/interfaces/premium/StatusInterface';
import type { State } from './state';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER = 'SET_ORDER';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_STATUS = 'SET_STATUS';
export const SET_CREATE_ORDER_STATUS = 'SET_CREATE_ORDER_STATUS';
export const SET_CHECK_ORDER_INVOICE = 'SET_CHECK_ORDER_INVOICE';
export const SET_CHECK_ORDER_STATUS = 'SET_CHECK_ORDER_STATUS';
export const SET_CREATE_PAYMENT_STATUS = 'SET_CREATE_PAYMENT_STATUS';

export default {
  [SET_ORDERS](state: State, payload: Array<SearchOrdersItem>) {
    state.orders = payload;
  },
  [SET_ORDER](state: State, payload: GetOrderByIdData) {
    state.order = payload;
  },
  [SET_PAGINATION](state: State, payload: PaginationResponseV2) {
    state.pagination = payload;
  },
  [SET_STATUS](state: State, payload: Status) {
    state.status = payload;
  },
  [SET_CREATE_ORDER_STATUS](state: State, payload: Status) {
    state.createOrderStatus = payload;
  },
  [SET_CHECK_ORDER_INVOICE](state: State, payload: CheckStatusOrderData) {
    state.checkOrderInvoice = payload;
  },
  [SET_CHECK_ORDER_STATUS](state: State, payload: Status) {
    state.checkOrderStatus = payload;
  },
  [SET_CREATE_PAYMENT_STATUS](state: State, payload: Status) {
    state.createPaymentStatus = payload;
  },
};
