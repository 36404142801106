import { RouteRecordRaw } from 'vue-router';
import {
  UNIFIED_LOGIN,
  UNIFIED_LOGIN_MOOC,
  UNIFIED_LOGOUT,
  NOT_FOUND,
  UNIFIED_FAQ,
  UNIFIED_FAQ_DETAIL,
  UNIFIED_FAQ_SEARCH_RESULT,
  UNIFIED_DETAIL_CERTIFICATE,
  UNIFIED_AUTH_CALLBACK,
  UNIFIED_QUIZ_CALLBACK,
} from './type';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: UNIFIED_LOGIN },
  },
  {
    path: '/login',
    name: UNIFIED_LOGIN,
    component: () => import('@/views/premium/authentication/Login.vue'),
  },
  {
    path: '/login/:client',
    redirect: { name: UNIFIED_LOGIN },
  },
  {
    path: '/login/:client/:code',
    name: UNIFIED_LOGIN_MOOC,
    component: () => import('@/views/premium/authentication/Login.vue'),
  },
  {
    path: '/logout',
    name: UNIFIED_LOGOUT,
    component: () => import('@/views/premium/authentication/Logout.vue'),
  },
  {
    path: '/auth-callback',
    name: UNIFIED_AUTH_CALLBACK,
    component: () => import('@/views/premium/authentication/AuthCallback.vue'),
  },
  {
    path: '/certificate/:id',
    name: UNIFIED_DETAIL_CERTIFICATE,
    component: () => import('@/modules/dashboard/certificate/CertificateDetail.vue'),
  },
  {
    path: '/quiz-callback',
    name: UNIFIED_QUIZ_CALLBACK,
    component: () => import('@/modules/dashboard/learning/QuizCallback.vue'),
  },
  {
    path: '/faq',
    name: UNIFIED_FAQ,
    component: () => import('@/views/premium/faq/Faq.vue'),
  },
  {
    path: '/faq/search',
    name: UNIFIED_FAQ_SEARCH_RESULT,
    component: () => import('@/views/premium/faq/SearchResult.vue'),
  },
  {
    path: '/faq/:id',
    name: UNIFIED_FAQ_DETAIL,
    component: () => import('@/views/premium/faq/FaqDetail.vue'),
  },
  {
    path: '/not-found',
    name: NOT_FOUND,
    component: () => import('@/views/common/NotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: NOT_FOUND },
  },
];

const unifiedRoutes = routes.filter(val => !val.meta?.disabled);

export default unifiedRoutes;
