import type { Category, GetAllCategoriesItem } from '@kunciehub/api-v2-web-js-client';
import type { State, Status } from './state';

export const SET_DATA_CATEGORY = 'SET_DATA_CATEGORY';
export const SET_DATA_LEVEL = 'SET_DATA_LEVEL';
export const SET_STATUS = 'SET_STATUS';

export default {
  [SET_DATA_CATEGORY](state: State, payload: GetAllCategoriesItem[]) {
    state.dataCategories = payload ?? [];
  },
  [SET_DATA_LEVEL](state: State, payload: Category[]) {
    state.dataLevel = payload;
  },
  [SET_STATUS](state: State, payload: Status) {
    state.status = payload;
  },
};
