import { Status } from '@/interfaces/premium/StatusInterface';
import {
  SearchOrdersItem,
  PaginationResponseV2,
  GetOrderByIdData,
  CheckStatusOrderData,
} from '@kunciehub/api-v2-web-js-client';

export interface State {
  orders: SearchOrdersItem[];
  order: GetOrderByIdData | undefined;
  pagination: PaginationResponseV2;
  status: Status;
  createOrderStatus: Status;
  checkOrderInvoice: CheckStatusOrderData | undefined;
  checkOrderStatus: Status;
  createPaymentStatus: Status;
}

export default {
  orders: [],
  order: undefined,
  pagination: {
    limit: 0,
    offset: 0,
    total: 0,
  },
  status: 'idle',
  checkOrderInvoice: undefined,
  createOrderStatus: 'idle',
  checkOrderStatus: 'idle',
  createPaymentStatus: 'idle',
} as State;
