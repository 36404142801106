import { createStore } from 'vuex';
import auth from '@/store/premium/modules/auth';
import cart from '@/store/premium/modules/cart';
import category from '@/store/premium/modules/category';
import checkout from '@/store/premium/modules/checkout';
import course from '@/store/premium/modules/course';
import mentor from '@/store/premium/modules/mentor';
import myCertificates from '@/store/premium/modules/myCertificates';
import myCourses from '@/store/premium/modules/myCourses';
import productCatalog from '@/store/premium/modules/productCatalog';
import orders from '@/store/premium/modules/orders';
import user from '@/store/premium/modules/user';

export default createStore({
  modules: {
    auth,
    cart,
    category,
    checkout,
    course,
    mentor,
    myCertificates,
    myCourses,
    orders,
    productCatalog,
    user,
  },
});
