import type {
  GetDocumentByIdResponse,
  GetQuizByContentIdResponse,
  GetWebinarByIdResponse,
  MyCourse,
  MyCoursesInfo,
  MyCoursesProgress,
  PaginationResponse,
  V2MyCourse,
  VideoPlayback,
} from '@kunciehub/api-v2-web-js-client';
import type { LoadState, State } from './state';

export const SET_LATEST_COURSE_LIST = 'SET_LATEST_COURSE_LIST';
export const SET_LOADING_UPDATE_PROGRESS = 'SET_LOADING_UPDATE_PROGRESS';
export const SET_LOAD_ALL_COURSES_STATE = 'SET_LOAD_ALL_COURSES_STATE';
export const SET_LOAD_COURSE_INFO_STATE = 'SET_LOAD_COURSE_INFO_STATE';
export const SET_LOAD_COURSE_PROGRESS_STATE = 'SET_LOAD_COURSE_PROGRESS_STATE';
export const SET_LOAD_COURSE_VIDEO_STATE = 'SET_LOAD_COURSE_VIDEO_STATE';
export const SET_LOAD_LATEST_COURSES_STATE = 'SET_LOAD_LATEST_COURSES_STATE';
export const SET_LOAD_QUIZ_INFO_STATE = 'SET_LOAD_QUIZ_INFO_STATE';
export const SET_LOAD_LIVE_SESSION_INFO_STATE = 'SET_LOAD_LIVE_SESSION_INFO_STATE';
export const SET_LOAD_CASE_STUDY_INFO_STATE = 'SET_LOAD_CASE_STUDY_INFO_STATE';
export const SET_FINISH_COURSE_STATUS = 'SET_FINISH_COURSE_STATUS';

export const SET_MY_COURSE_DETAIL = 'SET_MY_COURSE_DETAIL';
export const SET_MY_COURSE_LIST = 'SET_MY_COURSE_LIST';
export const SET_MY_COURSE_PAGINATION = 'SET_MY_COURSE_PAGINATION';
export const SET_MY_COURSE_PROGRESS = 'SET_MY_COURSE_PROGRESS';
export const SET_MY_COURSE_VIDEO = 'SET_MY_COURSE_VIDEO';
export const SET_MY_COURSE_QUIZ_INFO = 'SET_MY_COURSE_QUIZ_INFO';
export const SET_MY_COURSE_LIVE_SESSION_INFO = 'SET_MY_COURSE_LIVE_SESSION_INFO';
export const SET_MY_COURSE_CASE_STUDY_INFO = 'SET_MY_COURSE_CASE_STUDY_INFO';

export default {
  [SET_LATEST_COURSE_LIST](state: State, payload: MyCourse) {
    state.latestCoursesList = payload;
  },
  [SET_MY_COURSE_LIST](state: State, payload: Array<V2MyCourse>) {
    state.myCoursesList = payload;
  },
  [SET_MY_COURSE_DETAIL](state: State, payload: MyCoursesInfo) {
    state.courseDetail = payload;
  },
  [SET_MY_COURSE_PROGRESS](state: State, payload: Array<MyCoursesProgress>) {
    state.courseProgress = payload;
  },
  [SET_MY_COURSE_VIDEO](state: State, payload: VideoPlayback) {
    state.courseVideo = payload;
  },
  [SET_MY_COURSE_QUIZ_INFO](state: State, payload: GetQuizByContentIdResponse) {
    state.courseQuizInfo = payload;
  },
  [SET_MY_COURSE_LIVE_SESSION_INFO](state: State, payload: GetWebinarByIdResponse) {
    state.courseLiveSessionInfo = payload;
  },
  [SET_MY_COURSE_CASE_STUDY_INFO](state: State, payload: GetDocumentByIdResponse) {
    state.courseCaseStudyInfo = payload;
  },
  [SET_MY_COURSE_PAGINATION](state: State, payload: PaginationResponse) {
    state.pagination = payload;
  },
  [SET_LOAD_LATEST_COURSES_STATE](state: State, payload: LoadState) {
    state.loadLatestCoursesState = payload;
  },
  [SET_LOAD_ALL_COURSES_STATE](state: State, payload: LoadState) {
    state.loadAllCoursesState = payload;
  },
  [SET_LOAD_COURSE_INFO_STATE](state: State, payload: LoadState) {
    state.loadCourseInfoState = payload;
  },
  [SET_LOAD_COURSE_PROGRESS_STATE](state: State, payload: LoadState) {
    state.loadCourseProgressState = payload;
  },
  [SET_LOAD_COURSE_VIDEO_STATE](state: State, payload: LoadState) {
    state.loadCourseVideoState = payload;
  },
  [SET_LOAD_QUIZ_INFO_STATE](state: State, payload: LoadState) {
    state.loadCourseQuizState = payload;
  },
  [SET_LOAD_LIVE_SESSION_INFO_STATE](state: State, payload: LoadState) {
    state.loadCourseLiveSessionState = payload;
  },
  [SET_LOAD_CASE_STUDY_INFO_STATE](state: State, payload: LoadState) {
    state.loadCourseCaseStudyState = payload;
  },
  [SET_LOADING_UPDATE_PROGRESS](state: State, payload: LoadState) {
    state.loadingUpdateProgress = payload;
  },
  [SET_FINISH_COURSE_STATUS](state: State, payload: LoadState) {
    state.finishCourseStatus = payload;
  },
};
