/* eslint-disable implicit-arrow-linebreak */
declare interface codeMessageMapTypes {
  400: string;
  401: string;
  429: string;
  [key: string]: string;
}

export const isDevelopment = process.env.NODE_ENV === 'development';
export const isStaging = process.env.VUE_APP_VERSION === 'staging';
export const isProduction = process.env.VUE_APP_VERSION === 'production';
export const errCode401 = 401;
export const errCode400 = 400;

export const codeMessageMap: codeMessageMapTypes = {
  400: 'Terjadi Kesalahan',
  401: 'Terjadi Kesalahan, silahkan cek kembali nomor telponmu!',
  429: 'Terlalu banyak permintaan, silahkan coba beberapa saat lagi',
};

export const showErrorMessage = (code: number | string): string =>
  codeMessageMap[code] || 'Terjadi Kesalahan, silahkan coba beberapa saat lagi';
