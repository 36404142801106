import { SearchPaymentChannelsGroup } from '@kunciehub/api-v2-web-js-client';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  paymentChannelGroup: SearchPaymentChannelsGroup[];
  loadState: LoadState;
}

export default {
  paymentChannelGroup: [],
  loadState: 'idle',
} as State;
