import { Commit } from 'vuex';
import {
  CreateOrderRequest,
  CreateOrderResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  SearchOrdersRequest,
} from '@kunciehub/api-v2-web-js-client';
import { setupAPI } from '@/plugins/api';
import { IStatus } from '@/interfaces/premium/StatusInterface';
import {
  SET_CHECK_ORDER_INVOICE,
  SET_CHECK_ORDER_STATUS,
  SET_CREATE_ORDER_STATUS,
  SET_ORDERS,
  SET_ORDER,
  SET_PAGINATION,
  SET_STATUS,
  SET_CREATE_PAYMENT_STATUS,
} from './mutations';

const api = setupAPI();

export default {
  async searchOrders({ commit }: { commit: Commit }, payload: SearchOrdersRequest) {
    commit(SET_STATUS, IStatus.LOADING);
    try {
      const result = await api.orders.searchOrders(payload);
      const orders = result.data.data?.items;
      const pagination = result.data.data?.pagination;
      commit(SET_ORDERS, orders);
      commit(SET_PAGINATION, pagination);
      commit(SET_STATUS, IStatus.SUCCESS);
    } catch (error) {
      commit(SET_ORDERS, {});
      commit(SET_STATUS, IStatus.ERROR);
    }
  },
  async getMentorByID({ commit }: { commit: Commit }, payload: string) {
    commit(SET_STATUS, 'loading');
    try {
      const result = await api.orders.getOrderById(payload);
      const order = result.data?.data;
      commit(SET_ORDER, order);
      commit(SET_STATUS, IStatus.SUCCESS);
    } catch (error) {
      commit(SET_ORDERS, {});
      commit(SET_STATUS, IStatus.ERROR);
    }
  },
  async createOrder({ commit }: { commit: Commit }, payload: CreateOrderRequest) {
    commit(SET_CREATE_ORDER_STATUS, 'loading');
    try {
      const orderData = {
        ...payload,
        version: 'v2',
      };
      const result = await api.orders.createOrder(orderData);
      const response = result?.data as CreateOrderResponse;
      commit(SET_CREATE_ORDER_STATUS, IStatus.SUCCESS);
      return response.data.id;
    } catch (error: any) {
      commit(SET_CREATE_ORDER_STATUS, IStatus.ERROR);
      throw error.response?.data;
    }
  },
  async checkMySkillOrder({ commit }: { commit: Commit }, payload: string) {
    commit(SET_CHECK_ORDER_STATUS, 'loading');
    try {
      const result = await api.mySkill.myskillOrderCheck(payload, { headers: { 'Cache-Control': 'no-cache' } });
      commit(SET_CHECK_ORDER_INVOICE, result.data?.data);
      commit(SET_CHECK_ORDER_STATUS, IStatus.SUCCESS);
    } catch (error) {
      commit(SET_CHECK_ORDER_INVOICE, null);
      commit(SET_CHECK_ORDER_STATUS, IStatus.ERROR);
    }
  },
  async cancelOrder({ commit }: { commit: Commit }, payload: string) {
    commit(SET_CREATE_ORDER_STATUS, 'loading');
    try {
      await api.orders.cancelOrderById(payload);
      commit(SET_CREATE_ORDER_STATUS, IStatus.SUCCESS);
    } catch (error) {
      commit(SET_CREATE_ORDER_STATUS, IStatus.ERROR);
    }
  },
  async createPayment({ commit }: { commit: Commit }, payload: CreatePaymentRequest) {
    commit(SET_CREATE_PAYMENT_STATUS, IStatus.LOADING);
    try {
      const result = await api.payments.createPayment(payload);
      const response = result?.data as CreatePaymentResponse;
      const paymentUrl = response?.data?.payment_url;
      window.open(paymentUrl, '_self');
      commit(SET_CREATE_PAYMENT_STATUS, IStatus.SUCCESS);
    } catch (error: any) {
      commit(SET_CREATE_PAYMENT_STATUS, IStatus.ERROR);
      throw error.response?.data;
    }
  },
};
