import type {
  Mentor,
  MentorSearch,
  PaginationResponse,
  SearchProductCatalogItem,
} from '@kunciehub/api-v2-web-js-client';

export type Status = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  mentor: Mentor | undefined;
  mentorList: MentorSearch[] | undefined;
  pagination: PaginationResponse;
  status: Status;
  mentorProducts: SearchProductCatalogItem[] | undefined;
}

export default {
  mentor: undefined,
  mentorList: [],
  pagination: {
    limit: 0,
    offset: 0,
    total: 0,
  },
  status: 'idle',
  mentorProducts: [],
} as State;
