import type { Course } from '@kunciehub/api-v2-web-js-client';
import type { LoadState, State } from './state';

export const SET_COURSE_LIST = 'SET_COURSE_LIST';
export const SET_LOAD_STATE = 'SET_LOAD_STATE';

export default {
  [SET_COURSE_LIST](state: State, payload: Course[]) {
    state.courseList = payload;
  },
  [SET_LOAD_STATE](state: State, payload: LoadState) {
    state.loadState = payload;
  },
};
