import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { getPerformance } from "firebase/performance";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_CONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_CONFIG_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_CONFIG_DBURL,
  projectId: process.env.VUE_APP_FIREBASE_CONFIG_PID,
  messagingSenderId: process.env.VUE_APP_FIREBASE_CONFIG_MSID,
  appId: process.env.VUE_APP_FIREBASE_CONFIG_APPID,
  measurementId: process.env.VUE_APP_FIREBASE_CONFIG_MID,
};

const app = initializeApp(config);

const remoteConfig = getRemoteConfig(app);

getPerformance(app);
getAnalytics(app);

const firebase = {
  remoteConfig: window.indexedDB ? remoteConfig : null,
};

export default firebase;
