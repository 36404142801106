import type { MyCertificate } from '@kunciehub/api-v2-web-js-client';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  certificateList: MyCertificate[];
  loadState: LoadState;
}

export default {
  certificateList: [],
  loadState: 'idle',
} as State;
