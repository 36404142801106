import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { ref } from 'vue';

const analytics = ref<Analytics>();

const segment = {
  async useSegment() {
    if (!analytics.value) {
      await AnalyticsBrowser.load({
        writeKey: process.env.VUE_APP_SEGMENT_CODE || '',
      })
        .then(([res]) => {
          analytics.value = res;
        })
        .catch(err => console.log(err));
    }

    return analytics;
  },
};

export default segment;
