import * as Sentry from '@sentry/vue';
import { Commit, Dispatch } from 'vuex';
import { setupAPI } from '@/plugins/api';
import { getCartId, getCartToken, getProductId } from '@/utils/helpers/cart';
import { getAccessToken } from '@/utils/helpers/token';
import { AddCartItemsRequest } from '@kunciehub/api-v2-web-js-client';
import { SET_CART_CREDENTIAL, SET_CART_INFO, SET_LOAD_STATE, SET_ORDER_DATA } from './mutations';

const api = setupAPI();
const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';

export default {
  async createCart({ commit }: { commit: Commit }) {
    if (getCartId()) return;
    commit(SET_LOAD_STATE, LOADING);
    try {
      const results = await api.carts.createCart({ token: getAccessToken() });
      const { data } = results.data;
      commit(SET_CART_CREDENTIAL, { id: data?.cart_id, token: data?.token });
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error) {
      commit(SET_LOAD_STATE, ERROR);
      Sentry.captureException(error);
    }
  },
  async addToCart({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, payload: AddCartItemsRequest) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      await dispatch('createCart');
      await api.cartItems.addCartItems(getCartId(), getCartToken(), payload);
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error: any) {
      commit(SET_LOAD_STATE, ERROR);
      throw error.response.data;
    } finally {
      await dispatch('fetchCartInfo');
    }
  },
  async deleteCartItem({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, productId: string) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      await dispatch('createCart');
      await api.cartItems.removeCartItem(productId, getCartId(), getCartToken());
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error) {
      commit(SET_LOAD_STATE, ERROR);
      Sentry.captureException(error);
    } finally {
      await dispatch('fetchCartInfo');
    }
  },
  async fetchCartInfo({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      await dispatch('createCart');
      const results = await api.carts.getCartById(getCartId(), getCartToken());
      const { data } = results.data;
      commit(SET_CART_INFO, data);
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error) {
      commit(SET_LOAD_STATE, ERROR);
      Sentry.captureException(error);
    }
  },
  async clearCart({ commit }: { commit: Commit }) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      await api.carts.clearCart(getCartId(), getCartToken());
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error: any) {
      commit(SET_LOAD_STATE, ERROR);
      throw error.response.data;
    }
  },
  async calculateOrder({ commit }: { commit: Commit }) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      const result = await api.carts.calculateCartOrder({
        product_ids: getProductId(),
      });
      const { data } = result.data;
      commit(SET_ORDER_DATA, data);
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error: any) {
      commit(SET_LOAD_STATE, ERROR);
      throw error.response.data;
    }
  },
  async fetchGuestCart({ commit }: { commit: Commit }) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      const result = await api.carts.calculateCartOrder({
        product_ids: getProductId(),
      });
      const { data } = result.data;
      commit(SET_CART_INFO, data);
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error: any) {
      commit(SET_LOAD_STATE, ERROR);
      throw error.response.data;
    }
  },
};
