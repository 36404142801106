import { RouteRecordRaw } from 'vue-router';
import {
  DASHBOARD,
  DASHBOARD_CERTIFICATE_LOADING,
  DASHBOARD_COURSE_DETAIL,
  DASHBOARD_COURSE_FINISH,
  DASHBOARD_DETAIL_CERTIFICATE,
  DASHBOARD_LIST_CERTIFICATE,
  DASHBOARD_LIST_COURSE,
  DASHBOARD_LIST_PROGRAM,
  DASHBOARD_MY_ACCOUNT,
  DASHBOARD_PROGRAM_DETAIL,
  DASHBOARD_QUIZ,
  DASHBOARD_SUBSCRIPTION_DETAIL,
  DASHBOARD_TRANSACTION_HISTORY,
  DASHBOARD_TRANSACTION_HISTORY_DETAIL,
  DASHBOARD_ZOOM_CLIENT,
  DASHBOARD_ZOOM_CLIENT_AFTER_SESSION,
} from './type';

const routes: RouteRecordRaw[] = [
  {
    path: '/v2/dashboard',
    name: DASHBOARD,
    redirect: { name: DASHBOARD_LIST_COURSE },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'class',
        name: DASHBOARD_LIST_COURSE,
        component: () => import('@/modules/dashboard/learning/CoursesList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'class/:slug',
        name: DASHBOARD_COURSE_DETAIL,
        component: () => import('@/modules/dashboard/learning/CoursesDetail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'class/:slug/quiz/:id',
        name: DASHBOARD_QUIZ,
        component: () => import('@/modules/dashboard/learning/Quiz.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'class/zoom-client',
        name: DASHBOARD_ZOOM_CLIENT,
        component: () => import('@/modules/dashboard/learning/ZoomClientView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'class/zoom-client-after-session',
        name: DASHBOARD_ZOOM_CLIENT_AFTER_SESSION,
        component: () => import('@/modules/dashboard/learning/ZoomAfterLeave.vue'),
      },
      {
        path: 'class/subscription/:slug',
        name: DASHBOARD_SUBSCRIPTION_DETAIL,
        component: () => import('@/modules/dashboard/learning/SubscriptionDetail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'program',
        name: DASHBOARD_LIST_PROGRAM,
        component: () => import('@/views/premium/dashboard/ProgramList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'program/:programName',
        name: DASHBOARD_PROGRAM_DETAIL,
        component: () => import('@/views/premium/dashboard/ProgramDetail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'certificates',
        name: DASHBOARD_LIST_CERTIFICATE,
        component: () => import('@/modules/dashboard/certificate/CertificateList.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'certificate/:id',
        name: DASHBOARD_DETAIL_CERTIFICATE,
        component: () => import('@/modules/dashboard/certificate/CertificateDetail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'course/:slug/finish',
        name: DASHBOARD_COURSE_FINISH,
        component: () => import('@/modules/dashboard/learning/CourseFinish.vue'),
        meta: {
          active: true,
          requiresAuth: true,
        },
      },
      {
        path: 'certificate-loading/:slug',
        name: DASHBOARD_CERTIFICATE_LOADING,
        component: () => import('@/modules/dashboard/learning/CertificateLoading.vue'),
        meta: {
          active: true,
          requiresAuth: true,
        },
      },
      {
        path: 'order',
        name: DASHBOARD_TRANSACTION_HISTORY,
        component: () => import('@/modules/dashboard/order/OrderHistory.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'order/:id',
        name: DASHBOARD_TRANSACTION_HISTORY_DETAIL,
        component: () => import('@/modules/dashboard/order/OrderHistoryDetail.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'my-account',
        name: DASHBOARD_MY_ACCOUNT,
        component: () => import('@/modules/dashboard/account/Account.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const dashboardRoutes = routes.filter(val => !val.meta?.disabled);

export default dashboardRoutes;
