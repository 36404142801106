import { TrackerPlatform, TrackerProperties } from '@/interfaces/premium/TrackerInterface';
import isMobile from '@/utils/helpers/device';
import segment from '@/utils/helpers/segment';

const USER_ID_KEY = 'userId';

// const defaultProperties = (): TrackerProperties => ({
//   platform: isMobile() ? TrackerPlatform.mobile : TrackerPlatform.desktop,
//   screen_name: window.location.href,
//   session_id: getSessionId(),
//   timestamp: new Date().toISOString(),
//   user_id: localStorage.getItem(USER_ID_KEY) || null,
//   visitor_id: null,
// });

const defaultGoogleProperties = (): TrackerProperties => ({
  platform: isMobile() ? TrackerPlatform.mobile : TrackerPlatform.desktop,
  screen_name: window.location.href,
  user_id: localStorage.getItem(USER_ID_KEY) || null,
});

const generateVisitorId = async () => {
  const analytics = await segment.useSegment();

  return analytics.value?.user().anonymousId();
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const track = async (eventName?: string, params?: TrackerProperties) => true
  // const analytics = await segment.useSegment();

  // const trackerProperties = {
  //   ...defaultProperties(),
  //   ...params,
  //   visitor_id: params.visitor_id || analytics.value?.user().anonymousId(),
  // };

  // try {
  //   await analytics.value?.track(eventName, trackerProperties);
  // } catch (error) {
  //   captureException(error);
  // }

const googleTrack = (properties: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...defaultGoogleProperties(),
    ...properties,
  });
};

const tracker = {
  track,
  generateVisitorId,
  googleTrack,
};

export default tracker;
