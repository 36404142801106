export enum TrackerPlatform {
  desktop = 'web-desktop',
  mobile = 'web-mobile',
}

export interface TrackerProperties extends Record<string, string | undefined | null> {
  event_id?: string;
  timestamp?: string;
  visitor_id?: string | null;
  user_id?: string | null;
  platform?: TrackerPlatform;
  session_id?: string;
  screen_name?: string;
}
