import type { State } from './state';

export default {
  getProductCatalog(state: State) {
    return state.productCatalog;
  },
  getProductDetail(state: State) {
    return state.productDetail;
  },
  getPagination(state: State) {
    return state.pagination;
  },
  getStatus(state: State) {
    return state.status;
  },
  isLoading(state: State) {
    return state.status === 'loading';
  },
  isError(state: State) {
    return state.status === 'error';
  },
  isSuccess(state: State) {
    return state.status === 'success';
  },
};
