/* eslint-disable comma-dangle */
import {
  SearchMyCoursesRequest,
  UpdateProgressDocumentRequest,
  UpdateProgressQuizRequest,
  UpdateProgressVideoRequest,
} from '@kunciehub/api-v2-web-js-client';
import * as Sentry from '@sentry/vue';
import { Commit } from 'vuex';
import { setupAPI } from '@/plugins/api';
import { State } from '@/store/premium/modules/myCourses/state';
import delay from '@/utils/helpers/delay';
import {
  SET_LATEST_COURSE_LIST,
  SET_LOADING_UPDATE_PROGRESS,
  SET_LOAD_ALL_COURSES_STATE,
  SET_LOAD_COURSE_INFO_STATE,
  SET_LOAD_COURSE_PROGRESS_STATE,
  SET_LOAD_COURSE_VIDEO_STATE,
  SET_LOAD_LATEST_COURSES_STATE,
  SET_LOAD_QUIZ_INFO_STATE,
  SET_MY_COURSE_DETAIL,
  SET_MY_COURSE_LIST,
  SET_MY_COURSE_PAGINATION,
  SET_MY_COURSE_PROGRESS,
  SET_MY_COURSE_VIDEO,
  SET_MY_COURSE_QUIZ_INFO,
  SET_FINISH_COURSE_STATUS,
  SET_LOAD_LIVE_SESSION_INFO_STATE,
  SET_MY_COURSE_LIVE_SESSION_INFO,
  SET_MY_COURSE_CASE_STUDY_INFO,
  SET_LOAD_CASE_STUDY_INFO_STATE,
} from './mutations';

const api = setupAPI();
const delayTimeout = 2000;

export default {
  async getMyCoursesLatest({ commit }: { commit: Commit }) {
    commit(SET_LOAD_LATEST_COURSES_STATE, 'loading');
    try {
      const results = await api.myCourses.getMyCoursesLatest({});
      const { data } = results.data;
      commit(SET_LATEST_COURSE_LIST, data);
      commit(SET_LOAD_LATEST_COURSES_STATE, 'success');
    } catch (error) {
      commit(SET_LATEST_COURSE_LIST, {});
      commit(SET_LOAD_LATEST_COURSES_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async searchMyCourses({ commit }: { commit: Commit }, requestData: SearchMyCoursesRequest) {
    commit(SET_LOAD_ALL_COURSES_STATE, 'loading');
    try {
      const results = await api.myCourses.searchMyCourses(requestData);
      const { data } = results.data;
      commit(SET_MY_COURSE_LIST, data?.items);
      commit(SET_MY_COURSE_PAGINATION, data?.pagination);
      commit(SET_LOAD_ALL_COURSES_STATE, 'success');
    } catch (error) {
      commit(SET_MY_COURSE_LIST, []);
      commit(SET_LOAD_ALL_COURSES_STATE, 'error');
      Sentry.captureException(error);
    }
  },
  async searchMoreMyCourses({ commit, state }: { commit: Commit; state: State }, requestData: SearchMyCoursesRequest) {
    commit(SET_LOAD_ALL_COURSES_STATE, 'loading');
    try {
      const results = await api.myCourses.searchMyCourses(requestData);
      const courses = state.myCoursesList?.concat(results.data.data?.items || []);
      const pagination = results.data.data?.pagination;
      /**
       * add 2 second delay to avoid
       * flicker when the response is too fast
       */
      await delay(delayTimeout);
      commit(SET_MY_COURSE_LIST, courses);
      commit(SET_MY_COURSE_PAGINATION, pagination);
      commit(SET_LOAD_ALL_COURSES_STATE, 'success');
    } catch (error) {
      commit(SET_MY_COURSE_LIST, []);
      commit(SET_LOAD_ALL_COURSES_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async getCourseDetail({ commit }: { commit: Commit }, slug: string) {
    commit(SET_LOAD_COURSE_INFO_STATE, 'loading');
    try {
      const results = await api.myCourses.getMyCoursesInfoBySlug(slug);
      const { data } = results.data;
      commit(SET_MY_COURSE_DETAIL, data);
      commit(SET_LOAD_COURSE_INFO_STATE, 'success');
    } catch (error: any) {
      commit(SET_MY_COURSE_DETAIL, {});
      commit(SET_LOAD_COURSE_INFO_STATE, 'error');
      throw error.response.data;
    }
  },

  async getCourseProgress({ commit }: { commit: Commit }, slug: string) {
    commit(SET_LOAD_COURSE_PROGRESS_STATE, 'loading');
    try {
      const results = await api.myCourses.getMyCoursesLessonsBySlug(slug);
      const { data } = results.data;
      commit(SET_MY_COURSE_PROGRESS, data);
      commit(SET_LOAD_COURSE_PROGRESS_STATE, 'success');
    } catch (error) {
      commit(SET_MY_COURSE_PROGRESS, {});
      commit(SET_LOAD_COURSE_PROGRESS_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async getCourseVideo({ commit }: { commit: Commit }, componentId: string) {
    try {
      commit(SET_LOAD_COURSE_VIDEO_STATE, 'loading');
      commit(SET_MY_COURSE_VIDEO, undefined);
      const results = await api.videoPlayback.getVideoPlaybackById(componentId);
      const { data } = results.data;
      commit(SET_MY_COURSE_VIDEO, data);
      commit(SET_LOAD_COURSE_VIDEO_STATE, 'success');
    } catch (error) {
      commit(SET_MY_COURSE_VIDEO, {});
      commit(SET_LOAD_COURSE_VIDEO_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async updateVideoProgress(
    { commit }: { commit: Commit },
    payload: { requestData: UpdateProgressVideoRequest; slug: string; type: string },
  ) {
    try {
      commit(SET_LOADING_UPDATE_PROGRESS, 'loading');
      if (payload.type === 'subscription') {
        await api.myCourses.updateMyCourseSubscriptionVideoProgress(payload.requestData);
      } else {
        await api.myCourses.updateMyCourseVideoProgress(payload.requestData);
      }
      commit(SET_LOADING_UPDATE_PROGRESS, 'success');

      const results = await api.myCourses.getMyCoursesLessonsBySlug(payload.slug);
      const { data } = results.data;
      commit(SET_MY_COURSE_PROGRESS, data);
    } catch (error) {
      commit(SET_LOADING_UPDATE_PROGRESS, 'error');
      Sentry.captureException(error);
    }
  },

  async fetchQuizInfo({ commit }: { commit: Commit }, componentId: string) {
    commit(SET_MY_COURSE_QUIZ_INFO, undefined);
    try {
      commit(SET_LOAD_QUIZ_INFO_STATE, 'loading');
      const { data } = await api.quizzes.getQuizByContentId(componentId);
      commit(SET_MY_COURSE_QUIZ_INFO, data);
      commit(SET_LOAD_QUIZ_INFO_STATE, 'success');
    } catch (error) {
      commit(SET_LOAD_QUIZ_INFO_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async updateQuizProgress({ commit }: { commit: Commit }, payload: UpdateProgressQuizRequest) {
    try {
      commit(SET_LOADING_UPDATE_PROGRESS, 'loading');
      await api.myCourses.updateMyCourseQuizProgress(payload);
      commit(SET_LOADING_UPDATE_PROGRESS, 'success');
    } catch (error) {
      commit(SET_LOADING_UPDATE_PROGRESS, 'error');
      Sentry.captureException(error);
    }
  },

  async fetchLiveSessionInfo({ commit }: { commit: Commit }, componentId: string) {
    commit(SET_MY_COURSE_LIVE_SESSION_INFO, undefined);
    try {
      commit(SET_LOAD_LIVE_SESSION_INFO_STATE, 'loading');
      const { data } = await api.webinar.getWebinarByContentId(componentId);
      commit(SET_MY_COURSE_LIVE_SESSION_INFO, data);
      commit(SET_LOAD_LIVE_SESSION_INFO_STATE, 'success');
    } catch (error) {
      commit(SET_LOAD_LIVE_SESSION_INFO_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async fetchCaseStudyInfo({ commit }: { commit: Commit }, componentId: string) {
    commit(SET_MY_COURSE_CASE_STUDY_INFO, undefined);
    try {
      commit(SET_LOAD_CASE_STUDY_INFO_STATE, 'loading');
      const { data } = await api.documents.getDocumentByID(componentId);
      commit(SET_MY_COURSE_CASE_STUDY_INFO, data);
      commit(SET_LOAD_CASE_STUDY_INFO_STATE, 'success');
    } catch (error) {
      commit(SET_LOAD_CASE_STUDY_INFO_STATE, 'error');
      Sentry.captureException(error);
    }
  },

  async updateCaseStudyProgress({ commit }: { commit: Commit }, payload: UpdateProgressDocumentRequest) {
    try {
      commit(SET_LOADING_UPDATE_PROGRESS, 'loading');
      await api.myCourses.updateMyCourseDocumentProgress(payload);
      commit(SET_LOADING_UPDATE_PROGRESS, 'success');
    } catch (error: any) {
      commit(SET_LOADING_UPDATE_PROGRESS, 'error');
      throw error.response.data;
    }
  },

  async finishCourses({ commit }: { commit: Commit }, slug: string) {
    commit(SET_FINISH_COURSE_STATUS, 'loading');
    try {
      await api.myCourses.finishMyCoursesBySlug(slug);
      commit(SET_FINISH_COURSE_STATUS, 'success');
    } catch (error: any) {
      commit(SET_FINISH_COURSE_STATUS, 'error');
      throw error.response.data;
    }
  },

  resetState({ commit }: { commit: Commit }) {
    commit(SET_LOAD_LATEST_COURSES_STATE, 'idle');
    commit(SET_LATEST_COURSE_LIST, {});
    commit(SET_LOAD_ALL_COURSES_STATE, 'idle');
    commit(SET_MY_COURSE_LIST, []);
    commit(SET_MY_COURSE_PAGINATION, {});
    commit(SET_LOADING_UPDATE_PROGRESS, 'idle');
  },
};
