import type {
  GetProductCatalogBySlugData,
  PaginationResponseV2,
  SearchProductCatalogItem,
} from '@kunciehub/api-v2-web-js-client';

export type Status = 'idle' | 'loading' | 'error' | 'success';

export interface State {
  productCatalog: SearchProductCatalogItem[] | undefined;
  productDetail: GetProductCatalogBySlugData | null;
  pagination: PaginationResponseV2;
  status: Status;
}

export default {
  productCatalog: [],
  productDetail: null,
  pagination: {
    limit: 0,
    offset: 0,
    total: 0,
  },
  status: 'idle',
} as State;
