import { IUserMeta, IUserProfile } from '@/interfaces/premium/UserInterface';
import type { LoadState, State } from './state';

export const SET_USER_META = 'SET_USER_META';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const RESET_USER_META = 'RESET_USER_META';
export const SET_STATUS = 'SET_STATUS';
export const SET_REGISTERED_USER = 'SET_REGISTERED_USER';

export default {
  [SET_USER_META](state: State, payload: IUserMeta) {
    state.userMeta = payload;
  },
  [SET_USER_PROFILE](state: State, payload: IUserProfile) {
    state.userProfile = payload;
  },
  [RESET_USER_META](state: State) {
    state.userMeta = {} as IUserMeta;
    state.userProfile = {} as IUserProfile;
    state.registeredUser = false;
    localStorage.removeItem('userId');
  },
  [SET_STATUS](state: State, payload: LoadState) {
    state.status = payload;
  },
  [SET_REGISTERED_USER](state: State, payload: boolean) {
    state.registeredUser = payload;
  },
};
