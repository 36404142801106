import { changeZero } from '@/utils/helpers/second-to-duration';

const SECOND_MULTIPLIER = 1000;
const MINUTE_MULTIPLIER = 60 * SECOND_MULTIPLIER;
const HOUR_MULTIPLIER = 60 * MINUTE_MULTIPLIER;
const DAY_MULTIPLIER = 24 * HOUR_MULTIPLIER;
const YEAR_MULTIPLIER = 365 * DAY_MULTIPLIER;

const DAYS = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];

export function convertSecondToMillisecond(second: number): number {
  return second * SECOND_MULTIPLIER;
}

export function convertMinuteToMillisecond(minute: number): number {
  return minute * MINUTE_MULTIPLIER;
}

export function convertHourToMillisecond(hour: number): number {
  return hour * HOUR_MULTIPLIER;
}

export function convertDayToMillisecond(day: number): number {
  return day * DAY_MULTIPLIER;
}

export function convertYearToMillisecond(year: number): number {
  return year * YEAR_MULTIPLIER;
}

export function getTimeFromDate(date = '') {
  return date ? new Date(date).getTime() : new Date().getTime();
}

export function getDateTime(datetime: string, type: 'datetimeWithDayName' | 'datetime' | 'timeOnly' | string) {
  const dt = new Date(datetime);
  const date = changeZero(dt.getDate());
  const day = dt.getDay();
  const month = dt.getMonth();
  const year = dt.getFullYear();
  const hour = changeZero(dt.getHours());
  const minute = changeZero(dt.getMinutes());

  if (type === 'datetime') {
    return `${date} ${MONTHS[month]} ${year}, ${hour}:${minute}`;
  }
  if (type === 'datetimeWithDayName') {
    return `${DAYS[day]}, ${date} ${MONTHS[month]} ${year}, ${hour}:${minute}`;
  }
  if (type === 'timeOnly') {
    return `${hour}:${minute}`;
  }

  return `${date} ${MONTHS[month]} ${year}`;
}
