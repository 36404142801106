import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

let sampleRate: number;
if (process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE) {
  sampleRate = Number(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE);
}

const initSentry = (app: App, router: Router) => {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN || '',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          process.env.VUE_APP_DOMAIN || '',
          /^\//,
        ],
      }),
    ],
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_SENTRY_RELEASE,
    tracesSampleRate: sampleRate,
  });
};

export default initSentry;
