/* eslint-disable */
import dashboardRoutes from '@/router/dashboard';
import unifiedRoutes from '@/router/unified';
import learnDashboardRoutes from '@/router/learn-dashboard';
import learnUnifiedRoutes from '@/router/learn-unified';
import { DASHBOARD, UNIFIED_LOGIN, UNIFIED_LOGIN_MOOC, UNIFIED_LOGOUT } from './type';
import store from '@/store';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import urlHelper from '@/utils/helpers/url-helper';

const { host } = window.location;
const parts = host.split('.');
const subDomainName = 'learn';
const isLearnSubdomain = parts[0] === subDomainName;

const routes: Array<RouteRecordRaw> =
  isLearnSubdomain
    ? [...learnDashboardRoutes, ...learnUnifiedRoutes]
    : [...unifiedRoutes, ...dashboardRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _, next) => {
  const privateRoute = to.matched.some(route => route.meta.requiresAuth);

  store
    .dispatch('user/getUser')
    .then(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated'];
      if (privateRoute) {
        if (!isAuthenticated) {
          if (isLearnSubdomain) {
            next({ name: UNIFIED_LOGIN });
          } else {
            window.location.replace(`${urlHelper.KUNCIE_PREMIUM}/login`);
          }
        } else {
          next();
        }
      } else {
        if ([UNIFIED_LOGIN, UNIFIED_LOGIN_MOOC].includes(`${to.name?.toString()}`) && isAuthenticated) {
          next({ name: DASHBOARD });
        } else if (to.name === UNIFIED_LOGOUT && !isAuthenticated) {
          if (isLearnSubdomain) {
            next({ name: UNIFIED_LOGIN });
          } else {
            window.location.replace(`${urlHelper.KUNCIE_PREMIUM}/login`);
          }
        } else if (to.path.toLowerCase() !== to.path) {
          next({ path: to.path.toLowerCase() });
        } else {
          next();
        }
      }
    })
    .catch(() => {
      if (privateRoute) {
        next({ name: UNIFIED_LOGOUT });
      }
      else next();
    });
});

export { routes };

export default router;
