import { IUserMeta, IUserProfile } from '@/interfaces/premium/UserInterface';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';

export interface State {
  userMeta: IUserMeta | null;
  userProfile: IUserProfile | null;
  status: LoadState;
  registeredUser: boolean;
}

export default {
  userMeta: null,
  userProfile: null,
  status: 'idle',
  registeredUser: false,
} as State;
