import axios from 'axios';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAccessToken, getRefreshToken, getTokenId, setToken } from '@/utils/helpers/token';
import * as Sentry from '@sentry/vue';
import { isDevelopment } from '@/utils/helpers/constant-api';
import { AuthApi, Configuration, ConfigurationParameters } from '@kunciehub/api-v2-web-js-client';
import { getSessionId, getUserEmail, getUserPhone } from '@/utils/helpers/identity';

const axiosInstance: AxiosInstance = axios.create();

const basePath = process.env.VUE_APP_APIV2_HOST;
const configParams = (): ConfigurationParameters => ({
  baseOptions: {
    headers: {
      'Content-Type': 'application/json',
      'X-Login-Type': 'web',
    },
  },
});
const apiConfig = new Configuration(configParams());

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();

    config.headers = config.headers ?? {};

    if (accessToken) {
      if (config.url?.includes('refresh-token')) {
        config.headers.Authorization = `Bearer ${refreshToken}`;
      } else {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const unauthorizedStatus = 401
    const originalRequest = error.config
    const clientType = 'web'

    if (
      error.response?.status === unauthorizedStatus &&
      !originalRequest.url?.includes('refresh-token') &&
      !originalRequest.url?.includes('logout')
    ) {
      const authApi = new AuthApi(apiConfig, basePath, axiosInstance)
      const tokenId = getTokenId()

      try {
        const { data: response } = await authApi.authRefreshToken(clientType, {
          client_type: clientType,
          device_id: getSessionId(),
          email: getUserEmail(),
          phone_number: getUserPhone(),
        })

        setToken({
          token: `${response.data?.access_token}`,
          refreshToken: `${response.data?.refresh_token}`,
          tokenId,
        })

        const originalRequestResponse = await axiosInstance(originalRequest)
        return originalRequestResponse
      } catch (err) {
        if (isDevelopment) {
          console.error(err);
        }
        Sentry.captureException(err);
        return Promise.reject(err);
      }
    }

    if (isDevelopment) {
      console.error(error);
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

export default axiosInstance;
