import * as Sentry from '@sentry/vue';
import { Commit, Dispatch } from 'vuex';
import { SearchPaymentChannelsGroup } from '@kunciehub/api-v2-web-js-client';
import { setupAPI } from '@/plugins/api';
import { SET_PAYMENT_CHANNEL_GROUP, SET_LOAD_STATE } from './mutations';

const api = setupAPI();

type ArrayIcons = {
  name: string;
  url: string;
};
const LOADING = 'loading';
const SUCCESS = 'success';
const ERROR = 'error';

export default {
  async fetchPaymentChannelGroup({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, paymentAmount: number) {
    commit(SET_LOAD_STATE, LOADING);
    try {
      const results = await api.paymentChannel.searchPaymentChannels({
        filter: {
          payment_amount: paymentAmount,
        },
      });
      const { data } = results.data;
      dispatch('populatePaymentTypeIcon', data.items);
      commit(SET_LOAD_STATE, SUCCESS);
    } catch (error) {
      commit(SET_LOAD_STATE, ERROR);
      Sentry.captureException(error);
    }
  },
  populatePaymentTypeIcon({ commit }: { commit: Commit }, paymentChannelGroup: SearchPaymentChannelsGroup[]) {
    const mappedData = paymentChannelGroup.map(channel => {
      const paymentTypeIcon = [] as ArrayIcons[];

      channel.items.forEach(paymentType => {
        paymentTypeIcon.push({ name: paymentType.name, url: paymentType.logo_url });
      });

      return {
        ...channel,
        icons: paymentTypeIcon,
      };
    });
    commit(SET_PAYMENT_CHANNEL_GROUP, mappedData);
  },
};
