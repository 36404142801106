import { v4 as uuidv4 } from 'uuid';
import { convertMinuteToMillisecond } from '@/utils/helpers/date-helper';
import IdleTracker from '@/utils/helpers/idle-tracker';

export const SESSION_ID_KEY = 'sessionId';

const createSessionId = (): string => {
  const sessionId = uuidv4();
  return sessionId;
};

export const setSessionId = (): void => {
  const sessionId = createSessionId();

  localStorage.removeItem(SESSION_ID_KEY);
  localStorage.setItem(SESSION_ID_KEY, sessionId);
};

export const getSessionId = (): string => {
  if (!localStorage.getItem(SESSION_ID_KEY)) {
    setSessionId();
  }
  return localStorage.getItem(SESSION_ID_KEY) ?? '';
};

export const setUserEmail = (value: string) => localStorage.setItem('email', value)
export const setUserPhone = (value: string) => localStorage.setItem('phone', value)

export const getUserEmail = (): string => localStorage.getItem('email') ?? ''
export const getUserPhone = (): string => localStorage.getItem('phone') ?? ''

const idleTracker = new IdleTracker({
  timeout: convertMinuteToMillisecond(30),
  onIdleCallback: setSessionId,
});

idleTracker.start();
