import type { Course } from '@kunciehub/api-v2-web-js-client';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  courseList: Course[];
  loadState: LoadState;
}

export default {
  courseList: [],
  loadState: 'idle',
} as State;
