import type { Category, GetAllCategoriesItem } from '@kunciehub/api-v2-web-js-client';

export type Status = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  dataCategories: GetAllCategoriesItem[];
  dataLevel: Category[];
  status: Status;
}

export default {
  dataCategories: [],
  dataLevel: [],
  status: 'idle',
} as State;
