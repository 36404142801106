import type { State } from './state';

export default {
  getPaymentChannelGroup(state: State) {
    return state.paymentChannelGroup;
  },
  isLoading(state: State) {
    return state.loadState === 'loading';
  },
};
