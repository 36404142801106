import { App } from 'vue';

const isLearnSubdomain = () => {
  const { host } = window.location;
  const parts = host.split('.');
  const subDomainName = 'learn';

  return parts[0] === subDomainName;
};

export default {
  install: (app: App) => {
    app.config.globalProperties.$v2 = window.location.href.indexOf('v2') !== -1;
    app.config.globalProperties.$learn = isLearnSubdomain();
  },
};
