/* eslint-disable max-len */
import _ from 'lodash';

const arraySEO = [
  {
    id: 0,
    url: 'https://www.kuncie.com/belajar',
    code: 0,
    type: '-',
    title: 'Cari Tahu Kiat Sukses Berwirausaha Dari Ahlinya di Kuncie',
    description: 'Di Kuncie, kamu akan menemukan kiat sukses berwirausaha langsung dari para pengusaha dan entrepreneur sukses yang ada di Indonesia. Yuk mulai bisnismu!',
  },
  {
    id: 1,
    url: 'https://www.kuncie.com/mentors/giorrando-grissandy/2',
    code: 2,
    type: 'mentors',
    title: 'Kelas Giorrando Grissandy, Co-founder Garis Temu Agency',
    description: 'Giorrando adalah co-founder dari Garis Temu yang mendalami dunia entrepreneurship, bisnis, dan digital, khususnya di sosial media.',
  },
  {
    id: 2,
    url: 'https://www.kuncie.com/mentors/brilliant-liu/29',
    code: 29,
    type: 'mentors',
    title: 'Kelas Dari Briliant Liu - Founder House of Leaders',
    description: 'Briliant Liu merupakan personal branding coach dan pendiri House of Leaders, sebuah komunitas internasional dengan fokus kepemimpinan dan inspirasi positif.',
  },
  {
    id: 3,
    url: 'https://www.kuncie.com/mentors/gufron-syarif/31',
    code: 31,
    type: 'mentors',
    title: 'Kelas Dari Gufron Syarif, Co-Founder Haus',
    description: 'Gufron Syarif adalah pengusaha berpengalaman dan CEO HAUS!, sebuah start-up F&B berbasis minuman dan makanan kekinian dengan harga terjangkau.',
  },
  {
    id: 4,
    url: 'https://www.kuncie.com/mentors/raharja-putra/32',
    code: 32,
    type: 'mentors',
    title: 'Belajar Dari Copywriter Guru - Raharja Putra',
    description: 'Raharja Putra adalah copywriter & content creator dengan pengalaman lebih dari 5 tahun di berbagai perusahaan. Mau jadi copywriter? Jangan lewatkan kelas ini!',
  },
  {
    id: 5,
    url: 'https://www.kuncie.com/mentors/cempaka-asriani/34',
    code: 34,
    type: 'mentors',
    title: 'Kelas Dari Cempaka Asriani - Founder SARE Studio',
    description: 'Cempaka Asriani berpengalaman di bidang fashion journalism dan saat ini memiliki beberapa lini bisnis terutama di bidang Fashion dan B&B.',
  },
  {
    id: 6,
    url: 'https://www.kuncie.com/mentors/arnold-poernomo/35',
    code: 35,
    type: 'mentors',
    title: 'Kelas Dari Chef Arnold Poernomo, Juri MasterChef',
    description: 'Chef Arnold memiliki segudang pengalaman di dunia F&B. Dari menjadi seorang juri di MasterChef, hingga menciptakan restoran di Indonesia dan Australia.',
  },
  {
    id: 7,
    url: 'https://www.kuncie.com/mentors/agung-kurniawan/36',
    code: 36,
    type: 'mentors',
    title: 'Kelas Dari Agung Kurniawan - Owner Nourish Indonesia',
    description: 'Agung Kurniawan adalah salah satu pendiri Nourish Indonesia, Agung mengembangkan bisnis online nya melalui pemasaran digital yang efektif dan bertanggung jawab.',
  },
  {
    id: 8,
    url: 'https://www.kuncie.com/mentors/elwiana-monica/37',
    code: 37,
    type: 'mentors',
    title: 'Kelas Dari Elwiana Monica - Brand Director SEC Bowl',
    description: 'Elwiana Monica memiliki keahlian di bidang strategi branding. Terutama bagaimana customer servicing dapat mempengaruhi branding.',
  },
  {
    id: 9,
    url: 'https://www.kuncie.com/mentors/michella-ham/38',
    code: 38,
    type: 'mentors',
    title: 'Kelas Dari Michella Ham, CEO Skin Game',
    description: 'Berbekal IDR 5juta, Michella menciptakan Skin Game pada tahun 2018, mengembangkannya menjadi merek perawatan kulit terkemuka di Indonesia.',
  },
  {
    id: 10,
    url: 'https://www.kuncie.com/mentors/mutiara-kamila-athiyya/43',
    code: 43,
    type: 'mentors',
    title: 'Kelas Dari Mutiara Kamila Athiyya - THENBLANK Owner',
    description: 'Mutiara Kamila Athiyya adalah pengusaha muda dan pemilik THENBLANK, brand fashion populer yang dimulainya saat di bangku SMA hingga berkembang.',
  },
  {
    id: 11,
    url: 'https://www.kuncie.com/mentors/teten-masduki/44',
    code: 44,
    type: 'mentors',
    title: 'Kelas Dari Teten Masduki, Menteri Koperasi & UMKM Indonesia',
    description: 'Di masa kini, Pak Teten Masduki fokus terhadap meberdayai para UMKM di Indonesia untuk mencapai potensi mereka sebagai pendorong ekonomi negeri.',
  },
  {
    id: 12,
    url: 'https://www.kuncie.com/mentors/rachmat-kaimuddin/45',
    code: 45,
    type: 'mentors',
    title: 'Kelas Dari Rachmat Kaimuddin, Ex-CEO Bukalapak',
    description: 'Rachmad Kaimudin merupakan Ex-CEO dari Bukalapak.Sebelumnya ia juga pernah bekerja di Boston Consulting Group, Baring Private Equity Asia dan Bank Bukopin',
  },
  {
    id: 13,
    url: 'https://www.kuncie.com/mentors/putri-tanjung/46',
    code: 46,
    type: 'mentors',
    title: 'Kelas Dari Putri Tanjung, Pendiri CXO by CT Corp',
    description: 'Putri Tanjung merupakan chief dari Creative Experience Office (CXO) by CT Corp dan staf khusus presiden republik Indonesia. Simak selengkapnya disini!',
  },
  {
    id: 14,
    url: 'https://www.kuncie.com/mentors/danu-sofwan/47',
    code: 47,
    type: 'mentors',
    title: 'Kelas Dari Danu Sofwan, Owner Radja Cendol dan Es Teh',
    description: 'Yuk ikutan kelas dari Danu Sofwan, Pemilik Radja Cendol & Es Teh Indonesia yang laris dan berekspansi pesat dengan 800 cabang di seluruh Indonesia.',
  },
  {
    id: 15,
    url: 'https://www.kuncie.com/mentors/annisa-pratiwi/48',
    code: 48,
    type: 'mentors',
    title: 'Kelas Dari Annisa Pratiwi, Co-Founder Ladang Lima',
    description: 'Annisa Pratiwi mendirikan Ladang Lima, pelopor makanan bebas gluten di Indonesia yang menjual produk sehat mulai dari tepung serbaguna hingga mie vegetarian',
  },
  {
    id: 16,
    url: 'https://www.kuncie.com/mentors/reza-aryabima/49',
    code: 49,
    type: 'mentors',
    title: 'Kelas Dari Aryabima, Co-Founder Artisan Professionel',
    description: 'Pada tahun 2017, Reza Aryabima mendirikan Artisan Professionnel karena melihat masih ada gap antara supply dan demand dari produk bulu mata palsu.',
  },
  {
    id: 17,
    url: 'https://www.kuncie.com/mentors/joshua-dave/50',
    code: 50,
    type: 'mentors',
    title: 'Kelas Dari Joshua Dave - Founder Melts Indonesia',
    description: 'Sebelum di Melts Indonesia, Joshua memiliki berbagai pengalaman di berbagai bidang mulai dari mengelola coffee shop saat kuliah hingga fashion styling',
  },
  {
    id: 18,
    url: 'https://www.kuncie.com/mentors/fiona-anthony/53',
    code: 53,
    type: 'mentors',
    title: 'Kelas Dari Fiona Anthony - Founder Bakepack',
    description: 'Fiona memiliki pengalaman yang cukup panjang menjadi pramugari. Sampai akhirnya Ia mendirikan Bakepack dengan concern customer service yang berkualitas tinggi.',
  },
  {
    id: 19,
    url: 'https://www.kuncie.com/mentors/uma-hapsari/54',
    code: 54,
    type: 'mentors',
    title: 'Kelas Dari Uma Hapsari, Founder & CEO Amazara',
    description: 'Semasa kuliah, Uma memiliki pengalaman unik di dunia fashion. Hal ini yang mempengaruhi cara pandangnya terhadap bisnis fashion saat mendirikan AMAZARA.',
  },
  {
    id: 20,
    url: 'https://www.kuncie.com/mentors/devy-natalia/55',
    code: 55,
    type: 'mentors',
    title: 'Kelas Dari Pemilik BohoBaby - Devy Natalia',
    description: 'Devy mendirikan Bohobaby diawali dengan kesulitannya untuk mendapatkan pakaian bayi. Saat ini Bohobaby telah melakukan penjualan hingga mancanegara',
  },
  {
    id: 21,
    url: 'https://www.kuncie.com/mentors/elisa-suteja/58',
    code: 58,
    type: 'mentors',
    title: 'Kelas Dari Elisa Suteja, Co-Founder Fore Coffee',
    description: 'Karena tertarik dengan industri new retail khususnya produk kopi, Elisa Suteja memutuskan untuk mendirikan Fore Coffee. Simak kisahnya di Kuncie!',
  },
  {
    id: 22,
    url: 'https://www.kuncie.com/mentors/aries-lukman/61',
    code: 61,
    type: 'mentors',
    title: 'Belajar Mobile Photohraphy Dari Founder Iphonesia',
    description: 'Aries memiliki sebuah hobi yang dikembangkannya sejak lama. Hobi tersebut adalah mobile photography. Hobi ini yang membuatnya mendirikan iPhonesia.',
  },
  {
    id: 23,
    url: 'https://www.kuncie.com/mentors/cindy-erdy/63',
    code: 63,
    type: 'mentors',
    title: 'Tips Berbisnis Sepatu ala Founder Fayt Official',
    description: 'Fayt official didirikan pada 2019, dimana koleksi sepatunya dibuat secara self-manufactured oleh pengrajin dengan 20 tahun lebih pengalaman.',
  },
  {
    id: 24,
    url: 'https://www.kuncie.com/mentors/raditya-dika/64',
    code: 64,
    type: 'mentors',
    title: 'Kelas Menulis Dari Raditya Dika ada di Kuncie',
    description: 'Raditya Dika adalah seorang content creator sejati. Mulai dari menulis buku, stand-up comedy, hingga film layar lebar. Yuk ikut kelas menulis dari Raditya Dika!',
  },
  {
    id: 25,
    url: 'https://www.kuncie.com/mentors/setyanto-hantoro/65',
    code: 65,
    type: 'mentors',
    title: 'Kelas Dari Setyanto Hantoro, Ex-Direktur Utama Telkomesel',
    description: 'Setyanto memiliki pengalaman yang begitu banyak dan beragam di berbagai posisi di BUMN Telkom. Di Kuncie, beliau memberikan kelas kepemimpinan yang baik.',
  },
  {
    id: 26,
    url: 'https://www.kuncie.com/mentors/shopee/66',
    code: 66,
    type: 'mentors',
    title: 'Kelas Shopee Ini Akan Membantumu Meningkatkan Performa Jualan',
    description: 'Ikuti kelas shopee disini! Shopee adalah platform e-commerce terkemuka di Asia Tenggara. Dengan ribuan user, artinya potensi berjualan di shopee sangat besar.',
  },
  {
    id: 27,
    url: 'https://www.kuncie.com/mentors/pandu-sjahrir/68',
    code: 68,
    type: 'mentors',
    title: 'Belajar Dari Investor dan Pebisnis Sukses Pandu Sjahrir',
    description: 'Pandu Sjahrir merupakan pebisnis multitalenta, Ia tergolong masih sangat muda untuk pencapaiannya. Cari tahu apa saja pencapaian beliau di Kuncie.',
  },
  {
    id: 28,
    url: 'https://www.kuncie.com/mentors/joko-anwar/69',
    code: 69,
    type: 'mentors',
    title: 'Kelas Dari Joko Anwar - Sutradara & Produser Film Terbaik',
    description: 'Joko Anwar yang merupakan sarjana teknik aerospace pernah menjadi wartawan sebelum akhirnya menjadi sutradara dan produser film. Simak selengkapnya di Kuncie!',
  },
  {
    id: 29,
    url: 'https://www.kuncie.com/mentors/sandiaga-uno/70',
    code: 70,
    type: 'mentors',
    title: 'Belajar Menjadi Pengusaha Sukses ala Sandiaga Uno',
    description: 'Sandiaga Uno memiliki banyak pengalaman di bidang bisnis. Yuk pelajari bagaimana pengusaha terkaya ke-29 di Indonesia ini membangun bisnisnya hingga sukses.',
  },
  {
    id: 30,
    url: 'https://www.kuncie.com/mentors/andhyka-darwin/71',
    code: 71,
    type: 'mentors',
    title: 'Kelas Dari Co-Founder Roti Nogat, Andhyka Darwin',
    description: 'Selain mendirikan Roti Nogat yang membawa tren roti nogat di tahun 2015, Ia juga orang di balik Gudang Rottie dan Oleander Kitchen Bakery. Simak Selengkapnya!',
  },
  {
    id: 31,
    url: 'https://www.kuncie.com/mentors/helmy-yahya/75',
    code: 75,
    type: 'mentors',
    title: 'Kelas Content Creator dari Helmi Yahya di Kuncie',
    description: 'Helmy Yahya merupakan content creator yang berpengalaman puluhan tahun di pertelevisian Indonesia. Kini Ia merambah media digital, seperti apa? Simak di Kuncie!',
  },
  {
    id: 32,
    url: 'https://www.kuncie.com/mentors/gaery-undarsa/76',
    code: 76,
    type: 'mentors',
    title: 'Kelas Dari Gaery Undarsa, Co-Founder Tiket.com',
    description: 'Setelah 9 tahun berkecimpung di dunia perbankan, Gaery Undarsa memutuskan untuk kembali ke Indonesia untuk mendirikan Tiket.com. Simak selengkapnya disini!',
  },
  {
    id: 33,
    url: 'https://www.kuncie.com/mentors/alam-akbar/78',
    code: 78,
    type: 'mentors',
    title: 'Kelas Dari Alam Akbar, Founder dan CEO Kasual',
    description: 'Setelah sukses dengan Premium Shop, Alam Akbar mendirikan Kasual yang memiliki fokus pada fitur kustom produk fashion. Simak selengkapnya disini!',
  },
  {
    id: 34,
    url: 'https://www.kuncie.com/mentors/gilang-margi-nugroho/79',
    code: 79,
    type: 'mentors',
    title: 'Kelas Dari Founder Kepiting Nyinyir - Gilang Margi Nugroho',
    description: 'Sebelum mendirikan kepiting nyinyir, gilang memiliki pengalaman panjang dalam bisnis makanan. Ia mendapatkan beberapa penghargaan terkait konsep bisnisnya.',
  },
  {
    id: 35,
    url: 'https://www.kuncie.com/mentors/eva-alicia/81',
    code: 81,
    type: 'mentors',
    title: 'Kelas Dari Eva Alicia, Founder She Wears Gold',
    description: 'Eva Alicia belum genap berumur 20 tahun saat membangun She Wears Gold. Namun jangkauan customernya telah meluas di Instagram dan TikTok.',
  },
  {
    id: 36,
    url: 'https://www.kuncie.com/mentors/didik-ninik-thowok/82',
    code: 82,
    type: 'mentors',
    title: 'Didik Nini Thowok - Maestro Seni Tari dan Seni Rupa',
    description: 'Didik Ninik Thowok telah lebih dari 50 tahun menggeluti dunia seni. Karyanya tidak hanya diakui di Indonesia, namun menarik perhatian seluruh dunia.',
  },
  {
    id: 37,
    url: 'https://www.kuncie.com/mentors/ignasius-jonan/83',
    code: 83,
    type: 'mentors',
    title: 'Kisah Sukses Ignasius Jonan Dapat Kamu Pelajari di Kuncie',
    description: 'Kisah sukses dari Ignasius Jonan sangat beragam, mulai dari menjadi menteri ESDM, Menteri Perhubungan, Hingga menjadi dirut PT KAI. Simak kisahnya di Kuncie.',
  },
  {
    id: 38,
    url: 'https://www.kuncie.com/mentors/jusuf-kalla/84',
    code: 84,
    type: 'mentors',
    title: 'Jusuf Kalla Berbagi Pengalaman dan Perjalanan Bisnisnya',
    description: 'Jusuf Kalla atau JK merupakan Mantan Wakil Presiden Indonesia. Beliau juga memiliki pengalaman dalam bisnis yang begitu lama dengan Kalla Group.',
  },
  {
    id: 39,
    url: 'https://www.kuncie.com/mentors/tika-mulya/85',
    code: 85,
    type: 'mentors',
    title: 'Belajar Dari VP Brand Elzatta - Tika Mulya',
    description: 'Tika Mulya merupakan VP Brand Elzatta yang bertanggung jawab mengelola brand dari Elzatta terutama untuk channel digitalnya. Simak selengkapnya disini',
  },
  {
    id: 40,
    url: 'https://www.kuncie.com/mentors/rizka-romadhona/86',
    code: 86,
    type: 'mentors',
    title: 'Kelas Dari Rizka Romadhona, Pemilik Lapis Bogor Sangkuriang',
    description: 'Rizka berhasil melalui rintangan dan Lapis Bogor Sangkuriang sekarang terkenal khas sebagai oleh-oleh wajib dari Kota Bogor.',
  },
  {
    id: 41,
    url: 'https://www.kuncie.com/mentors/nicko-widjaja/87',
    code: 87,
    type: 'mentors',
    title: 'Kelas Dari Nicko Widjaja, Pionir Investasi Teknologi',
    description: 'Nicko dikenal sebagai pionir dalam bidang investasi teknologi di Indonesia. Di Kuncie Ia berbicara seputar bisnis dan investasi. Simak modulnya di Kuncie.',
  },
  {
    id: 42,
    url: 'https://www.kuncie.com/mentors/butet-kertaredjasa/90',
    code: 90,
    type: 'mentors',
    title: 'Butet Kertaredjasa Berbagi Pengalaman di Industri Kreatif',
    description: 'Seorang seniman dan aktor kondang dari Indonesia, Butet Kertaredjasa memiliki segudang pengalaman dalam industri kreatif Indonesia',
  },
  {
    id: 43,
    url: 'https://www.kuncie.com/mentors/gita-wirjawan/91',
    code: 91,
    type: 'mentors',
    title: 'Kelas Bisnis Gita Wirjawan - Pengusaha dan Edukator Sukses',
    description: 'Gita Wirjawan adalah seorang pengusaha yang pernah menjabat sebagai Menteri Perdagangan Indonesia. Yuk belajar dari Pak Gita tentang membangun bisnis.',
  },
  {
    id: 44,
    url: 'https://www.kuncie.com/mentors/bukalapak/92',
    code: 92,
    type: 'mentors',
    title: 'Kelas Dari Bukalapak Tentang Bisnis Online eCommerce',
    description: 'Bukalapak merupakan perusahaan e-commerce di Indonesia yang berdiri pada tahun 2010. Simak beberapa topik menarik dari Bukalapak di Kuncie!',
  },
  {
    id: 45,
    url: 'https://www.kuncie.com/mentors/fajar-nugraha/93',
    code: 93,
    type: 'mentors',
    title: 'Kelas Dari Fajar Nughraha, Co-Owner Adorable Project',
    description: 'Fajar Nugraha mendirikan Adorable Project dengan modal 180 ribu rupiah dan memproduksi aksesoris seperti gelang dan kalung hingga memiliki omset ratusan juta.',
  },
  {
    id: 46,
    url: 'https://www.kuncie.com/mentors/anita-loeki/94',
    code: 94,
    type: 'mentors',
    title: 'Kelas Dari Anita Loeki, Co-founder Mineral Botanica',
    description: 'Anita Loeki mendirikan Mineral Botanica yang berhasil menjadi salah satu brand kosmetik terkenal setelah melihat sebuah peluang untuk brand kosmetik berkualitas.',
  },
  {
    id: 47,
    url: 'https://www.kuncie.com/mentors/michelle-sulistyo/95',
    code: 95,
    type: 'mentors',
    title: 'Kelas Dari Michelle Sulistyo, Kulo Group Owner',
    description: 'Michelle yang memang suka kopi sebelum membangun bisnis Kedai Kopi Kulo. Ia juga berhasil melebarkan sayap hingga ke bisnis all you can eat, sambal, dan Boba.',
  },
  {
    id: 48,
    url: 'https://www.kuncie.com/mentors/hendy-setiono/106',
    code: 106,
    type: 'mentors',
    title: 'Apa Yang Menjadi Kunci Sukses Hendy Setiono?',
    description: 'Hendy Setiono adalah CEO dari Baba Rafi Enterprise yang saat ini menaungi beberapa brand seperti Kebab Turki Baba Rafi, Cakekinian, danAura Dermatology',
  },
  {
    id: 49,
    url: 'https://www.kuncie.com/mentors/handoko-hendroyono/107',
    code: 107,
    type: 'mentors',
    title: 'Kelas Handoko Hendroyono, Founder M Bloc & Filosofi Kopi',
    description: 'Mulai dari Filosofi Kopi hingga M Bloc Space. Handoko Hendroyono terus berupaya menciptakan bisnis yang dapat berinteraksi secara personal',
  },
  {
    id: 50,
    url: 'https://www.kuncie.com/mentors/ridho-adityawan/109',
    code: 109,
    type: 'mentors',
    title: 'Kelas Dari Ridho Adityawan, Founder Ayam Gepuk Pak Gembus',
    description: 'Dengan modal awal 26 juta rupiah, kini bisnis ayam gepuk pak gembus beromset 8 miliar dan tersebar di seluruh Indonesia dan beberapa negara tetangga',
  },
  {
    id: 51,
    url: 'https://www.kuncie.com/mentors/pieter-lydian/110',
    code: 110,
    type: 'mentors',
    title: 'Mengenal Pieter Lydian, Country Director Facebook Indonesia',
    description: 'Sudah 25 tahun Pieter melanglang buana di dunia teknologi. Pieter Lydian akhirnya ditunjuk menjadi Country Director dari Facebook Indonesia sampai sekarang.',
  },
  {
    id: 52,
    url: 'https://www.kuncie.com/mentors/ainun-chomsun/112',
    code: 112,
    type: 'mentors',
    title: 'Kelas Ainun Chomsun - Pendiri Akademi Berbagi',
    description: 'Ainun Chomsun adalah seorang pendiri Akademi Berbagi, sebuah gerakan sosial yang berupaya untuk memberikan edukasi yang gratis kepada masyarakat luas',
  },
  {
    id: 53,
    url: 'https://www.kuncie.com/mentors/sonny-arca/113',
    code: 113,
    type: 'mentors',
    title: 'Kelas Dari Owner Martabak Pizza Orins - Sonny Arca',
    description: 'Beberapa bisnis pernah Sonny jalankan sampai akhirnya menciptakan bisnis martabak yang unik yaitu Martabak Pizza Orins yang terkenal di Seluruh Indonesia',
  },
  {
    id: 54,
    url: 'https://www.kuncie.com/mentors/safir-senduk/114',
    code: 114,
    type: 'mentors',
    title: 'Kelas Keuangan Dari Safir Senduk - Master Financial Planner',
    description: 'Sebagai seorang financial planner, Safir telah membagikan ilmunya di Indonesia hingga luar negeri. Yuk pelajari aspek finansial bagi seorang pebisnis.',
  },
  {
    id: 55,
    url: 'https://www.kuncie.com/mentors/jeffry-jouw/115',
    code: 115,
    type: 'mentors',
    title: 'Jejouw, Pecinta Sneakers Yang Mendirikan Kick Avenue dan USS',
    description: 'Jeffry Jouw merupakan penggemar sepatu sneakers yang sukses merubah hobinya menjadi bisnis dengan mendirikan Kick Avenue dari nol bersama teman-temannya.',
  },
  {
    id: 56,
    url: 'https://www.kuncie.com/mentors/leonard-theosabrata/116',
    code: 116,
    type: 'mentors',
    title: 'Belajar Dari Direktur SMESCO Indonesia - Leonard Theobrata',
    description: 'Pak Leo diamanahi posisi direktur utama SMESCO Indonesia untuk meberdayakan UMKM di Indonesia. Yuk belajar dari beliau agar UMKM dapat lebih maju!',
  },
  {
    id: 57,
    url: 'https://www.kuncie.com/mentors/darwis-triadi/117',
    code: 117,
    type: 'mentors',
    title: 'Belajar Fotografi Langsung Oleh Ahlinya - Darwis Triadi',
    description: 'Darwis Triadi merupakan mantan pilot yaag menggeluti karir di bidang fotografi. Di Kuncie, Ia menjadi mentor untuk kamu yang ingin belajar fotografi dari dasar.',
  },
  {
    id: 58,
    url: 'https://www.kuncie.com/mentors/alditsa-decca/118',
    code: 118,
    type: 'mentors',
    title: 'Ini dia Founder Dari SundaySundayCo, Alditsa Decca',
    description: 'Decca memulai SundaySundayCo bermodalkan uang sejumlah 5 juta rupiah saja. Bagaimana Perjalanan personel Pee Wee Gaskins ini merintis usaha tersebut?',
  },
  {
    id: 59,
    url: 'https://www.kuncie.com/mentors/melvin-mumpuni/119',
    code: 119,
    type: 'mentors',
    title: 'Belajar dari Melvin Mumpuni dalam Mendirikan Finansialku',
    description: 'Melvin menemukan sebuah masalah yang membuatnya berniat untuk mendirikan Finansialku.com pada saat pengerjaan tesisnya di bidang keuangan. Simak disini!',
  },
  {
    id: 60,
    url: 'https://www.kuncie.com/mentors/fitriani-rahmah/120',
    code: 120,
    type: 'mentors',
    title: 'Membangun Bisnis Seperti CEO Serasa Salad Bar',
    description: 'Fitri memulai bisnis Serasa Salad Bar dari berjualan saus salad rumahan hingga sekarang memiliki cabang di 3 kota dan masih banyak lainnya di waktu dekat!',
  },
  {
    id: 61,
    url: 'https://www.kuncie.com/mentors/1000-startup-digital/121',
    code: 121,
    type: 'mentors',
    title: 'Pelatihan dari Gerakan 1000 Startup Digital Di Kuncie',
    description: 'Gerakan 1000 Startup Digital kini bekerjasama dengan Kuncie untuk meningkatkan pelatihan calon pebisnis perusahaan startup secara online',
  },
  {
    id: 62,
    url: 'https://www.kuncie.com/mentors/christian-sugiono/122',
    code: 122,
    type: 'mentors',
    title: 'Belajar Bisnis Dari Christian Sugiono di Kuncie',
    description: 'Selain dikenal sebagai aktor papan atas di Indonesia, Ia juga memiliki beberapa bisnis yang sukses. Yuk Belajar dari Christian Sugiono!',
  },
  {
    id: 63,
    url: 'https://www.kuncie.com/mentors/iman-taufiq-djayadiningrat/123',
    code: 123,
    type: 'mentors',
    title: 'Kelas Iman Taufiq Djayadiningrat Pemilik Hairnerds Studio',
    description: 'Iman merupakan seorang pekerja kantoran yang memiliki hobi untuk memotong rambut dan menjadikannya bisnis, yaitu Hairnerds Studio.',
  },
  {
    id: 64,
    url: 'https://www.kuncie.com/mentors/rangga-umara/124',
    code: 124,
    type: 'mentors',
    title: 'Yuk Cari Tahu Faktor Keberhasilan Rangga Umara di Kuncie',
    description: 'Rangga Umara merupakan owner dari Pecel Lele Lela yang sukses dia bangung dari nol. Cari tahu faktor keberhasilan dan kisah suksesnya disini!',
  },
  {
    id: 65,
    url: 'https://www.kuncie.com/mentors/dayu-dara-permata/125',
    code: 125,
    type: 'mentors',
    title: 'Dayu Dara Permata, Pendiri Perusahaan Startup Pinhome',
    description: 'Dayu menciptakan perusahaan startupnya sendiri yaitu Pinhome untuk membuat revolusi terhadap sektor properti di Indonesia. Simak kelasnya di Kuncie!',
  },
  {
    id: 66,
    url: 'https://www.kuncie.com/mentors/christie-basil/126',
    code: 126,
    type: 'mentors',
    title: 'Christie Basil, Menemukan Sukses di Industri Fashion',
    description: 'Seorang pebisis muda yang menemukan passionnya di industri fashion, Christie berbisnis di segmen ready to wear dan lebih sepsifiknya di wedding dresses.',
  },
  {
    id: 67,
    url: 'https://www.kuncie.com/mentors/rizky-arief-dwi-prakoso/127',
    code: 127,
    type: 'mentors',
    title: 'Kelas Dari Rizky Arief Dwi Prakoso, CEO Hmns Perfume',
    description: 'Rizky Arief memiliki track record yang mengesankan. Mulai dari CEO di NAH Project yang berlanjut menjadi CEO dari HMNS, sebuah brand Parfum ternama di Indonesia',
  },
  {
    id: 68,
    url: 'https://www.kuncie.com/mentors/arrofi-ramadhan/128',
    code: 128,
    type: 'mentors',
    title: 'Arrofi Ramadhan | Desainer Interior dan Founder Ruang A23',
    description: 'Arrofi memiliki keahlian dalam desain interior ruangan. Ia di kenal luas karena konten edukasi seputar desain interior di Tiktok. Simak Kelasnya disini!',
  },
  {
    id: 69,
    url: 'https://www.kuncie.com/mentors/dinda-puspitasari/129',
    code: 129,
    type: 'mentors',
    title: 'Dinda Puspitasari, Seorang Fashion Illustrator Ternama di Indonesia',
    description: 'Dinda adalah seorang fashion ilustrator ternama di Indonesia. Di Kuncie, Ia membagiakan beberapa tips terkait dengan keahliannya sebagai ilustrator',
  },
  {
    id: 70,
    url: 'https://www.kuncie.com/mentors/andanu-prasetyo/130',
    code: 130,
    type: 'mentors',
    title: 'Kelas Dari Andanu Prasetyo, Pendiri Toko Kopi Tuku',
    description: 'Toko kopi tuku merupakan pelopor tren es kopo susu. Di Kuncie, sang founder Andany Prasetyo atau Tio membagikan caranya untuk ekspansi bisnis.',
  },
  {
    id: 71,
    url: 'https://www.kuncie.com/mentors/aghnia-punjabi/131',
    code: 131,
    type: 'mentors',
    title: 'Kelas Dari Aghnia Punjabi, Owner dari Sylmi Basic',
    description: 'Seorang Key Opinion Leader dengan keahlian di bidang kecantikan dan make-up, Aghnia Punjabi juga memiliki jiwa kewirausahaan yang kuat.',
  },
  {
    id: 72,
    url: 'https://www.kuncie.com/mentors/bagus-widnyana/133',
    code: 133,
    type: 'mentors',
    title: 'Kelas Ida Bagus Andre Widyana Tentang Konten Tiktok',
    description: 'Bagus Andre Widyana merupakan seorang content creator yang sangat terkenal di Tiktok dan Instagram. Ia telah bekerjasama dengan brand terkenal di Indonesia',
  },
  {
    id: 73,
    url: 'https://www.kuncie.com/mentors/sonia-basil/134',
    code: 134,
    type: 'mentors',
    title: 'Founder Cakeology, Sonia Basil Bebagi Tips di Kuncie',
    description: 'Sonia Basil merupakan Seorang serial entrepereneur di bidang F&B, yang berhasil menciptakan 2 brand ternama dalam bidang cake design, yaitu Keku dan Cakeology.',
  },
  {
    id: 74,
    url: 'https://www.kuncie.com/mentors/najla-bisyir/135',
    code: 135,
    type: 'mentors',
    title: 'Najla Bisyir | Pemilik Bittersweet By Najla Berbagi d Kuncie',
    description: 'Najla Bisyir adalah seorang ibu rumah tangga sekaligus owner dari Bittersweet by Najla. Ia memberikan beberapa tips untuk membangun bisnis ibu rumah tangga.',
  },
  {
    id: 75,
    url: 'https://www.kuncie.com/mentors/bimo-kusumo/136',
    code: 136,
    type: 'mentors',
    title: 'Bimo Kusumo Berbagi Tips Trik Seputar Voice Over di Kuncie',
    description: 'Bimo Kusumo adalah voice ove talent yang sudah memulai karirnya sejak kecil. Ia pernah menjadi penyiar beberapa radio ternama di Indonesia.',
  },
  {
    id: 76,
    url: 'https://www.kuncie.com/mentors/dee-lestari/137',
    code: 137,
    type: 'mentors',
    title: 'Belajar Dari Dewi Dee Lestari, Penulis Novel Supernova',
    description: 'Dee Lestari adalah salah satu penulis novel terbaik di Indonesia. Karyanya Supernova pasti tidak asing lagi. Yuk belajar cara menulis novel di Kuncie!',
  },
  {
    id: 77,
    url: 'https://www.kuncie.com/mentors/andy-sukma-lubis/138',
    code: 138,
    type: 'mentors',
    title: 'Belajar Cara Membuat Presentasi dari Melukis Slide',
    description: 'Andy Sukma Lubis merupakan founder dari melukis slide yang merupakan platform untuk belajar membuat presentasi profesinal dan upskill presentasi.',
  },
  {
    id: 78,
    url: 'https://www.kuncie.com/mentors/petra-barus/139',
    code: 139,
    type: 'mentors',
    title: 'Petra Barus: Pendiri UrbanIndo dan Certified AWS Developer',
    description: 'Tonton modul seputar tech startup, engineering leadership dan cloud computing oleh seorang yang sangat kental dengan dunia teknologi, Petra Barus.',
  },
  {
    id: 79,
    url: 'https://www.kuncie.com/mentors/andry-susanto/140',
    code: 140,
    type: 'mentors',
    title: 'Chef Andry Susanto | Owner Oma Elly Kitchen PIK',
    description: 'Ikuti kelas dari Chef Andry Susanto, owner sekaligus chef dari Oma Elly kitchen yang terkenal dengan fine dining experiencenya.',
  },
  {
    id: 80,
    url: 'https://www.kuncie.com/mentors/angelina-prisilla/141',
    code: 141,
    type: 'mentors',
    title: 'Angelina Prisilla | Pemilik dan Owner Wijaya Betta',
    description: 'Yuk kenalan sekaligus belajar dari owner Wijaya Betta, Angelina Prisilla yang telah memenangkan berbagai penghargaan kontes ikan cupang skala internasional',
  },
  {
    id: 81,
    url: 'https://www.kuncie.com/mentors/gembong-suwito/142',
    code: 142,
    type: 'mentors',
    title: 'Kelas Investasi dari Komunitas Investor Bijak',
    description: 'Gembong Suwito yang merupakan penasehat investasi dari komunitas investor bijak dan financial planner Finansialku berbagi kelas untuk belajar investasi.',
  },
  {
    id: 82,
    url: 'https://www.kuncie.com/mentors/martha-suherman/143',
    code: 143,
    type: 'mentors',
    title: 'Kelas Fotografi Online Gratis Dari Martha Suherman',
    description: 'Yuk ikutan kelas fotografi online gratis dari salah satu fotografer terbaik di Indonesia, Martha Suherman. Download aplikasi Kuncie sekarang!',
  },
  {
    id: 83,
    url: 'https://www.kuncie.com/mentors/ardi-kemara-pradipta/144',
    code: 144,
    type: 'mentors',
    title: 'Ardi Kemara Putra | Digital Marketing Expert',
    description: 'Ardi Kemara Pradipta merupakan seorang digital marketing expert, marketing analysis, dan online brand development yang berpengalaman selama lebih dari 10 tahun.',
  },
  {
    id: 84,
    url: 'https://www.kuncie.com/mentors/faza-meonk/145',
    code: 145,
    type: 'mentors',
    title: 'Profil Faza Meonk, Pencipta Karakter Komik Si Juji',
    description: 'Faza Meonk merupakan komikus yang sukses menciptakaan karakter yang bernama Si Juki. Tonton video modul pembelajaran tetang komik dari Faza Meonk disini.',
  },
  {
    id: 85,
    url: 'https://www.kuncie.com/mentors/mikael-jasin/147',
    code: 147,
    type: 'mentors',
    title: 'Inilah Mikael Jasin, Barista Internasional Yang Mendunia',
    description: 'Mikael Jasin merupakan barista internasional yang mendapatkan beberapa penghargaan dari world barista championship yang memiliki 2 perusahaan di industri kopi',
  },
  {
    id: 86,
    url: 'https://www.kuncie.com/mentors/jessica-lin/148',
    code: 148,
    type: 'mentors',
    title: 'Jessica Lin, Pendiri Everwhite Berbagi Kiat Sukses di Kuncie',
    description: 'Jessica Lin merupakan pendiri Everwhite. Ia mendirikan Everwhite dan sukses menggaet banyak pelanggan hingga Ia masuk ke jajaran Forbes 30 under 30 Asia.',
  },
  {
    id: 87,
    url: 'https://www.kuncie.com/mentors/rex-marindo/149',
    code: 149,
    type: 'mentors',
    title: 'Rex Marirndo | Founder Warunk Upnormal dan Bakso Boedjangan',
    description: 'Belajar segala sesuatu tentang bisnis kuliner langsung dari Rex Marindo yang merupakan founder warunk upnormal dan bakso boedjangan.',
  },
  {
    id: 88,
    url: 'https://www.kuncie.com/mentors/afif-bimantara/173',
    code: 173,
    type: 'mentors',
    title: 'Profil Afif Bimantara, Desainer UI UX yang mendunia',
    description: 'Yuk kenalan dengan Afif Bimantara, seorang desainer ui ux yang karyanya sudah melalangbuana ke seluruh dunia.',
  },
  {
    id: 89,
    url: 'https://www.kuncie.com/mentors/gojek/178',
    code: 178,
    type: 'mentors',
    title: 'Gojek Berbagi Ilmu di Kuncie!',
    description: 'Siapa sih yang tidak kenal Gojek? salah satu platform multifungsi terbesar di Indonesia ini berbagi ilmu di Kuncie loh! Mau tau tentang apa? Simak disini!',
  },
  {
    id: 90,
    url: 'https://www.kuncie.com/mentors/teuku-daffa/179',
    code: 179,
    type: 'mentors',
    title: 'Teuku Daffa: Tiktok Content Creator Dan Public Speaker',
    description: 'Teuku Daffa merupakan seorang digital creator yang membahas seputar public speaking. Yuk ikuti kelas public speaking dari DaffaSpeaks!',
  },
  {
    id: 91,
    url: 'https://www.kuncie.com/mentors/pijar-mahir/180',
    code: 180,
    type: 'mentors',
    title: 'Pijar Mahir: Platform Pembelajaran Digital Tersertifikasi',
    description: 'Pijar Mahir merupakan platform pembelajaran digital bersertifikat. Mereka berbagi ilmu terkait strategi branding dan fundamentalnya.',
  },
  {
    id: 92,
    url: 'https://www.kuncie.com/mentors/sharen-fernanda/181',
    code: 181,
    type: 'mentors',
    title: 'Cara Mengelola Aset Properti oleh Sharen Fernanda',
    description: 'Sharen Fernanda adalah investor properti yang memiliki pengalaman mumpuni pada bidangnya. Ia juga merupakan platform crowdfunding pertama di Indonesia',
  },
  {
    id: 93,
    url: 'https://www.kuncie.com/mentors/viki-rahardja/182',
    code: 182,
    type: 'mentors',
    title: 'Cara Menjadi Barista ala Viki Rahardja',
    description: 'Viki adalah seorang barista yang berhasi mendirikan coffee shop sendiri yaitu Kopi Bu Dokter. Cari tahu cara menjadi barista bersama Viki!',
  },
  {
    id: 94,
    url: 'https://www.kuncie.com/mentors/steven-wang/183',
    code: 183,
    type: 'mentors',
    title: 'Steven Wang: Tips Menjadi Reseller dan Dropshiper',
    description: 'Steven Wang merupakan CEO dropzilla dan #footmemo-nya di Instagram dan Tiktok. Disini dia berbagi kiat sukses menjadi reseller dan dropshiper lho!',
  },
  {
    id: 95,
    url: 'https://www.kuncie.com/mentors/nadya-amatullah/184',
    code: 184,
    type: 'mentors',
    title: 'Nadjani: Kiat Sukses Mendirikan Bisnis Busana Muslim',
    description: 'Nadya Amatullah, seorang founder sekaligus desainer Nadjani Indonesia membagikan kiat suksesnya dalam berbisnis busana muslim dan menjadi entrepreneur.',
  },
  {
    id: 96,
    url: 'https://www.kuncie.com/mentors/jie-w-kusumo/187',
    code: 187,
    type: 'mentors',
    title: 'Kenalan Dengan Fotografer Makanan Terkenal di Indonesia',
    description: 'Jie Wahyono Kusumo, dikenal juga sebagai Jiewa Vieri adalah seorang blogger dan fotografer makanan Indonesia. Tonton Modul\\nya di Kuncie!',
  },
  {
    id: 97,
    url: 'https://www.kuncie.com/mentors/inboundid/188',
    code: 188,
    type: 'mentors',
    title: 'Kelas dari InboundID, Digital Marketing Agency di Jakarta',
    description: 'InboundID merupakan digital marketing agency yang ada di Jakarta. Di video ini, mereka akan berbagi tutorial seputar digital marketing secara gratis.',
  },
  {
    id: 98,
    url: 'https://www.kuncie.com/mentors/niko-julius/189',
    code: 189,
    type: 'mentors',
    title: 'Kelas Instagram Organik dari Niko Julius',
    description: 'Yuk ikuti kelas dari Niko Julius tentang instagram organik serta cara memaksimalkannya hanya di Kuncie. Kelas ini Gratis lho!',
  },
  {
    id: 99,
    url: 'https://www.kuncie.com/mentors/iseller/190',
    code: 190,
    type: 'mentors',
    title: 'iSeller: Omichannel Platform Digital Untuk Kemudahan Bisnis',
    description: 'Pada kesempatan kali ini iSeller akan berbagi bagaimana memaksimalkan sistem pos untuk memudahkan bisnis restoran yang kamu miliki.',
  },
  {
    id: 100,
    url: 'https://www.kuncie.com/mentors/dellaneira-adzra/192',
    code: 192,
    type: 'mentors',
    title: 'Profil Pendiri Choco Glitz Floris Dellaneira Adzra',
    description: 'Yuk belajar seluk beluk dan strategi bisnis dari choco glitz florist langsung oleh foundernya, Dellaneira Adzra.',
  },
  {
    id: 101,
    url: 'https://www.kuncie.com/module/strategi-konten-dan-sosial-media/16',
    code: 16,
    type: 'module',
    title: 'Cara Memenangkan Strategi Pemasaran Melalui Sosial Media',
    description: 'Media sosial adalah bagian penting dari sebuah bisnis. Jadi bagaimana strategi pemasaran melalu sosial media kamu bisa memenagkan permainan ini?',
  },
  {
    id: 102,
    url: 'https://www.kuncie.com/module/membangun-bisnis-fashion-dengan-landasan-kuat-tren-pasar-dan-data/31',
    code: 31,
    type: 'module',
    title: 'Cara Membangun Bisnis Fashion Dengan Landasan Kuat',
    description: 'Cempaka Asriani memberi tahu caranya sendiri untuk membangun bisnis fashion dengan landasan kuat. Simak selengkapnya hanya di Kuncie!',
  },
  {
    id: 103,
    url: 'https://www.kuncie.com/module/pentingnya-bekerja-keras-untuk-meraih-mimpi-dengan-arnold-poernomo/32',
    code: 32,
    type: 'module',
    title: 'Pentingnya Bekerja Keras untuk Meraih Mimpi',
    description: 'Semua orang memiliki mimpinya masing-masing, namun hanya beberapa yang dapat mencapainya. Itulah alasan pentingnya bekerja keras agar dapat meraih semua mimpi!',
  },
  {
    id: 104,
    url: 'https://www.kuncie.com/module/customer-service-kuat-untuk-branding-bisnis-yang-kuat/33',
    code: 33,
    type: 'module',
    title: 'Customer Service Untuk Strategi Branding & Bisnis yang kuat!',
    description: 'Bersama dengan Elwiana, mari belajar bagaimana strategi untuk menguatkan customer service agar bisnis dan brand kamu semakin kuat!',
  },
  {
    id: 105,
    url: 'https://www.kuncie.com/module/memulai-dan-membesarkan-bisnis-sampingan-pertamamu/34',
    code: 34,
    type: 'module',
    title: 'Memulai dan Mengembangkan Bisnis Sampingan Yang Menjanjikan',
    description: 'Di course ini, Owner dari Nourish Indonesia akan berbagi ilmu tentang cara memulai bisnis sampingan sendiri, dan bagaimana cara mengembangkannya!',
  },
  {
    id: 106,
    url: 'https://www.kuncie.com/module/cara-memuaskan-kehausan-bisnis-untuk-ekspansi/35',
    code: 35,
    type: 'module',
    title: 'Cara Mengembangkan Bisnis ala CEO HAUS!',
    description: 'Di course ini, Gufron Syarief akan berbagi berbagai cara mengembangkan sebuah bisnis hingga dapat berekspansi. Simak selengkapnya hanya di Kuncie!',
  },
  {
    id: 107,
    url: 'https://www.kuncie.com/module/perjalanan-digitalisasi-umkm-dengan-teten-masduki-menteri-umkm-dan-koperasi/36',
    code: 36,
    type: 'module',
    title: 'Perjalanan Digitalisasi UMKM dengan Teten Masduki',
    description: 'Bagaimanakah perjalanan menuju digitalisasi UMKM di Indonesia? Mari cari tahu, bersama dengan Teten Masduki, Menteri UMKM dan Koperasi, periode 2019 - 2024.',
  },
  {
    id: 108,
    url: 'https://www.kuncie.com/module/perjalanan-menuju-indonesia-digital-bersama-rachmat-kaimuddin-ceo-bukalapak/37',
    code: 37,
    type: 'module',
    title: 'Cara Menghadapi Era Digital Saat Ini Ala Rachmat Kaimuddin',
    description: 'Untuk siap bersaing pada masa transisi digital yang masif ini, penting untuk tahu cara menghadapi era digital saat ini. Kupas tuntas bersama Rachmat Kaimuddin!',
  },
  {
    id: 109,
    url: 'https://www.kuncie.com/module/aspekaspek-penting-ketika-membuat-brand-fashion-pertamamu/38',
    code: 38,
    type: 'module',
    title: 'Cara Membuat Brand Fashion Sendiri Serta Aspek-Aspeknya',
    description: 'Inilah cara membuat brand fashion sendiri beserta aspek-aspek yang perlu diperhatikan. Bersama Mutiara Kamila dari THENBLANK, kita kupas tuntas topik ini!',
  },
  {
    id: 110,
    url: 'https://www.kuncie.com/module/menjelajahi-arti-seorang-pemimpin-muda-dengan-putri-tanjung/39',
    code: 39,
    type: 'module',
    title: 'Menjelajahi Arti Seorang Pemimpin Muda dengan Putri Tanjung',
    description: 'Cari tahu apa arti seorang pemimpin dari staf khusus presiden dan Chief of Creative Experience Office (CXO), Putri Tanjung.',
  },
  {
    id: 111,
    url: 'https://www.kuncie.com/module/a-ke-z-untuk-franchising-bisnis-fb/40',
    code: 40,
    type: 'module',
    title: 'Cara Membuat Franchise Yang Sukses Sendiri',
    description: 'Di modul ini, Danu Sofwan akan berbagi cara untuk membuat franchisemu sendiri! Ia merupakan pengusaha franchise sukses dengan brand Radja Cendol dan Es Teh',
  },
  {
    id: 112,
    url: 'https://www.kuncie.com/module/kiatkiat-menjadi-penulis-kreatif-ala-raditya-dika/42',
    code: 42,
    type: 'module',
    title: 'Cara Menjadi Penulis Kreatif ala Raditya Dika',
    description: 'Di course ini, mari cari tahu bagaimana cara menjadi penulis yang kreatif, ala Raditya Dika. Tips ini cocok untuk pemula lho!',
  },
  {
    id: 113,
    url: 'https://www.kuncie.com/module/personal-branding-101-untuk-masa-depan-yang-cemerlang/43',
    code: 43,
    type: 'module',
    title: 'Apa Itu Personal Branding Dan Elemen Utamanya',
    description: 'Cari tahu apa itu personal branding dan bagaimana caranya untuk memperlakukan diri kita sendiri sebagai sebuah brand bersama Brilliant Liu!',
  },
  {
    id: 114,
    url: 'https://www.kuncie.com/module/langkah-awal-sebuah-bisnis-kopi-di-pasar-yang-ramai/44',
    code: 44,
    type: 'module',
    title: 'Cara Memulai Bisnis Kopi Dan Bersaing di Pasar',
    description: 'Kelas ini akan menunjukkan cara untuk memulai bisnis kopi di tengah persaingan yang ketat. Bersama Elisa Suteja, topik tersebut akan dibahas tuntas!',
  },
  {
    id: 115,
    url: 'https://www.kuncie.com/module/langkah-awal-dalam-memulai-bisnis-skincaremu-sendiri/45',
    code: 45,
    type: 'module',
    title: 'Ini Dia Cara Memulai Bisnis Skincare ala Skin Game',
    description: 'Memulai sebuah bisnis skincare tidak pernah mudah. Bagaimana Michella Ham mendirikan Skin Game? Mari cari tahu caranya di course ini!',
  },
  {
    id: 116,
    url: 'https://www.kuncie.com/module/cara-pemakaian-copywriting-untuk-pemasaran-bisnis-yang-lebih-hebat/46',
    code: 46,
    type: 'module',
    title: 'Belajar Copywriting untuk Pemasaran Bisnis Secara Gratis',
    description: 'Bersama dengan Raharja Putra, mari kita belajar copywriting untuk pemasaran bisnis online yang efektif. Kelas ini gratis dan terbuka untuk pemula loh!',
  },
  {
    id: 117,
    url: 'https://www.kuncie.com/module/kepemimpinan-di-era-digital-bersama-setyanto-hantoro/48',
    code: 48,
    type: 'module',
    title: 'Cara Membangun Karir dan Kepemimpinan di Era Digital',
    description: 'Era Digital mendisrupsi banyak sekali sisi kehidupan. Termasuk bagaimana cara membangun karir dan memimpin orang lain. Simak selengkapnya di Kuncie!',
  },
  {
    id: 118,
    url: 'https://www.kuncie.com/module/mengelola-stok-dengan-mudah-di-platform-shopee/49',
    code: 49,
    type: 'module',
    title: 'Cara Mengelola Stok Barang di Platform Shopee',
    description: 'Banyak yang bingung bagaimana cara mengelola stok sehingga operasi bisa berjalan secara lancar. Yuk, kita cari tahu caranya bareng-bareng di course ini!',
  },
  {
    id: 119,
    url: 'https://www.kuncie.com/module/cara-kembali-cuan-di-shopee-abis-tutup-toko-setelah-liburan/50',
    code: 50,
    type: 'module',
    title: 'Cara Cuan Setelah Memanfaatkan Fitur Toko Libur Shopee',
    description: 'Bagaimana sih caranya sehingga pelanggan-pelanggan kita akan tahu bahwa kita sudah kembali menggunakan fiitur toko libur shopee? Yuk cari tahu di course ini!',
  },
  {
    id: 120,
    url: 'https://www.kuncie.com/module/mulai-berjualan-online-melalui-platform-shopee/51',
    code: 51,
    type: 'module',
    title: 'Ini Dia Video Tutorial Cara Jualan di Shopee Secara Lengkap!',
    description: 'Mari cari tahu cara jualan di shopee melalui video ini. Di zaman modern ini, hampir semua orang ingin memulai bisnis di platform shopee karena mudah dimengerti.',
  },
  {
    id: 121,
    url: 'https://www.kuncie.com/module/menciptakan-bisnis-fb-berdasarkan-visi-misi-yang-kuat/52',
    code: 52,
    type: 'module',
    title: 'Pentingnya Visi Dan Misi Sebuah Bisnis Yang Kuat',
    description: 'Di course kali ini, kita akan belajar pentingnya visi misi yang mengedepankan para pelanggan. Mari cari tahu lebih lanjut dengan Fiona Anthony dari BakePack',
  },
  {
    id: 122,
    url: 'https://www.kuncie.com/module/the-art-of-financial-management/53',
    code: 53,
    type: 'module',
    title: 'Seni dan Cara Mengatur Keuangan ala Pandu Sjahrir',
    description: 'Belajar cara mengatur keuangan sangatlah penting. Bersama Pandu Sjahrir, kita akan belajar aspek dalam pengaturan uang dan investasi.',
  },
  {
    id: 123,
    url: 'https://www.kuncie.com/module/berkarya-dengan-passion-bersama-joko-anwar/54',
    code: 54,
    type: 'module',
    title: 'Pentingnya Passion Dalam Berkarya - Joko Anwar',
    description: 'Joko Anwar dikenal melalui beragam film yang dibuatnya yang memenangkan beragam penghargaan. Inilah pentingnya passion dalam berkarya ala Joko Anwar',
  },
  {
    id: 124,
    url: 'https://www.kuncie.com/module/entrepreneurship-adalah-mindset-bersama-sandiaga-uno/55',
    code: 55,
    type: 'module',
    title: 'Materi Entrepreneurship Dari Sandiaga Uno di Kuncie',
    description: 'Di kelas ini, Sandiaga akan memberikan materi entrepreneurship dan pentingnya ketangguhan dengan memberikan contoh pengalamannya serta tips-tips menghadapinya.',
  },
  {
    id: 125,
    url: 'https://www.kuncie.com/module/cara-ekspansi-bisnis-untuk-para-pebisnis-baru/56',
    code: 56,
    type: 'module',
    title: 'Cara ekspansi bisnis untuk para pebisnis baru!',
    description: 'Di course kali ini, bersama dengan Devy Natalia, kita akan cari tahu cara ekspansi bisnis khususnya untuk kamu para pebisnis baru.',
  },
  {
    id: 126,
    url: 'https://www.kuncie.com/module/cara-mendapatkan-foto-eksepsional-dengan-alat-konvensional/64',
    code: 64,
    type: 'module',
    title: 'Belajar Fotografi Dengan Hp Dan Ciptakan Foto Yang Keren',
    description: 'Belajar mengambil foto berkualitas adalah sebuah keharusan bagi sebuah bisnis. Disini, kita akan belajar bagaimana cara mengambil foto keren dengan HP!',
  },
  {
    id: 127,
    url: 'https://www.kuncie.com/module/menciptakan-bisnis-dengan-fokus-kepada-gap-di-supply-demand/65',
    code: 65,
    type: 'module',
    title: 'Menciptakan bisnis dengan fokus kepada gap di supply & demand',
    description: 'Di kursus ini, Reza akan menjelaskan bagaimana step-step mendirikan bisnis yang berawal dari kesadaran gap antara supply dan demand.',
  },
  {
    id: 128,
    url: 'https://www.kuncie.com/module/menciptakan-dan-memasarkan-produk-yang-lekat-di-mata-dan-hati/68',
    code: 68,
    type: 'module',
    title: 'Menjadi Market Leader Yang Lekat di Mata dan Hati',
    description: 'Pernah berfikir, apa sih yang sebenarnya bikin produk mudah dilihat dan diingat? Mari cari tahu cara menjadi market leader bersama dengan Joshua Dave.',
  },
  {
    id: 129,
    url: 'https://www.kuncie.com/module/membuat-dan-menembus-pasar-dengan-identitas-bisnis-yang-kuat/69',
    code: 69,
    type: 'module',
    title: 'Cara Membangun Identitas dan Karakter Bisnis Yang Kuat',
    description: 'Identitas bisnis yang kuat merupakan pembeda dalam bisnis yang dapat membantu menembus pasar yang baru. Kursus ini akan menjelaskannya secara detil!',
  },
  {
    id: 130,
    url: 'https://www.kuncie.com/module/menjadi-juara-di-bisnis-fb-dengan-memaksimalkan-operasional/70',
    code: 70,
    type: 'module',
    title: 'Bagaimana Melaksanakan Manajemen Operasional Yang Baik',
    description: 'Di kursus ini, Andhyka akan menjelaskan bagaimana manajemen operasional yang baik dapat membantu bisnismu! Simak selengkapnya di Kuncie!',
  },
  {
    id: 131,
    url: 'https://www.kuncie.com/module/dari-televisi-menuju-media-digital-bersama-helmy-yahya/72',
    code: 72,
    type: 'module',
    title: 'Dari Televisi Menuju Media Digital Bersama Helmy Yahya',
    description: 'Helmy Yahya merupakan seorang legenda pertelevisian Indonesia. Disini, Helmy akan berbagi kisahnya dalam berkarya di dunia digital!',
  },
  {
    id: 132,
    url: 'https://www.kuncie.com/module/memulai-bisnis-dan-kepemimpinan-bersama-gaery-undarsa/73',
    code: 73,
    type: 'module',
    title: 'Tentang Makna Kepemimpinan Dari Co-Founder Tiket.com',
    description: 'Di kelas ini, Gaery Undarsa (Co-Founder Tiket.com) akan berbagi mengenai makna kepemimpinan dan bagaimana Ia memulai bisnis.',
  },
  {
    id: 133,
    url: 'https://www.kuncie.com/module/model-bisnis-made-to-order-dalam-industri-fashion/74',
    code: 74,
    type: 'module',
    title: 'Mengenal Model Bisnis Made To Order Dalam Industri Fashion',
    description: 'Dalam kursus ini, Alam akan membagikan cerita mengenai bagaimana dia membangun bisnis made-to-order dalam dunia fashion.',
  },
  {
    id: 134,
    url: 'https://www.kuncie.com/module/menciptakan-restoran-untuk-zaman-modern-restoran-online/75',
    code: 75,
    type: 'module',
    title: 'Menciptakan Bisnis Kuliner Online Yang Menjanjikan',
    description: 'Mari cari tahu bersama Gilang, bagaimana cara menciptakan bisnis kuliner online yang menjanjikan layaknya Ia menciptakan dan sukses dengan kepiting nyinyir.',
  },
  {
    id: 135,
    url: 'https://www.kuncie.com/module/meningkatkan-performa-brand-dengan-kolaborasi-dan-website/76',
    code: 76,
    type: 'module',
    title: 'Manfaat Kolaborasi Bisnis Dalam Meningkatkan Performa Brand',
    description: 'Terdapat beragam cara untuk meningkatkan performa brand. Diantaranya dengan kolaborasi bisnis dan membuat website. Simak selengkapnya di Kuncie!',
  },
  {
    id: 136,
    url: 'https://www.kuncie.com/module/promosi-bisnis-melalui-tiktok/77',
    code: 77,
    type: 'module',
    title: 'Cara Promosi Bisnis di TikTok ala Eva Alicia',
    description: 'Dalam kursus ini, Eva akan membagikan pengalamannya dalam melakukan promosi lewat TikTok yang membantu bisnisnya untuk berkembang dengan pesat!',
  },
  {
    id: 137,
    url: 'https://www.kuncie.com/module/semangat-berkarya-seorang-seniman-bersama-didik-ninik-thowok/78',
    code: 78,
    type: 'module',
    title: 'Apa itu Seni Tari dan Cara Menjadi Penari Yang Baik',
    description: 'Di kursus ini, Didik Ninik Thowok akan menjelaskan apa itu seni tari dan menceritakan pengalaman inspiratifnya saat memulai karir nya sebagai penari.',
  },
  {
    id: 138,
    url: 'https://www.kuncie.com/module/terbiasa-dengan-bouncing-back-bersama-ignasius-jonan/79',
    code: 79,
    type: 'module',
    title: 'Cara Bangkit Dari Keterpurukan Dan Kegagalan',
    description: 'Ignasius Jonan memiliki segudang pengalaman bangkit dari sebuah kegagalan dan cara untuk bangkit dari keterpurukan tersebut. Simak caranya di Kuncie.',
  },
  {
    id: 139,
    url: 'https://www.kuncie.com/module/esensi-kepemimpinan-bersama-jusuf-kalla/80',
    code: 80,
    type: 'module',
    title: 'Belajar Kepemimpinan Dari Bapak Jusuf Kalla',
    description: 'Belajar Kepemimpinan dari Jusuf Kalla yang memiliki pengalaman begitu panjang sebagai tampuk kepemimpinan di pemerintahan maupun bisnis.',
  },
  {
    id: 140,
    url: 'https://www.kuncie.com/module/halhal-yang-harus-dihindari-oleh-pengusaha-untuk-menumbuhkan-bisnis/81',
    code: 81,
    type: 'module',
    title: 'Kesalahan Yang Harus Dihindari Oleh Pebisnis Pemula',
    description: 'Di kursus ini, Uma akan memaparkan kesalahan dan hal apa yang harus dihindari! Disini akan dibahas mengenai kontrol biaya overhead hingga tips dalam merekrut!',
  },
  {
    id: 141,
    url: 'https://www.kuncie.com/module/meningkatkan-potensi-bisnis-dengan-menjamah-saluran-online/82',
    code: 82,
    type: 'module',
    title: 'Perbedaan Bisnis Online dan Offline Serta Potensinya',
    description: 'Kursus ini akan membahas perbedaan bisnis online dan offline serta bagaimana brand yang telah berdiri secara offline berusaha untuk menjamah dunia online.',
  },
  {
    id: 142,
    url: 'https://www.kuncie.com/module/menciptakan-brand-yang-ikonis-dan-membesarkannya-dengan-ekspansi/83',
    code: 83,
    type: 'module',
    title: 'Cara Menciptakan Brand Sendiri Yang Ikonis dan Ekspansi',
    description: 'Pada kursus ini dia akan membahas bagaimana cara menciptakan brand yang ikonis serta cara membesarkan brand nya dengan cara ekspansi.',
  },
  {
    id: 143,
    url: 'https://www.kuncie.com/module/perjalanan-investasi-startup-nicko-widjaja/84',
    code: 84,
    type: 'module',
    title: 'Tips dan Cara Investasai di Perusahaan Startup',
    description: 'Investasi untuk perusahaan startup merupakan hal yang sangat berisiko. Dalam kursus ini Nicko akan menjelaskan perjalanannya sebagai investor.',
  },
  {
    id: 144,
    url: 'https://www.kuncie.com/module/serba-serbi-dunia-seni-bersama-butet-kertaradjasa/85',
    code: 85,
    type: 'module',
    title: 'Serba Serbi Dunia Seni bersama Butet Kertaradjasa',
    description: 'Dunia seni merupakan dunia yang luaS. Dalam kursus ini, Butet akan membagikan kisahnya memulai perjalanan di dunia seni hingga hal-hal yang menginspirasinya.',
  },
  {
    id: 145,
    url: 'https://www.kuncie.com/module/tentang-kesuksesan-dan-masalah-genting-abad-ini-bersama-gita-wirjawan/86',
    code: 86,
    type: 'module',
    title: 'Cara Mencapai Kesuksesan ala Gita Wirjawan',
    description: 'Dalam kursus ini, Gita Wirjawan membahas makna dan cbagaimana ara mencapai kesuksesan serta masalah genting yang dihadapi oleh manusia abad ini.',
  },
  {
    id: 146,
    url: 'https://www.kuncie.com/module/persiapan-untuk-berjualan-online-di-bukalapak/87',
    code: 87,
    type: 'module',
    title: 'Cara Jualan Online di Bukalapak Dan Persiapannya',
    description: 'Meningkatnya pengguna internet, menyebabkan kebiasaan berbelanja berpindah ke Internet. Inilah cara jualan online di bukalapak agar dagangan kamu laris manis!',
  },
  {
    id: 147,
    url: 'https://www.kuncie.com/module/mindset-shift-untuk-memulai-bisnis/88',
    code: 88,
    type: 'module',
    title: 'Konsep Mindset Shift Untuk Memulai Bisnis',
    description: 'Di kursus ini, Fajar akan berbagi mengenai konsep mindset shift serta pengalamannya dalam mendirikan Adorable Project.',
  },
  {
    id: 148,
    url: 'https://www.kuncie.com/module/membangun-dan-memaintain-bisnis-era-sekarang/89',
    code: 89,
    type: 'module',
    title: 'Cara Meningkatkan Loyal Customer dan Me-maintainnya',
    description: 'Di kursus ini, Michelle akan berbagi mengenai cara membangun customer base yang loyal, promosi di media sosial dan cara membuat bisnis model Grab and Go,',
  },
  {
    id: 149,
    url: 'https://www.kuncie.com/module/mengembangkan-bisnis-dengan-sistem-franchise-hingga-ke-luar-negeri/90',
    code: 90,
    type: 'module',
    title: 'Mengenal Sistem dan Konsep Bisnis Franchise',
    description: 'Di kursus ini, Hendy akan menjelaskan cara membangun cabang dengan konsep franchise dan tips membangun bisnis bagi pemula dan pemasaran viralitas.',
  },
  {
    id: 150,
    url: 'https://www.kuncie.com/module/mengembangkan-bisnis-kreatif-dengan-mengandalkan-produk-lokal/91',
    code: 91,
    type: 'module',
    title: 'Mental Yang Harus Dimiliki Oleh Pebisnis Industri Kreatif',
    description: 'Pada kursus ini, Handoko akan menjelaskan mental yang harus dimiliki pebisnis di industri kreatif, cara terjun, branding, dan lokalitas dalam bisnis kreatif.',
  },
  {
    id: 151,
    url: 'https://www.kuncie.com/module/eksekusi-pengembangan-produkmu-dengan-aim-strategy/92',
    code: 92,
    type: 'module',
    title: 'Strategi Pengembangan Produk ala Mineral Botanica',
    description: 'Dalam kursus ini, Anita akan berbagi tentang strategi pengembangan produk serta beberapa tips memulai bisnis yang lain!',
  },
  {
    id: 152,
    url: 'https://www.kuncie.com/module/az-bisnis-kuliner-strategi-proses-huluhilir-mengelola-tim/93',
    code: 93,
    type: 'module',
    title: 'Bisnis Kuliner: Strategi, Proses Hulu-Hilir, Mengelola Tim',
    description: 'Di kursus ini, Founder Ayam Gepuk Pak Gembus akan membagikan hal mengenai bisnis kuliner. Mulai dari strategi, cara me-manage nya, sampai mengembangkannya.',
  },
  {
    id: 153,
    url: 'https://www.kuncie.com/module/berkembang-di-era-digital-bisnis-mindset/94',
    code: 94,
    type: 'module',
    title: 'Cara Menemukan Peluang Bisnis di Era Digital',
    description: 'Bagaimana menemukan peluang di era digitalisasi? Pieter Lydian akan mengupas tuntas topik-topik ini di Kuncie!',
  },
  {
    id: 154,
    url: 'https://www.kuncie.com/module/perkenalan-terhadap-social-media-marketing/95',
    code: 95,
    type: 'module',
    title: 'Apa itu Social Media Marketing Beserta Contohnya',
    description: 'Di era digital, sering kita mendengar social media marketing. Apa sih sebenarnya itu? Pentingkah untuk kita pelajari? Cari tahu disini!',
  },
  {
    id: 155,
    url: 'https://www.kuncie.com/module/memberikan-kaki-kepada-mimpimu-melalui-sebuah-gerakan-sosial/96',
    code: 96,
    type: 'module',
    title: 'Pengertian Gerakan Sosial  Dan Cara Menjalankannya',
    description: 'Bersama dengan Ainun Chomsun dari Akademi Berbagi, mari cari tahu perngertian dari gerakan sosial bagaimana kamu sendiri dapat melakukan hal yang sama.',
  },
  {
    id: 156,
    url: 'https://www.kuncie.com/module/menentukan-bisnis-fb-yang-tepat-ala-martabak-pizza-orins/97',
    code: 97,
    type: 'module',
    title: 'Menentukan Ide Bisnis Makanan Yang Tepat Ala Martabak Orins',
    description: 'Mari cari tahu ide bisnis makanan dan hal apa saja yang harus kita persiapkan terlebih dahulu untuk memaksimalkan kemungkinan sukses bisnis kita.',
  },
  {
    id: 157,
    url: 'https://www.kuncie.com/module/pertimbangan-finansial-oleh-para-pebisnis-baru/98',
    code: 98,
    type: 'module',
    title: 'Aspek Finansial dalam Studi Kelayakan Bisnis - Safir Senduk',
    description: 'Mari pelajari apa saja pertimbangan aspek finansial yang harus dipikirkan pada bisnis oleh seorang pebisnis baru, bersama Safir Senduk',
  },
  {
    id: 158,
    url: 'https://www.kuncie.com/module/menciptakan-ladang-kesempatan-dengan-bisnis-berbasis-hobi/99',
    code: 99,
    type: 'module',
    title: 'Mengembangkan Hobi Menjadi Bisnis Ala Jejouw',
    description: 'Mengembangkan hobi menjadi bisnis merupakan dambaan setiap orang. Di Kelas in, yuk kita lihat bagaimana Jejouw dapat mencapai itu semua.',
  },
  {
    id: 159,
    url: 'https://www.kuncie.com/module/perkembangan-bisnis-kreatif-dan-digital-untuk-umkm-indonesia/100',
    code: 100,
    type: 'module',
    title: 'Digitalisasi UMKM dan Perkembangan Bisnis Kreatif Indonesia',
    description: 'Mari kita pelajari bagaimana Leonard Theosabrata, direktur SMESCO Indonesia, berencana untuk membantu digitalisasi UMKM di Indonesia secara merata.',
  },
  {
    id: 160,
    url: 'https://www.kuncie.com/module/seluk-beluk-dunia-fotografi-dalam-negeri-dengan-darwis-triadi/101',
    code: 101,
    type: 'module',
    title: 'Pelajari Cara Menjadi Fotografer Bersama Darwis Triadi',
    description: 'Bersama Darwis Triadi, mari cari tahu cara untuk menjadi fotografer, terutama di Indonesia, untuk meniti karir di bidang fotografi.',
  },
  {
    id: 161,
    url: 'https://www.kuncie.com/module/membangun-bisnis-berkelanjutan-melalui-kolaborasi/102',
    code: 102,
    type: 'module',
    title: 'Membangun Bisnis Berklanjutan Melalui Kolaborasi',
    description: 'Bersama Alditsa Decca, founder dari SundaySundayCo, mari lihat bagaimana cara menjalin kerjasama kolaborasi yang bermanfaat untuk bisnis.',
  },
  {
    id: 162,
    url: 'https://www.kuncie.com/module/persiapan-keuangan-dan-mental-seorang-pebisnis-baru/103',
    code: 103,
    type: 'module',
    title: 'Cara Mengatur Keuangan Bisnis Untuk Pebisnis Baru',
    description: 'Bersama Melvin Mumpuni, founder Finansialku.com, cari tahu bagaimana cara pengusaha mengatur keuangan, mengasah skill komunikasi dan kepemimpinan.',
  },
  {
    id: 163,
    url: 'https://www.kuncie.com/module/perjalanan-bisnis-fb-dari-ideasi-hingga-mendirikan-toko-fisik-pertama/104',
    code: 104,
    type: 'module',
    title: 'Perjalanan Bisnis F&B Hingga Membuka Toko Fisik Sendiri',
    description: 'Bisnis F&B identik dengan sebuah lokasi fisik, baik itu kafe atau restoran. Bagaimana perjalanan bisnis F&B mulai dari ideasi produk hingga mendirikan outlet?',
  },
  {
    id: 164,
    url: 'https://www.kuncie.com/module/apa-itu-sebuah-startup/105',
    code: 105,
    type: 'module',
    title: 'Apa Itu Startup dan Perbedaannya dengan UMKM',
    description: 'Di modul ini, kita akan mempelajari apa itu startup serta memahami perbedaannya dengan UMKM dan menanamkan pola pikir problem solving untuk startup',
  },
  {
    id: 165,
    url: 'https://www.kuncie.com/module/apa-itu-design/106',
    code: 106,
    type: 'module',
    title: 'Apa Yang Dimaksud Dengan Desain Produk Beserta Contohnya',
    description: 'Di modul ini, kita akan belajar arti dari Product Design / Desain Produk serta cakupan kerja seorang Product Designer.',
  },
  {
    id: 166,
    url: 'https://www.kuncie.com/module/pengembangan-produk/107',
    code: 107,
    type: 'module',
    title: 'Apa Itu Minimum Viable Product (MVP) Beserta Contohnya',
    description: 'Di modul ini, kita akan belajar tentang Apa itu Minimum Viable Product (MVP) serta perbedaan prototype dengan MVP.',
  },
  {
    id: 167,
    url: 'https://www.kuncie.com/module/sudut-pandang-seorang-founder-startup-ala-christian-sugiono/108',
    code: 108,
    type: 'module',
    title: 'Cara Membangun Bisnis Startup Ala Christian Sugiono',
    description: 'Christian membangun startup berbekal pengalaman dalam perjalanan aktingnya. Cari tahu bagaimana Christian dalam membangun sebuah startup di course ini!',
  },
  {
    id: 168,
    url: 'https://www.kuncie.com/module/membangun-bisnis-jasa-terdiferensiasi-dengan-memanfaatkan-platform-digital/109',
    code: 109,
    type: 'module',
    title: 'Cara Memulai dan Kiat Meraih Kunci Sukses Bisnis Jasa',
    description: 'Yuk telusuri bagaimana cara memulai sebuah bisnis jasa dengan memanfaatkan platform digital dan kunci sukses dari Iman Taufiq',
  },
  {
    id: 169,
    url: 'https://www.kuncie.com/module/memulai-sebuah-bisnis-franchise-ala-rangga-umara/110',
    code: 110,
    type: 'module',
    title: 'Cara Memulai Bisnis Franchise Makanan Yang Sukses',
    description: 'Cari tahu apa itu franchise dan bagaimana untuk memulai bisnis franchise makanan yang baik dan benar bersama Rangga Umara, Owner dari Pecel Lele Lela.',
  },
  {
    id: 170,
    url: 'https://www.kuncie.com/module/perjalanan-karir-dari-korporat-hingga-startup-bersama-dayu-dara/111',
    code: 111,
    type: 'module',
    title: 'Cara Membangun Bisnis Startup Sendiri Hingga Pendanaan',
    description: 'Dayu telah berhasil membangun Pinhome. Di Kuncie, ia berbagi banyak hal terkait cara membangun bisnis startup hingga ke tahap mendapatkan pendanaan.',
  },
  {
    id: 171,
    url: 'https://www.kuncie.com/module/kekuatan-media-sosial-dalam-meningkatkan-performa-bisnis/112',
    code: 112,
    type: 'module',
    title: 'Memanfaatkan Kekuatan Sosial Media Untuk Bisnis',
    description: 'Banyak cara memanfaatkan sosial media untuk mengembangkan bisnis. Bersama dengan Michella Ham, mari cari tahu apa saja yang harus dilakukan!',
  },
  {
    id: 172,
    url: 'https://www.kuncie.com/module/fundamental-dalam-membangun-sebuah-bisnis-dari-nol/113',
    code: 113,
    type: 'module',
    title: 'Cara Memulai Bisnis Dari Nol Hingga Sukses',
    description: 'Membangun bisnis dari nol merupakan mimpi dan tujuan dari banyak orang. Bersama Danu Sofwan, cari tahu apa yang harus diperhatikan ketika memulai bisnis.',
  },
  {
    id: 173,
    url: 'https://www.kuncie.com/module/market-research-and-validation/114',
    code: 114,
    type: 'module',
    title: 'Apa itu Market Research dalam Startup Serta Langkahnya',
    description: 'Di modul ini, kita akan belajar tentang apa itu market research, apa saja langkah pertama untuk melakukannya serta pentingnya segmentasi dan validasi.',
  },
  {
    id: 174,
    url: 'https://www.kuncie.com/module/product-research/115',
    code: 115,
    type: 'module',
    title: 'Cara Riset Produk Menggunakan Metode yang Sering Digunakan',
    description: 'Disini, kita akan belajar cara riset produk menggunakan metode-metode yang sering digunakan product research. Serta melakukan usability dan A/B Testing',
  },
  {
    id: 175,
    url: 'https://www.kuncie.com/module/rapid-prototyping/116',
    code: 116,
    type: 'module',
    title: 'Apa Yang Dimaksud Dengan Rapir Prototyping Serta Tujuannya',
    description: 'Di modul ini, kita akan belajar tentang apa itu rapid prototyping dan tujuannya serta metode memvalidasi ide prototype dalam membangun produk.',
  },
  {
    id: 176,
    url: 'https://www.kuncie.com/module/langkah-awal-dalam-memulai-bisnis-fashion-ala-mutiara-kamila/117',
    code: 117,
    type: 'module',
    title: 'Cara Memulai Bisnis Fashion ala Mutiara Kamila',
    description: 'Mari kita pelajari bagaimana cara Mutiara membangun bisnis fashion di awal, hingga merealisasikan keberlanjutan untuk bisnisnya tersebut.',
  },
  {
    id: 177,
    url: 'https://www.kuncie.com/module/memulai-sebuah-bisnis-fashion-design-yang-modern/118',
    code: 118,
    type: 'module',
    title: 'Cara Memulai Bisnis Fashion Dengan Brand Sendiri',
    description: 'Bersama Christie Basil, mari cari tahu cara memulai bisnis fashion design yang modern, dengan brand sendiri di Kuncie!',
  },
  {
    id: 178,
    url: 'https://www.kuncie.com/module/dokumentasi-finansial-yang-diperlukan-oleh-seorang-pemilik-umkm/119',
    code: 119,
    type: 'module',
    title: 'Laporan Keuangan Untuk UMKM serta Cara Untuk Membuatnya',
    description: 'Laporan Keuangan sangat penting untuk mengukur performa bisnis umkm. Bersama dengan Safir Senduk, mari belajar cara membuat dokumentasi finansial tersebut.',
  },
  {
    id: 179,
    url: 'https://www.kuncie.com/module/cara-memulai-bisnis-dengan-persiapan-dan-branding-yang-matang/120',
    code: 120,
    type: 'module',
    title: 'Cara Memulai Bisnis dan Persiapan Branding Yang Matang',
    description: 'Bersama dengan Rizky, mari cari tahu cara memulai bisnis dan apa saja yang diperlukan untuk membangun brand dengan baik!',
  },
  {
    id: 180,
    url: 'https://www.kuncie.com/module/langkah-awal-mengembangkan-bisnis-berdasarkan-passion/121',
    code: 121,
    type: 'module',
    title: 'Cara Menemukan Bisnis Yang Sesuai Passion Ala Arrofi',
    description: 'Setiap orang pasti memiliki mimpi untuk membangun bisnis sesuai passion yang dimilikinya. Tak beda dengan Arrofi, yang memiliki passion di desain interior.',
  },
  {
    id: 181,
    url: 'https://www.kuncie.com/module/business-model-lean-canvas/122',
    code: 122,
    type: 'module',
    title: 'Apa itu Business Plan Dan Lean Canvas Serta Perbedaannya',
    description: 'Di modul ini, kita akan belajar tentang apa itu business plan dan lean canvas, serta perbedaannya. Serta memahami permasalahan startup dan analisa solusinya',
  },
  {
    id: 182,
    url: 'https://www.kuncie.com/module/product-design/123',
    code: 123,
    type: 'module',
    title: 'Design Thinking: Pengertian, Proses, dan Frameworknya',
    description: 'Di modul ini kita akan belajar tentang apa itu design tihingking, bagaimana tahapan, proses dan frameworknya serta apa saja metode yang digunakan.',
  },
  {
    id: 183,
    url: 'https://www.kuncie.com/module/team-and-project-management/124',
    code: 124,
    type: 'module',
    title: 'Project Management dan Istilah dalam Product Development',
    description: 'Di modul ini, kita akan belajar tentang apa itu project management dan pengenalan istilah dalam product development dan tools yang digunakan',
  },
  {
    id: 184,
    url: 'https://www.kuncie.com/module/teknik-dasar-dalam-menjadi-seorang-ilustrator/125',
    code: 125,
    type: 'module',
    title: 'Teknik Dasar dan Cara Untuk Menjadi Seorang Ilustrator',
    description: 'Bersama dengan Dinda Puspitasari, mari cari tahu teknik dasar dan cara untuk menemukan stylenya sendiri sebagai seorang ilustrator.',
  },
  {
    id: 185,
    url: 'https://www.kuncie.com/module/team-project-management/126',
    code: 126,
    type: 'module',
    title: 'Cara Membangun Tim Yang Efektif Untuk Startup',
    description: 'Di modul ini, kita akan belajar mengidentifikasi kebutuhan tim agar dapat menciptakan tim yang efektif untuk memenuhi kebutuhan startup tersebut.',
  },
  {
    id: 186,
    url: 'https://www.kuncie.com/module/mengembangkan-bisnis-kopi-dengan-ekspansi-organik/129',
    code: 129,
    type: 'module',
    title: 'Strategi Ekspansi Bisnis Dalam Mengembangkan Bisnis Kopi',
    description: 'Bisnis kopi adalah bisnis yang sangat digemari belakangan ini. Bagaimana bisnis tersebut bisa populer? Dan apa strategi ekspansi bisnis ala kopi tuku?',
  },
  {
    id: 187,
    url: 'https://www.kuncie.com/module/strategi-rebranding-bisnis-dengan-memanfaatkan-media-sosial/130',
    code: 130,
    type: 'module',
    title: 'Rebranding Bisnis 101: Pengertian, Cara, Dan Straateginya',
    description: 'Beberapa bisnis terkenal pernah melakukan proses rebranding. Namun apa itu rebranding bisnis? Bagaimana Strateginya? Simak di video ini hanya di Kuncie',
  },
  {
    id: 188,
    url: 'https://www.kuncie.com/module/proses-pembuatan-konten-digital-bagi-seorang-content-creator/131',
    code: 131,
    type: 'module',
    title: 'Cara Membuat Konten Tiktok Yang Menarik Serta Prosesnya',
    description: 'Content creator adalah profesi yang sedang populer saat ini. Namun, tahukah kamu proses yang harus dilalui? Cari tau cara membuat konten tiktok disini!',
  },
  {
    id: 189,
    url: 'https://www.kuncie.com/module/dasar-dan-operasional-dalam-membangun-bisnis-kue/132',
    code: 132,
    type: 'module',
    title: 'Kursus Cake Decorating dari Sonia Basil Secara Gratis',
    description: 'Cake Decorating selalu dipandang sebagai hal yang sangat sulit. Namun bersama Sonia Basil, mari belajar untuk dekorasi kue-kue yang unik dan enak!',
  },
  {
    id: 190,
    url: 'https://www.kuncie.com/module/dasar-fotografi-produk-dengan-darwis-triadi/133',
    code: 133,
    type: 'module',
    title: 'Teknis Dasar Dan Materi Fotografi Produk by Darwis Triadi',
    description: 'Bersama dengan Darwis Triadi, mari cari tahu materi teknik dasar dari fotografi produk, menggunakan kamera atau hp dan dengan model atau tidak!',
  },
  {
    id: 191,
    url: 'https://www.kuncie.com/module/mengembangkan-bisnis-kue-yang-kekinian/134',
    code: 134,
    type: 'module',
    title: 'Tips Sukses Mengembangkan Bisnis Kue Kekinian',
    description: 'Setelah sukses dengan Bittersweet By Najla, Sang Owner memberikan tips dan trik agar bisnis kue kekninian dapat berkembang dan menguntungkan.',
  },
  {
    id: 192,
    url: 'https://www.kuncie.com/module/mengasah-skill-voice-over-dan-membangun-network/135',
    code: 135,
    type: 'module',
    title: 'Belajar Cara Mengasah Skill Voice Over bersama Bimo Kusumo',
    description: 'Yuk asah kemampuan kamu sebagai pengisi suara dengan belajar voice over dengan Bimo Kusumo! Kamu juga akan belajar membangun networking, lho!',
  },
  {
    id: 193,
    url: 'https://www.kuncie.com/module/teknik-penulisan-novel-dengan-dee-lestari/136',
    code: 136,
    type: 'module',
    title: 'Tips Serta Cara Menulis Novel Yang Baik dan Benar',
    description: 'Cari tahu cara menulis novel yang baik dan benar langsung dari penulis novel kondang, Dee Lestari. Mulai dari Teknik, langkah langkah, hingga penerbitan buku.',
  },
  {
    id: 194,
    url: 'https://www.kuncie.com/module/penyampaian-informasi-dan-desain-visual-dalam-slidemaking/137',
    code: 137,
    type: 'module',
    title: 'Belajar Membuat Power Point untuk Presentasi Profesional',
    description: 'Slide Powerpoint adalah alat presentasi yang sering kita temui di dunia. Bagaimana cara menciptakan bahan presentasi di power point yang efektif? simak disini!',
  },
  {
    id: 195,
    url: 'https://www.kuncie.com/module/menjalankan-bisnis-kuliner-rumahan-dengan-kualitas-tinggi/138',
    code: 138,
    type: 'module',
    title: 'Cara Menjalankan Bisnis Kuliner Rumahan Yang Menjanjikan',
    description: 'Tips untuk kamu yang sedang menjalankan bisnis kuliner rumahan dengan kualistas tinggi. Dapatkan ide dan strategi usaha makanan dari Chef Andry Susanto',
  },
  {
    id: 196,
    url: 'https://www.kuncie.com/module/membangun-bisnis-ikan-cupang/141',
    code: 141,
    type: 'module',
    title: 'Cara Budidaya dan Membuka Bisnis Ikan Cupang Hias',
    description: 'Bagi Angelina, ikan cupang lebih dari sekedar hobi. Yuk belajar cara budidaya ikan cupang dan membuka peluang bisnis dari hobi unik yang satu ini.',
  },
  {
    id: 197,
    url: 'https://www.kuncie.com/module/dasar-fotografi-untuk-memulai-perjalananmu-sendiri/143',
    code: 143,
    type: 'module',
    title: 'Pelajari Teknik Dasar Dalam Fotografi dari Martha Suherman',
    description: 'Di modul kali ini kalian bisa belajar dasar - dasar fotografi dari fotografer profesional, Martha Suherman. Dan kelas ini gratis hanya di Kuncie!',
  },
  {
    id: 198,
    url: 'https://www.kuncie.com/module/kiat-kiat-menjadi-seorang-komikus/144',
    code: 144,
    type: 'module',
    title: 'Cara Menjadi Komikus Dan Langkah Awalnya',
    description: 'Di kelas ini kamu akan belajar cara menjadi seorang komikus serta langkahnya untuk pemula. Modul ini disampaikan langsung oleh komikus terkenal di Indonesia.',
  },
  {
    id: 199,
    url: 'https://www.kuncie.com/module/mendalami-digital-marketing-dan-brand-online-development/145',
    code: 145,
    type: 'module',
    title: 'Kursus Digital Marketing dan Brand Development Online',
    description: 'Kursus digital marketing ini bersifat gratis. Kamu akan belajar banyak tentang strategi hingga penerapan digital marketing pada pelatihan kali ini.',
  },
  {
    id: 200,
    url: 'https://www.kuncie.com/module/mengenal-industri-kopi-biji-kopi-sampai-ke-pengolahannya/147',
    code: 147,
    type: 'module',
    title: 'Pengertian dan Pemahaman Tentang Industri Kopi di Indonesia',
    description: 'Industri kopi adalah industri paling menarik saat ini. Apa saja tantangan yang dihadapi industri kopi indonesia saat ini? Cari tau di Kuncie!',
  },
  {
    id: 201,
    url: 'https://www.kuncie.com/module/mempersiapkan-dan-meningkatkan-performa-lapakmu-di-bukalapak/150',
    code: 150,
    type: 'module',
    title: 'Cara Meningkatkan Performa Penjualan di Bukalapak',
    description: 'Setelah bisa berjualan online, saatnya kamu untuk meningkatkan performa lapak. Yuk cari tahu cara meningkatkan penjualan di Bukalapak dan mempertahankannya!',
  },
  {
    id: 202,
    url: 'https://www.kuncie.com/module/jurus-ampuh-seo-dan-digital-marketing-untuk-lapakmu/151',
    code: 151,
    type: 'module',
    title: 'Intip Jurus Ampuh Cara SEO di Bukalapak Yuk!',
    description: 'Di modul kali ini, kalian akan mempelajari cara untuk meningkatkan penjualan di bukalapak menggunakan SEO, digital marketing, dan juga copywriting!',
  },
  {
    id: 203,
    url: 'https://www.kuncie.com/module/pentingnya-digitalisasi-bisnis-di-era-modern-dengan-gobiz/152',
    code: 152,
    type: 'module',
    title: 'Pentingnya Digitalisasi Bisnis di Era Revolusi 4.0',
    description: 'Digitalisasi bisnis tentu saja menjadi hal yang sangat wajib di era 4.0 ini. Bagaimana cara kita untuk mendigitalisasi usaha kita? Simak disini!',
  },
  {
    id: 204,
    url: 'https://www.kuncie.com/module/kebiasaan-bisnis-baru-yang-harus-kita-terapkan-di-era-kebiasaan-baru/153',
    code: 153,
    type: 'module',
    title: 'Strategi di Era New Normal Untuk Bisnis Baru',
    description: 'Dengan berjalannya era new normal, bisnis baru wajib menyesuaikan diri dan merangkai strategi secepat mungkin. Bagaimana caranya? Simak disini!',
  },
  {
    id: 205,
    url: 'https://www.kuncie.com/module/memulai-bisnis-rumahan-sendiri-dari-nol/154',
    code: 154,
    type: 'module',
    title: 'Cara Memulai Bisnis Rumahan Sendiri Dari Nol',
    description: 'Mari cari tahu apa yang sebenarnya diperlukan untuk memulai bisnis sendiri dari rumah dari nol. Tingkatkan omset dan rebut hati pelanggan!',
  },
  {
    id: 206,
    url: 'https://www.kuncie.com/module/belajar-cara-berbicara-di-depan-umum-dengan-percaya-diri-bersama-teuku-daffa/155',
    code: 155,
    type: 'module',
    title: 'Belajar Public Speaking Agar Lancar Berbicara di Depan Umum',
    description: 'Yuk simak cara berbicara di depan umum dengan percaya diri di kelas belajar public speaking oleh Teuku Daffa. Selamat tinggal grogi!',
  },
  {
    id: 207,
    url: 'https://www.kuncie.com/module/fundamental-dari-branding-bersama-pijar-mahir/156',
    code: 156,
    type: 'module',
    title: 'Apa itu Branding Serta Konsep Dasar dan Fundamentalnya',
    description: 'Branding adalah sebuah aktifitas yang harus dilalui semua pelaku bisnis. Yuk cari tahu apa yang diperlukan untuk memperkuat branding bisnismu sendiri!',
  },
  {
    id: 208,
    url: 'https://www.kuncie.com/module/mastering-teknik-teknik-membuat-kopi-bersama-viki-rahardja/157',
    code: 157,
    type: 'module',
    title: 'Teknik Membuat Kopi Ala Barista Viki Rahardja',
    description: 'Simak video tutorial teknik membuat kopi ala barista dari Viki yang memiliki wawasan mendalam di dunia kopi, Ia adalah pemilik Kopi Bu dokter.',
  },
  {
    id: 209,
    url: 'https://www.kuncie.com/module/menjalankan-bisnis-bersama-brand-ambassador-sebagai-wajah-perusahaan/159',
    code: 159,
    type: 'module',
    title: 'Tentang Apa Itu Brand Ambassador Beserta Fungsinya',
    description: 'Kiat sukses dalam memilih brand ambassador yang tepat untuk meningkatkan brand awareness perusahaan atau bisnis. Studi kasus dari brand skincare Everwhite.',
  },
  {
    id: 210,
    url: 'https://www.kuncie.com/module/dasardasar-dari-fotografi-produk-bersama-martha-suherman/161',
    code: 161,
    type: 'module',
    title: 'Dasar Teknik Fotografi Produk Bersama Martha Suherman',
    description: 'Presentasikan produkmu dengan elegan dan menarik. Pelajari dasar teknik fotografi produk secara gratis di Kuncie. Download Sekarang!',
  },
  {
    id: 211,
    url: 'https://www.kuncie.com/module/mengawali-langkah-pertamamu-di-bidang-food-content-creation/162',
    code: 162,
    type: 'module',
    title: 'Cara Menjadi Food Content Creator dari Jiewa Vieri',
    description: 'Kelas ini cocok untuk kamu yang ingin menjadi food content creator karena materinya disampaikan langsung oleh pemilik IniJie Academy, Jiewa Vieri.',
  },
  {
    id: 212,
    url: 'https://www.kuncie.com/module/fundamental-dari-search-engine-optimization/163',
    code: 163,
    type: 'module',
    title: 'Belajar SEO Gratis Langsung Dari Praktisi Digital Agency',
    description: 'Kuncie dengan senang hati memberikan kelas untuk belajar SEO (search engine optimization) secara Gratis disini. Materi disampaikan langsung oleh praktisi SEO di digital marketing agency.',
  },
  {
    id: 213,
    url: 'https://www.kuncie.com/module/mengenal-instagram-untuk-memaksimalkan-instagram/164',
    code: 164,
    type: 'module',
    title: 'Belajar Instagram Marketing Dan Cara Memaksimalkannya',
    description: 'Kursus untuk belajar instagram marketing Strategy ini diisi oleh influencer marketing hub instagram, Niko Julius. Yuk tonton videonya hanya di Kuncie!',
  },
  {
    id: 214,
    url: 'https://www.kuncie.com/module/memanfaatkan-sistem-pos-untuk-memaksimalkan-manajemen-restoranmu/166',
    code: 166,
    type: 'module',
    title: 'Memaksimalkan Sistem POS Restoran Untuk Manajemen Resto',
    description: 'Yuk belajar cara memaksimalkan sistem pos untuk kamu yang menggeluti bisnis restoran agar manajemen restomu efisien dan efektif',
  },
  {
    id: 215,
    url: 'https://www.kuncie.com/module/memulai-bisnis-kado-unik-yang-melekat-di-hati-pelanggan/167',
    code: 167,
    type: 'module',
    title: 'Melihat Peluang Bisnis Kado Unik Serta Persiapannya',
    description: 'Untuk kamu yang sedang mencari inspirasi dan ide serta peluang bisnis unik, Dellaneira Adzra punya jawabannya pada modul ini.',
  },
];

const metaTitle = 'Cari Tahu Kiat Sukses Berwirausaha Dari Ahlinya di Kuncie';
const metaDesc = 'Di Kuncie, kamu akan menemukan kiat sukses berwirausaha langsung dari para pengusaha dan entrepreneur sukses yang ada di Indonesia. Yuk mulai bisnismu!';

const SEOConfig = {
  setMeta(isReplace: boolean, title?: string, description?: string, id?: number, type?: string, image?: string) {
    let isProduction = false;
    if (process.env.VUE_APP_VERSION) {
      isProduction = process.env.VUE_APP_VERSION === 'production';
    }
    const metaData = {
      title: metaTitle,
      description: metaDesc,
      og: {
        title: metaTitle,
        description: metaDesc,
        site_name: 'Kuncie',
        type: 'website',
        url: window.location.href,
        image: image ? image : require('@/assets/logo.png'),
      },
      twitter: {
        title: metaTitle,
        description: metaDesc,
      },
    };
    if (title && !isReplace) {
      this.changeTitle(metaData, `${title} | ${metaTitle}`);
    }

    if (!isProduction && isReplace) {
      this.changeMetaData(metaData, title);
      this.changeMetaData(metaData, '', description);
    }

    if (isProduction && isReplace) {
      if (id && type && title && description) {
        this.changeMetaProduction(metaData, id, type, title, description);
      } else if (title && description) {
        this.changeMetaData(metaData, title);
        this.changeMetaData(metaData, '', description);
      } else {
        // do nothing
      }
    }

    return metaData;
  },
  changeTitle(metaData: any, title: string) {
    const truncateTitleReplace = _.truncate(title, {
      length: 60, separator: ' ',
    });
    metaData.title = truncateTitleReplace;
    metaData.og.title = truncateTitleReplace;
    metaData.twitter.title = truncateTitleReplace;
  },
  changeDescription(metaData: any, description: string) {
    const truncateDescReplace = _.truncate(description, {
      length: 160, separator: ' ',
    });
    metaData.description = truncateDescReplace;
    metaData.og.description = truncateDescReplace;
    metaData.twitter.description = truncateDescReplace;
  },
  changeMetaData(metaData: any, title?: string, description?: string) {
    if (title) {
      this.changeTitle(metaData, title);
    }

    if (description) {
      this.changeDescription(metaData, description);
    }
  },
  changeMetaProduction(metaData: any, id: number, type: string, title: string, desc: string) {
    const findData = arraySEO.find(seo => (seo.code === id && seo.type === type));
    if (findData && findData.title) {
      this.changeTitle(metaData, findData.title);
    } else if (title) {
      this.changeTitle(metaData, title);
    } else {
      // do nothing
    }

    if (findData && findData.description) {
      this.changeDescription(metaData, findData.description);
    } else if (desc) {
      this.changeDescription(metaData, desc);
    } else {
      // do nothing
    }
  },
};

export default SEOConfig;
