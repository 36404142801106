import { IToken, IOTPStatus } from '@/interfaces/premium/AuthInterface';
import { setToken } from '@/utils/helpers/token';
import type { LoadState, State } from './state';

export const SET_AUTH_ID = 'SET_AUTH_ID';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_IS_LOGIN = 'SET_IS_LOGIN';
export const RESET_AUTH = 'RESET_AUTH';
export const SET_STATUS = 'SET_STATUS';
export const SET_STATUS_OTP = 'SET_STATUS_OTP';

export default {
  [SET_AUTH_ID](state: State, payload: string) {
    state.authId = payload;
  },
  [SET_PHONE_NUMBER](state: State, payload: string) {
    if (payload.charAt(0) === '+') {
      state.phoneNumber = payload;
    } else if (payload.charAt(0) === '0') {
      payload = `+62${payload.substring(1)}`;
    } else {
      payload = `+62${payload}`;
    }
    state.phoneNumber = payload;
  },
  [SET_TOKEN](state: State, payload: IToken) {
    state.accessToken = payload.token;
    state.refreshToken = payload.refreshToken;
    setToken(payload)
  },
  [RESET_AUTH](state: State) {
    state.authId = '';
    state.phoneNumber = '';
    state.accessToken = '';
    state.refreshToken = '';
  },
  [SET_STATUS](state: State, payload: LoadState) {
    state.status = payload;
  },
  [SET_STATUS_OTP](state: State, payload: IOTPStatus) {
    state.statusOTP = payload;
  },
};
