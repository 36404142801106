import { IOTPStatus } from '@/interfaces/premium/AuthInterface';
import type { State } from './state';
import type { State as userState } from '../user/state';

interface rootState {
  user: userState;
}

export default {
  getAuthId(state: State) {
    return state.authId;
  },
  getPhoneNumber(state: State) {
    return state.phoneNumber;
  },
  isAuthenticated(state: State, _: any, rootState: rootState) {
    return !!(
      state.accessToken &&
      rootState.user.userProfile &&
      rootState.user.userMeta &&
      rootState.user.registeredUser
    );
  },
  getStatus(state: State) {
    return state.status;
  },
  isLoading(state: State) {
    return state.status === 'loading';
  },
  isSuccess(state: State) {
    return state.status === 'success';
  },
  isError(state: State) {
    return state.status === 'error';
  },
  statusOTP(state: State) {
    return state.statusOTP;
  },
  isValidOTP(state: State) {
    return state.statusOTP === IOTPStatus.VALID;
  },
  isInvalidOTP(state: State) {
    return state.statusOTP === IOTPStatus.INVALID;
  },
  isExpiredOTP(state: State) {
    return state.statusOTP === IOTPStatus.EXPIRED;
  },
};
