import axiosInstance from '@/api/axios';
import { defaultLoginType } from '@/api/modules/auth';
import { ISetUserInfo } from '@/interfaces/premium/UserInterface';
import { getAccessToken } from '@/utils/helpers/token';

const timezone = 'Asia/Jakarta';
const platform = 'kuncie-web';

const userApi = {
  createUserMeta: (loginType = defaultLoginType) => {
    const accessToken = getAccessToken();
    const data = {
      timezone,
      device_token: accessToken,
      login_type: loginType,
      platform,
    };
    return axiosInstance.post(`${process.env.VUE_APP_APIV2_HOST}/user-meta`, data);
  },
  setUserInfo: (userInfo: ISetUserInfo) => {
    const data = {
      ...userInfo,
    };
    return axiosInstance.patch(`${process.env.VUE_APP_APIV2_HOST}/user-meta/set-user-info`, data);
  },
};

export default userApi;
