export enum IOTPStatus {
  IDLE = 'IDLE',
  VALID = 'VALID_OTP',
  INVALID = 'INVALID_OTP',
  EXPIRED = 'OTP_EXPIRED',
}

export enum IStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

export type Status = 'idle' | 'loading' | 'error' | 'success';
