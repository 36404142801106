import type {
  GetProductCatalogBySlugData,
  PaginationResponseV2,
  SearchProductCatalogItem,
} from '@kunciehub/api-v2-web-js-client';
import type { State, Status } from './state';

export const SET_PRODUCT_CATALOG = 'SET_PRODUCT_CATALOG';
export const SET_PRODUCT_DETAIL = 'SET_PRODUCT_DETAIL';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_STATUS = 'SET_STATUS';

export default {
  [SET_PRODUCT_CATALOG](state: State, payload: Array<SearchProductCatalogItem>) {
    state.productCatalog = payload;
  },
  [SET_PRODUCT_DETAIL](state: State, payload: GetProductCatalogBySlugData) {
    state.productDetail = payload;
  },
  [SET_PAGINATION](state: State, payload: PaginationResponseV2) {
    state.pagination = payload;
  },
  [SET_STATUS](state: State, payload: Status) {
    state.status = payload;
  },
};
