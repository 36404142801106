import { captureException } from '@sentry/vue';
import { getBoolean, getString, ensureInitialized, fetchAndActivate } from 'firebase/remote-config';
import firebase from '@/firebase';

const minimumFetchIntervalMillis = 1800000;
const fetchTimeoutMillis = 10000;
const RcFullMaintenance = 'FULL_MAINTENANCE';

export const init = async () => {
  if (firebase.remoteConfig) {
    try {
      firebase.remoteConfig.settings = {
        minimumFetchIntervalMillis,
        fetchTimeoutMillis,
      };

      await ensureInitialized(firebase.remoteConfig);
      await fetchAndActivate(firebase.remoteConfig);
    } catch (error) {
      captureException(error);
    }
  } else {
    captureException('indexedDB not supported for this browser');
  }
};

const firebaseRemoteConfig = {
  getMaintenanceScreen(): boolean {
    return getBoolean(firebase.remoteConfig, RcFullMaintenance) ?? false;
  },
  getProductSubInformation(type: 'course' | 'program'): object {
    const key = {
      course: 'COURSE_SUB_INFORMATION',
      program: 'PROGRAM_SUB_INFORMATION',
    };

    return JSON.parse(getString(firebase.remoteConfig, key[type]) ?? '{}');
  },
  getProductFaq(): object {
    return JSON.parse(getString(firebase.remoteConfig, 'PRODUCT_FAQ') ?? '{}');
  },
  getPaymentChannel(): [] {
    return JSON.parse(getString(firebase.remoteConfig, 'PAYMENT_CHANNEL') ?? '[]');
  },
  getHighlightedClass(): [] {
    return JSON.parse(getString(firebase.remoteConfig, 'HIGHLIGHTED_CLASS') ?? '[]');
  },
};

export default firebaseRemoteConfig;
