import { MyCoursesComponent, MyCoursesProgress } from '@kunciehub/api-v2-web-js-client';
import { getTimeFromDate } from '@/utils/helpers/date-helper';
import type { State } from './state';

export default {
  getLatestCourseList(state: State) {
    return state.latestCoursesList;
  },
  getMyCourseListData(state: State) {
    return state.myCoursesList;
  },
  getMyCourseListPagination(state: State) {
    return state.pagination;
  },
  getMyCourseDetail(state: State) {
    return state.courseDetail;
  },
  getMyCourseDetailId(state: State) {
    return state.courseDetail?.id;
  },
  getMyCourseProgress(state: State) {
    const currentDateTime = getTimeFromDate();
    state.courseProgress?.forEach(progress => {
      if (progress.start_date && currentDateTime < getTimeFromDate(progress.start_date)) {
        progress.component.forEach(component => {
          component.is_locked = true;
        });
      }
    });
    return state.courseProgress;
  },
  getMyCourseInfo(state: State) {
    return state.courseDetail.class_info;
  },
  getMyCourseMentor(state: State) {
    let mentors: string[] = [];
    if (state.courseDetail.partner_mentors) {
      mentors = state.courseDetail.partner_mentors;
    } else {
      state.courseDetail.mentors.forEach(val => {
        mentors.push(`${val.full_name} - ${val.job_title}`);
      });
    }
    return mentors || [];
  },
  getMyCourseVideo(state: State) {
    return state.courseVideo;
  },
  getMyCourseQuizInfo(state: State) {
    return state.courseQuizInfo?.data;
  },
  getMyCourseLiveSessionInfo(state: State) {
    return state.courseLiveSessionInfo?.data;
  },
  getMyCourseCaseStudyInfo(state: State) {
    return state.courseCaseStudyInfo?.data;
  },
  getUncompletedComponent(state: State) {
    if (state.courseProgress) {
      for (let i = 0; i < state.courseProgress.length; i += 1) {
        const component = state.courseProgress[i].component.filter(
          (comp: MyCoursesComponent) => !comp.is_completed && !comp.is_locked,
        )[0];
        if (component) {
          return component;
        }
      }
      // fallback to first component if all component already complete
      return state.courseProgress[0].component[0];
    }

    return null;
  },
  isGetCourseDetailError(state: State) {
    return state.loadCourseInfoState === 'error' || state.loadCourseProgressState === 'error';
  },
  isLoadingCourseInfo(state: State) {
    return state.loadCourseInfoState === 'loading';
  },
  isLoadingCourseProgress(state: State) {
    return state.loadCourseProgressState === 'loading';
  },
  isLoadingLatestCourses(state: State) {
    return state.loadLatestCoursesState === 'loading';
  },
  isLoadingAllCourses(state: State) {
    return state.loadAllCoursesState === 'loading';
  },
  isLoadingAllCoursesDone(state: State) {
    return state.loadAllCoursesState === 'success';
  },
  isLoadingCourseVideo(state: State) {
    return state.loadCourseVideoState === 'loading';
  },
  hasCourses(state: State) {
    return (state.myCoursesList || []).length > 0;
  },
  totalLesson(state: State) {
    return state.courseProgress?.reduce(
      (sum: number, progress: MyCoursesProgress) => sum + progress.total_component,
      0,
    );
  },
  totalCompletedLesson(state: State) {
    return state.courseProgress?.reduce(
      (sum: number, progress: MyCoursesProgress) => sum + progress.total_finish_component,
      0,
    );
  },
  isLoadingFinishCourse(state: State) {
    return state.finishCourseStatus === 'loading';
  },
};
