import { RouteRecordRaw } from 'vue-router';
import {
  UNIFIED_HOME,
  UNIFIED_PRODUCT_CATALOG,
  UNIFIED_CATEGORY0_PAGE,
  UNIFIED_CATEGORY1_PAGE,
  UNIFIED_PRODUCT_DETAIL,
  UNIFIED_MENTORS,
  UNIFIED_MENTORS_V2,
  UNIFIED_MENTOR_PROFILE,
  UNIFIED_LOGIN,
  UNIFIED_LOGOUT,
  UNIFIED_CART,
  UNIFIED_CHECKOUT,
  UNIFIED_DIRECT_BUY,
  UNIFIED_THANK_YOU,
  NOT_FOUND,
  UNIFIED_CREATE_PAYMENT,
  UNIFIED_FAQ,
  UNIFIED_FAQ_DETAIL,
  UNIFIED_FAQ_SEARCH_RESULT,
  UNIFIED_DETAIL_CERTIFICATE,
  UNIFIED_AUTH_CALLBACK,
  UNIFIED_QUIZ_CALLBACK,
} from './type';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: {
      template: '<div></div>', // An empty component or template
    },
  },
  {
    path: '/v2/home',
    name: UNIFIED_HOME,
    component: () => import('@/views/premium/Home.vue'),
  },
  {
    path: '/v2/product',
    name: UNIFIED_PRODUCT_CATALOG,
    component: () => import('@/views/premium/ProductList.vue'),
  },
  {
    path: '/v2/category/:slugc0',
    name: UNIFIED_CATEGORY0_PAGE,
    component: () => import('@/views/premium/ProductList.vue'),
    meta: {
      disabled: true,
    },
  },
  {
    path: '/v2/category/:slugc0/:slugc1',
    name: UNIFIED_CATEGORY1_PAGE,
    component: () => import('@/views/premium/ProductList.vue'),
    meta: {
      disabled: true,
    },
  },
  {
    path: '/v2/product/:slug',
    name: UNIFIED_PRODUCT_DETAIL,
    component: () => import('@/views/premium/ProductDetail.vue'),
  },
  {
    path: '/mentors',
    name: UNIFIED_MENTORS,
    component: () => import('@/views/premium/MentorList.vue'),
  },
  {
    path: '/v2/mentors',
    name: UNIFIED_MENTORS_V2,
    component: () => import('@/views/premium/MentorList.vue'),
  },
  {
    path: '/v2/mentors/:slug',
    name: UNIFIED_MENTOR_PROFILE,
    component: () => import('@/views/premium/MentorProfile.vue'),
  },
  {
    path: '/v2/login',
    name: UNIFIED_LOGIN,
    component: () => import('@/views/premium/authentication/Login.vue'),
  },
  {
    path: '/v2/logout',
    name: UNIFIED_LOGOUT,
    component: () => import('@/views/premium/authentication/Logout.vue'),
  },
  {
    path: '/v2/auth-callback',
    name: UNIFIED_AUTH_CALLBACK,
    component: () => import('@/views/premium/authentication/AuthCallback.vue'),
  },
  {
    path: '/v2/cart',
    name: UNIFIED_CART,
    component: () => import('@/views/premium/Cart.vue'),
  },
  {
    path: '/v2/checkout',
    name: UNIFIED_CHECKOUT,
    component: () => import('@/views/premium/Checkout.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/v2/beli-langsung',
    name: UNIFIED_DIRECT_BUY,
    component: () => import('@/views/premium/DirectBuy.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/thank-you',
    name: UNIFIED_THANK_YOU,
    component: () => import('@/views/premium/ThankYou.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/v2/payment/:id',
    name: UNIFIED_CREATE_PAYMENT,
    component: () => import('@/views/common/CreatePayment.vue'),
    meta: {
      active: true,
      requiresAuth: true,
    },
  },
  {
    path: '/v2/certificate/:id',
    name: UNIFIED_DETAIL_CERTIFICATE,
    component: () => import('@/modules/dashboard/certificate/CertificateDetail.vue'),
  },
  {
    path: '/v2/quiz-callback',
    name: UNIFIED_QUIZ_CALLBACK,
    component: () => import('@/modules/dashboard/learning/QuizCallback.vue'),
  },
  {
    path: '/faq',
    name: UNIFIED_FAQ,
    component: () => import('@/views/premium/faq/Faq.vue'),
  },
  {
    path: '/faq/search',
    name: UNIFIED_FAQ_SEARCH_RESULT,
    component: () => import('@/views/premium/faq/SearchResult.vue'),
  },
  {
    path: '/faq/:id',
    name: UNIFIED_FAQ_DETAIL,
    component: () => import('@/views/premium/faq/FaqDetail.vue'),
  },
  {
    path: '/not-found',
    name: NOT_FOUND,
    component: () => import('@/views/common/NotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: NOT_FOUND },
  },
];

const unifiedRoutes = routes.filter(val => !val.meta?.disabled);

export default unifiedRoutes;
