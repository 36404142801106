/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import authApi from '@/api/modules/auth';
import { EmailPayload, IOTPStatus, IToken } from '@/interfaces/premium/AuthInterface';
import { setupAPI } from '@/plugins/api';
import { decodeBase64, encodeBase64 } from '@/utils/helpers/base64';
import { CART_ID_KEY, CART_TOKEN_KEY } from '@/utils/helpers/cart';
import { showErrorMessage } from '@/utils/helpers/constant-api';
import errorToString from '@/utils/helpers/error-to-string';
import { getSessionId, getUserEmail, getUserPhone, setUserEmail } from '@/utils/helpers/identity';
import { getTokenId, removeToken } from '@/utils/helpers/token';
import { ForgotPasswordSendEmailRequest, ForgotPasswordSubmitRequest, ForgotPasswordVerifyRequest } from '@kunciehub/api-v2-web-js-client';
import { useToast } from 'vue-toastification';
import { Commit } from 'vuex';
import { RESET_AUTH, SET_AUTH_ID, SET_PHONE_NUMBER, SET_STATUS, SET_STATUS_OTP, SET_TOKEN } from './mutations';

const api = setupAPI();
const toast = useToast();

export default {
  changeToken({ commit }: { commit: Commit }, payload: IToken): void {
    commit(SET_TOKEN, payload);
  },
  async login({ commit, getters }: { commit: Commit; getters: any }, payload: string) {
    commit(SET_STATUS, 'loading');
    try {
      commit(SET_PHONE_NUMBER, payload);
      const res = await authApi.login(getters.getPhoneNumber);
      commit(SET_AUTH_ID, encodeBase64(res.data.authId));
      commit(SET_STATUS, 'success');
      return res;
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(showErrorMessage(error.response.status));
      throw error;
    }
  },
  async loginWhatsapp({ commit, getters }: { commit: Commit; getters: any }) {
    commit(SET_STATUS, 'loading');
    try {
      const res = await authApi.login(getters.getPhoneNumber, 'whatsapp');
      commit(SET_AUTH_ID, encodeBase64(res.data.authId));
      commit(SET_STATUS, 'success');
      return res;
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(showErrorMessage(error.response.status));
      throw error;
    }
  },
  async logout({ commit }: { commit: Commit; getters: any; rootState: any }) {
    commit(SET_STATUS, 'loading');
    try {
      const tokenId = getTokenId();
      await api.auths.authLogout({
        client_type: 'web',
        device_id: getSessionId(),
        email: getUserEmail(),
        phone_number: getUserPhone(),
        token_id: tokenId,
      })
    } catch (error: any) {
      // toast.error(showErrorMessage(error.response.status));
    } finally {
      removeToken();
      localStorage.removeItem(CART_ID_KEY);
      localStorage.removeItem(CART_TOKEN_KEY);
      localStorage.removeItem('email');
      localStorage.removeItem('phone');
      commit(RESET_AUTH);
      commit('user/RESET_USER_META', null, { root: true });
      commit(SET_STATUS, 'success');
      localStorage.removeItem('mooc_registration_code');
    }
  },
  async validateOTP({ commit, getters }: { commit: Commit; getters: any }, payload: string) {
    commit(SET_STATUS, 'loading');
    commit(SET_STATUS_OTP, IOTPStatus.IDLE);
    try {
      const res = await authApi.validateOTP(payload, decodeBase64(getters.getAuthId), getters.getPhoneNumber);
      const { access_token, refresh_token, tokenId } = res.data;
      if (access_token) {
        commit(SET_TOKEN, {
          token: access_token,
          refreshToken: refresh_token,
          tokenId,
        });
        commit(SET_STATUS_OTP, IOTPStatus.VALID);
      }
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      const { authId, code } = error.response.data;
      if (authId) {
        commit(SET_AUTH_ID, encodeBase64(authId));
        if (code === IOTPStatus.INVALID) {
          commit(SET_STATUS_OTP, IOTPStatus.INVALID);
        }
      } else {
        commit(SET_STATUS_OTP, IOTPStatus.IDLE);
        if (code === IOTPStatus.EXPIRED) {
          commit(SET_STATUS_OTP, IOTPStatus.EXPIRED);
        }
      }
      throw error;
    }
  },
  async resendOTP({ commit, getters }: { commit: Commit; getters: any }) {
    commit(SET_STATUS, 'loading');
    try {
      const res = await authApi.resendOTP(getters.getPhoneNumber, decodeBase64(getters.getAuthId));
      commit(SET_AUTH_ID, encodeBase64(res.data.authId));
      commit(SET_STATUS, 'success');
      return res;
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(showErrorMessage(error.response.status));
      throw error;
    }
  },
  async validateAuth0({ commit }: { commit: Commit; getters: any }, token: string) {
    commit(SET_STATUS, 'loading');
    try {
      const { data: response } = await api.authorizations.authVerifyThirdParty({
        device_token: token,
        login_type: '',
        platform: 'web',
        provider: 'auth0',
        timezone: 'Asia/Jakarta',
        device_id: getSessionId(),
      });

      commit(SET_TOKEN, {
        token: response.data?.tokens?.access_token,
        refreshToken: response.data?.tokens?.refresh_token,
        tokenId: '',
      });
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(showErrorMessage(error.response.status));
      throw error;
    } finally {
      window.location.href = `${process.env.VUE_APP_LEARN_URL}/login`;
    }
  },
  async authenticateEmail({ commit }: { commit: Commit; getters: any }, payload: EmailPayload) {
    commit(SET_STATUS, 'loading');
    const { email, password } = payload
    try {
      const { data: response } = await api.auths.authAuthenticateEmail({
        device_id: getSessionId(),
        client_type: 'web',
        email,
        password,
      });

      commit(SET_TOKEN, {
        token: response.data?.access_token,
        refreshToken: response.data?.refresh_token,
        tokenId: '',
      });
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      throw error;
    }
  },
  async forgotPasswordSendEmail({ commit }: { commit: Commit; getters: any }, payload: ForgotPasswordSendEmailRequest) {
    commit(SET_STATUS, 'loading');
    const { email } = payload
    try {
      setUserEmail('')
      await api.auths.authForgotPasswordSendEmail({ email });
      setUserEmail(email)
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(errorToString(error));
      throw error;
    }
  },
  async forgotPasswordVerify({ commit }: { commit: Commit; getters: any }, payload: ForgotPasswordVerifyRequest) {
    commit(SET_STATUS, 'loading');
    const { email, verification_code } = payload
    try {
      await api.auths.authForgotPasswordVerify({ email, verification_code });
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(errorToString(error));
      throw error;
    }
  },
  async forgotPasswordSubmit({ commit }: { commit: Commit; getters: any }, payload: ForgotPasswordSubmitRequest) {
    commit(SET_STATUS, 'loading');
    const { email, verification_code, new_password, confirm_new_password } = payload
    try {
      await api.auths.authForgotPasswordSubmit({ email, verification_code, new_password, confirm_new_password });
      commit(SET_STATUS, 'success');
    } catch (error: any) {
      commit(SET_STATUS, 'error');
      toast.error(errorToString(error));
      throw error;
    }
  },
};
