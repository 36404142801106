import type { State } from './state';

export default {
  getCourseList(state: State) {
    return state.courseList;
  },
  getLoadState(state: State) {
    return state.loadState;
  },
};
