// unified route
export const UNIFIED_HOME = 'unified-home';
export const UNIFIED_PRODUCT_CATALOG = 'unified-product-catalog';
export const UNIFIED_CATEGORY0_PAGE = 'unified-category0-page';
export const UNIFIED_CATEGORY1_PAGE = 'unified-category1-page';
export const UNIFIED_PRODUCT_DETAIL = 'unified-product-detail';
export const UNIFIED_MENTORS = 'unified-mentors';
export const UNIFIED_MENTORS_V2 = 'unified-mentors-v2';
export const UNIFIED_MENTOR_PROFILE = 'unified-mentor-profile';
export const UNIFIED_AUTH_CALLBACK = 'unified-auth-callback';
export const UNIFIED_LOGIN = 'unified-login';
export const UNIFIED_LOGIN_MOOC = 'unified-login-mooc';
export const UNIFIED_LOGOUT = 'unified-logout';
export const UNIFIED_CART = 'unified-cart';
export const UNIFIED_CHECKOUT = 'unified-checkout';
export const UNIFIED_DIRECT_BUY = 'unified-direct-buy';
export const UNIFIED_THANK_YOU = 'unified-thank-you';
export const UNIFIED_CREATE_PAYMENT = 'unified-create-payment';
export const UNIFIED_DETAIL_CERTIFICATE = 'unified-detail-certificate';
export const UNIFIED_QUIZ_CALLBACK = 'unified-quiz-callback';
export const UNIFIED_FAQ = 'unified-faq';
export const UNIFIED_FAQ_SEARCH_RESULT = 'unified-faq-search-result';
export const UNIFIED_FAQ_DETAIL = 'unified-faq-detail';
export const NOT_FOUND = 'not-found';

// dashboard route
export const DASHBOARD = 'dashboard';
export const DASHBOARD_LIST_COURSE = 'dashboard-list-course';
export const DASHBOARD_COURSE_DETAIL = 'dashboard-course-detail';
export const DASHBOARD_COURSE_FINISH = 'dashboard-course-finish';
export const DASHBOARD_CERTIFICATE_LOADING = 'dashboard-certificate-loading';
export const DASHBOARD_LIST_PROGRAM = 'dashboard-list-program';
export const DASHBOARD_PROGRAM_DETAIL = 'dashboard-program-detail';
export const DASHBOARD_LIST_CERTIFICATE = 'dashboard-list-certificate';
export const DASHBOARD_DETAIL_CERTIFICATE = 'dashboard-detail-certificate';
export const DASHBOARD_TRANSACTION_HISTORY = 'dashboard-transaction-history';
export const DASHBOARD_TRANSACTION_HISTORY_DETAIL = 'dashboard-transaction-history-detail';
export const DASHBOARD_QUIZ = 'dashboard-quiz';
export const DASHBOARD_ZOOM_CLIENT = 'dashboard-zoom-client';
export const DASHBOARD_ZOOM_CLIENT_AFTER_SESSION = 'dashboard-zoom-client-after-session';
export const DASHBOARD_SUBSCRIPTION_DETAIL = 'dashboard-subscription-detail';
export const DASHBOARD_MY_ACCOUNT = 'dashboard-my-account';
