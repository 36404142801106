import { SearchMentorsRequest } from '@kunciehub/api-v2-web-js-client';
import { Commit } from 'vuex';
import { setupAPI } from '@/plugins/api';
import delay from '@/utils/helpers/delay';
import MYSKILL_SLUG from '@/constants/fixtures/mentors/slug';
import { SET_MENTOR, SET_MENTOR_LIST, SET_PAGINATION, SET_STATUS, SET_MENTOR_PRODUCTS } from './mutations';
import { State } from './state';

const api = setupAPI();
const delayTimeout = 1000;

export default {
  async getProductByMentor({ commit }: { commit: Commit }, mentorId: string) {
    const LATEST_PRODUCT_SORT = 'latest_product';
    const PRODUCT_LIMIT_PER_PAGE = 12;
    const PRODUCT_OFFSET = 0;

    commit(SET_STATUS, 'loading');
    try {
      const results = await api.productCatalog.searchProductCatalog({
        pagination: {
          limit: PRODUCT_LIMIT_PER_PAGE,
          offset: PRODUCT_OFFSET,
        },
        filter: { category_ids: [], prices: [], mentor_ids: [mentorId] },
        sort: LATEST_PRODUCT_SORT,
      });
      const { data } = results.data;

      commit(SET_MENTOR_PRODUCTS, data.items);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_MENTOR_PRODUCTS, []);
      commit(SET_STATUS, 'error');
    }
  },
  async getMentorBySlug({ commit, state, dispatch }: { commit: Commit; state: State; dispatch: any }, slug: string) {
    commit(SET_STATUS, 'loading');
    try {
      if (slug === MYSKILL_SLUG) {
        const results = await api.mentors.getMentorBySlug(slug);
        const { data } = results.data;
        commit(SET_MENTOR, data);
        commit(SET_STATUS, 'success');
      } else {
        throw Error
      }
    } catch (error) {
      commit(SET_MENTOR, {});
      commit(SET_STATUS, 'error');
    }

    await dispatch('getProductByMentor', state.mentor?.id);
  },
  async searchMentors({ commit }: { commit: Commit }, payload: SearchMentorsRequest) {
    commit(SET_STATUS, 'loading');
    try {
      const results = await api.mentors.searchMentors(payload);
      const mentors = results.data.data?.items;
      const pagination = results.data.data?.pagination;
      commit(SET_MENTOR_LIST, mentors);
      commit(SET_PAGINATION, pagination);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_MENTOR_LIST, {});
      commit(SET_STATUS, 'error');
    }
  },
  async searchMoreMentors({ commit, state }: { commit: Commit; state: State }, payload: SearchMentorsRequest) {
    commit(SET_STATUS, 'loading');
    try {
      const results = await api.mentors.searchMentors(payload);
      const mentors = state.mentorList?.concat(results.data.data?.items || []);
      const pagination = results.data.data?.pagination;
      /**
       * add 1 second delay to avoid
       * flicker when the response is too fast
       */
      await delay(delayTimeout);
      commit(SET_MENTOR_LIST, mentors);
      commit(SET_PAGINATION, pagination);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_MENTOR_LIST, {});
      commit(SET_STATUS, 'error');
    }
  },
};
