import axios from 'axios';
import { createApp } from 'vue';
import VueFeather from 'vue-feather';
import VueLoading from 'vue-loading-overlay';
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta';
import Toast, { POSITION, PluginOptions } from 'vue-toastification';
import VueLazyLoad from 'vue3-lazyload';
import { createAuth0 } from '@auth0/auth0-vue';
import errorHandle from '@/errorHandle';
import antd from 'ant-design-vue'
import App from './App.vue';
import initSentry from './initSentry';
import api from './plugins/api';
import clickOutside from './plugins/click-outside';
import v2 from './plugins/v2';
import webAssetsURL from './plugins/web-assets-url';
import './registerServiceWorker';
import router from './router';
import store from './store';

import '@/assets/common/styles/global.scss';
import '@/assets/common/styles/tailwind.css';
import '@kunciehub/web-ui/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue3-circle-progress/dist/circle-progress.css';
import 'vue-toastification/dist/index.css';

// js
import 'bootstrap';

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});

axios.interceptors.response.use(
  response => response,
  error => {
    const errorResponse = {
      response: error.response,
      status: error.response.status,
      message: error.response.data.message,
    };
    error = errorHandle(errorResponse);

    return Promise.reject(error);
  },
);

const app = createApp(App);

initSentry(app, router);

app.use(
  createAuth0({
    domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
    clientId: `${process.env.VUE_APP_AUTH0_CLIENT_ID}`,
    authorizationParams: {
      redirect_uri: `${process.env.VUE_APP_LEARN_URL}/auth-callback`,
      scope: 'openid profile email',
    },
  }),
);

const options: PluginOptions = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 1,
  newestOnTop: true,
  position: POSITION.BOTTOM_CENTER,
  timeout: 10000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  icon: false,
  rtl: false,
  toastClassName: 'custom-toast',
};

app.use(store);
app.use(router);
app.use(VueLazyLoad, {
  loading: require('@/assets/belajar/img/load-image.gif'),
  error: require('@/assets/belajar/img/no-image.svg'),
});
app.use(VueLoading, {
  color: '#2A88AA',
  loader: 'spinner',
  width: 30,
  height: 30,
  backgroundColor: '#ffffff',
  opacity: 0.5,
  zIndex: 999,
});
app.use(metaManager);
app.use(metaPlugin);
app.use(Toast, options);
app.component(`${VueFeather.name}`, VueFeather);
app.use(api);
app.use(v2);
app.use(webAssetsURL);
app.use(clickOutside);
app.use(antd)
app.mount('#app');
