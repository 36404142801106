import type { State } from './state';

export default {
  getMentor(state: State) {
    return state.mentor;
  },
  getMentorList(state: State) {
    return state.mentorList;
  },
  getPagination(state: State) {
    return state.pagination;
  },
  getStatus(state: State) {
    return state.status;
  },
  getMentorProducts(state: State) {
    return state.mentorProducts;
  },
  isMentorListAvailable(state: State) {
    return state.mentorList ? state.mentorList.length > 0 : false;
  },
  isLoading(state: State) {
    return state.status === 'loading';
  },
  isError(state: State) {
    return state.status === 'error';
  },
};
