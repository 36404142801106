<template>
  <div class="tw-flex tw-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center">
    <img
      src="@/assets/common/images/maintenance.svg"
      alt="maintenance"
      class="tw-h-56 tw-w-56"
    >
    <div class="tw-mt-12 tw-text-center tw-text-xl tw-font-bold">
      Sedang Dalam Perbaikan
    </div>
    <div class="tw-mt-2 tw-w-[368px] tw-text-center tw-text-sm">
      Situs yang anda cari saat ini sedang dalam perbaikan dan akan segera kembali
    </div>
  </div>
</template>
