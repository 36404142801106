import axiosInstance from '@/api/axios';
import qs from 'qs';

const clientType = 'web';
export const defaultLoginType = 'phone';

const authApi = {
  login: (phoneNumber: string, loginType = defaultLoginType) => {
    const data = qs.stringify({
      type: loginType,
      clientType,
      phoneNumber,
    });
    const config = {
      headers: {
        'AM-PHONENUMBER': phoneNumber,
        'AM-SEND': 'otp',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return axiosInstance.post(`${process.env.VUE_APP_AUTH_DOMAIN}/v2/authenticate`, data, config);
  },
  validateOTP: (otp: string, authId: string, phoneNumber: string) => {
    const data = qs.stringify({
      authId,
      otp,
      clientType,
      phoneNumber,
    });
    return axiosInstance.post(`${process.env.VUE_APP_AUTH_DOMAIN}/v2/submit-otp`, data);
  },
  resendOTP: (phoneNumber: string, authId: string) => {
    const data = qs.stringify({
      phoneNumber,
      authId,
      clientType,
    });
    return axiosInstance.post(`${process.env.VUE_APP_AUTH_DOMAIN}/v2/resend-otp`, data);
  },
  logout: (tokenId: string) => {
    const data = qs.stringify({
      cookie: tokenId,
      phoneNumber: '',
      deviceId: '',
    });
    return axiosInstance.post(`${process.env.VUE_APP_AUTH_DOMAIN}/logout`, data);
  },
};

export default authApi;
