import { GetAllMyCertificatesData } from '@kunciehub/api-v2-web-js-client';
import type { LoadState, State } from './state';

export const SET_CERTIFICATE_LIST = 'SET_CERTIFICATE_LIST';
export const SET_LOAD_STATE = 'SET_LOAD_STATE';

export default {
  [SET_CERTIFICATE_LIST](state: State, payload: GetAllMyCertificatesData) {
    state.certificateList = payload.items;
  },
  [SET_LOAD_STATE](state: State, payload: LoadState) {
    state.loadState = payload;
  },
};
