/*
  Plugin to handle close dropdown menu
*/
import { App } from 'vue';

export default function directive(app: App) {
  app.directive('click-outside', {
    beforeMount(el, binding) {
      el.clickOutsideEvent = (evt: any) => {
        evt.stopPropagation();
        if (!(el === evt.target || el.contains(evt.target))) {
          binding.value(evt, el);
        }
      };
      window.requestAnimationFrame(() => {
        document.addEventListener('click', el.clickOutsideEvent);
      });
    },
    unmounted(el) {
      document.removeEventListener('click', el.clickOutsideEvent);
    },
  });
}
