export const CART_ID_KEY = 'cartId';
export const CART_TOKEN_KEY = 'cartToken';
export const PRODUCT_ID_KEY = 'productId';

export type CartCredentialPayload = { id: string; token: string };

export const getCartId = (): string => localStorage.getItem(CART_ID_KEY) ?? '';

export const setCartId = (id: string): void => {
  localStorage.removeItem(CART_ID_KEY);
  localStorage.setItem(CART_ID_KEY, id);
};

export const getCartToken = (): string => localStorage.getItem(CART_TOKEN_KEY) ?? '';

export const setCartToken = (token: string): void => {
  localStorage.removeItem(CART_TOKEN_KEY);
  localStorage.setItem(CART_TOKEN_KEY, token);
};

export const getProductId = (): string[] => {
  const data = localStorage.getItem(PRODUCT_ID_KEY);
  if (data) {
    return JSON.parse(data);
  }
  return [];
};

export const setProductId = (id: string) => {
  const data = getProductId();
  // check if id doesn't exist on the data
  if (data.indexOf(id) === -1) {
    data.push(id);
    localStorage.setItem(PRODUCT_ID_KEY, JSON.stringify(data));
  }
};

export const updateProductId = (toRemove: string) => {
  const data = getProductId();
  const updatedData = data.filter(item => item !== toRemove);
  localStorage.setItem(PRODUCT_ID_KEY, JSON.stringify(updatedData));
};

export const deleteProductId = () => {
  localStorage.removeItem(PRODUCT_ID_KEY);
};
