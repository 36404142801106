export type Currency = 'USD' | 'IDR' | 'EUR' | 'GBP' | 'KRW' | 'JPY' | 'CNY';

export const ISOCurrency = {
  USD: 'en-US',
  IDR: 'id-ID',
  EUR: 'de-DE',
  GBP: 'en-GB',
  KRW: 'ko-KR',
  JPY: 'ja-JP',
  CNY: 'zh-CN',
};

export default (
  format: Currency = 'IDR',
  value = 0,
  fraction = 0,
  style: 'currency' | 'decimal' = 'currency',
): string =>
  new Intl.NumberFormat(ISOCurrency[format], {
    style,
    currency: format,
    minimumFractionDigits: fraction,
  }).format(value);
