
import SEOConfig from '@/utils/belajar/seo-config';
import firebaseRemoteConfig, { init } from '@/utils/helpers/firebase-remote-config';
import MaintenancePage from '@/views/common/MaintenancePage.vue';
import { captureException } from '@sentry/vue';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    MaintenancePage,
  },
  metaInfo() {
    return SEOConfig.setMeta(false);
  },
  data() {
    return {
      isMaintenance: false,
    };
  },
  async beforeMount() {
    await init();
    this.checkIfMaintenance();
    const isOnboarding = window.localStorage.getItem('onboarding')
    if (!isOnboarding) {
      window.localStorage.setItem('onboarding', 'show');
    }
  },
  methods: {
    async checkIfMaintenance() {
      try {
        this.isMaintenance = firebaseRemoteConfig.getMaintenanceScreen();
      } catch (error) {
        captureException(error);
      }
    },
  },
});
