import { decodeBase64, encodeBase64 } from '@/utils/helpers/base64';
import { IToken } from '@/interfaces/premium/AuthInterface';

export const getAccessToken = () => decodeBase64(localStorage.getItem(encodeBase64('token')) ?? '');
export const getRefreshToken = () => decodeBase64(localStorage.getItem(encodeBase64('refreshToken')) ?? '')
export const getTokenId = () => decodeBase64(localStorage.getItem(encodeBase64('tokenId')) ?? '')

export const setToken = (token: IToken) => {
  Object.keys(token).forEach(key =>
    localStorage.setItem(encodeBase64(String(key)), encodeBase64(token[key]))
  )
}

export const removeToken = () => {
  localStorage.removeItem(encodeBase64('token'));
  localStorage.removeItem(encodeBase64('tokenId'));
  localStorage.removeItem(encodeBase64('refreshToken'));
}
