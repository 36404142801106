import * as Sentry from '@sentry/vue';

let count = 0;
let message = '';

function isDevelopment() {
  return process.env.NODE_ENV !== 'production';
}

export default function errorHandle(error) {
  const statusCode = error.status;
  switch (statusCode) {
    case 0:
      message = 'Connection Timeout';
      break;
    case 400:
      message = `Bad Request : ${error.message}`;
      break;
    case 401:
      message = 'Unauthorized Access';
      if (count < 1 && localStorage.getItem(btoa('token'))) {
        if (isDevelopment()) {
          console.log(count, 'refresh-token');
        }
        count += 1;
        break;
      }
      break;
    case 403:
      message = 'Unauthorized';
      break;
    case 404:
      message = 'URL Not Found';
      break;
    case 500:
      message = 'Internal Server Error';
      break;
    case 502:
      message = 'API Bad Gateway';
      break;
    case 503:
      message = 'API Service Not Available';
      break;
    case 504:
      message = 'Gateway Timeout';
      break;
    default:
      message = error.message;
      break;
  }

  const errorData = {
    response: error.response,
    status: error.status,
    statusCode: error.status,
    message,
    errorMessages: error.message || '',
  };

  if (isDevelopment()) {
    console.log(errorData);
  }

  Sentry.captureEvent({
    message,
    level: Sentry.Severity.Error,
    contexts: errorData,
  });
  //
  return errorData;
}
