import { setupAPI } from '@/plugins/api';
import delay from '@/utils/helpers/delay';
import { SearchProductCatalogRequest } from '@kunciehub/api-v2-web-js-client';
import { Commit } from 'vuex';
import { SET_PAGINATION, SET_PRODUCT_CATALOG, SET_PRODUCT_DETAIL, SET_STATUS } from './mutations';
import { State } from './state';

const api = setupAPI();

export default {
  async searchProductCatalog({ commit }: { commit: Commit }, payload: SearchProductCatalogRequest) {
    commit(SET_STATUS, 'loading');
    try {
      const result = await api.productCatalog.searchProductCatalog(payload);
      const products = result.data.data?.items;
      const pagination = result.data.data?.pagination;
      commit(SET_PRODUCT_CATALOG, products);
      commit(SET_PAGINATION, pagination);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_PRODUCT_CATALOG, {});
      commit(SET_STATUS, 'error');
    }
  },
  async searchMoreProductCatalog(
    { commit, state }: { commit: Commit; state: State },
    payload: SearchProductCatalogRequest,
  ) {
    commit(SET_STATUS, 'loading');
    try {
      const result = await api.productCatalog.searchProductCatalog(payload);
      const products = state.productCatalog?.concat(result.data.data?.items || []);
      const pagination = result.data.data?.pagination;
      await delay(1000);
      commit(SET_PRODUCT_CATALOG, products);
      commit(SET_PAGINATION, pagination);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_PRODUCT_CATALOG, {});
      commit(SET_STATUS, 'error');
    }
  },
  async getProductCatalogBySlug({ commit }: { commit: Commit }, slug: string) {
    commit(SET_STATUS, 'loading');
    try {
      const results = await api.productCatalog.getProductCatalogBySlug(slug);
      const { data } = results.data;
      commit(SET_PRODUCT_DETAIL, data);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_PRODUCT_DETAIL, null);
      commit(SET_STATUS, 'error');
    }
  },
};
