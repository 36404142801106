import type {
  MyCourse,
  MyCoursesInfo,
  MyCoursesProgress,
  VideoPlayback,
  PaginationResponse,
  GetQuizByContentIdResponse,
  GetWebinarByIdResponse,
  GetDocumentByIdResponse,
  V2MyCourse,
} from '@kunciehub/api-v2-web-js-client';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  latestCoursesList: MyCourse;
  myCoursesList: V2MyCourse[] | undefined;
  courseDetail: MyCoursesInfo;
  courseProgress: MyCoursesProgress[] | undefined;
  courseVideo: VideoPlayback | undefined;
  courseQuizInfo: GetQuizByContentIdResponse | undefined;
  courseLiveSessionInfo: GetWebinarByIdResponse | undefined;
  courseCaseStudyInfo: GetDocumentByIdResponse | undefined;
  pagination: PaginationResponse;
  loadLatestCoursesState: LoadState;
  loadAllCoursesState: LoadState;
  loadCourseInfoState: LoadState;
  loadCourseProgressState: LoadState;
  loadCourseVideoState: LoadState;
  loadCourseQuizState: LoadState;
  loadCourseLiveSessionState: LoadState;
  loadCourseCaseStudyState: LoadState;
  loadingUpdateProgress: LoadState;
  finishCourseStatus: LoadState;
}

export default {
  latestCoursesList: {},
  myCoursesList: {},
  courseDetail: {},
  courseProgress: undefined,
  courseVideo: undefined,
  courseQuizInfo: undefined,
  courseLiveSessionInfo: undefined,
  courseCaseStudyInfo: undefined,
  pagination: {
    limit: 0,
    offset: 0,
    total: 0,
  },
  loadLatestCoursesState: 'idle',
  loadAllCoursesState: 'idle',
  loadCourseInfoState: 'idle',
  loadCourseProgressState: 'idle',
  loadCourseVideoState: 'idle',
  loadCourseCaseStudyState: 'idle',
  loadingUpdateProgress: 'idle',
  finishCourseStatus: 'idle',
} as State;
