import { setupAPI } from '@/plugins/api';
import { Commit } from 'vuex';
import { SET_COURSE_LIST, SET_LOAD_STATE } from './mutations';

const api = setupAPI();

export default {
  // TODO: this action is not being used, refactor this if the course list on the mentor detail page has been developed
  async searchCourses({ commit }: { commit: Commit }) {
    commit(SET_LOAD_STATE, 'loading');
    try {
      const results = await api.courses.searchCourses({});
      const { data } = results.data;
      commit(SET_COURSE_LIST, data?.items);
      commit(SET_LOAD_STATE, 'success');
    } catch (error) {
      commit(SET_COURSE_LIST, {});
      commit(SET_LOAD_STATE, 'error');
    }
  },
};
