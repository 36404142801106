import { get } from 'lodash';
import { Commit } from 'vuex';
import { setupAPI } from '@/plugins/api';
import { SET_DATA_CATEGORY, SET_DATA_LEVEL, SET_STATUS } from './mutations';

const api = setupAPI();

export default {
  async getCategories({ commit }: { commit: Commit }, payload: object) {
    commit(SET_STATUS, 'loading');
    try {
      // TODO: Move this to plugin
      const options = {
        headers: {
          ApiKeyAuth: 'apikeyauth',
          'x-mock-response-code': 200,
          'x-mock-match-request-body': true,
        },
      };

      const results = await api.categories.searchCategories(payload, options);
      const { data } = results.data;
      const isCategory = ['', 'category'].includes(get(payload, 'type', ''));
      const isLevel = get(payload, 'type', '') === 'level';

      if (isCategory) {
        commit(SET_DATA_CATEGORY, data);
        commit(SET_STATUS, 'success');
      } else if (isLevel) {
        commit(SET_DATA_LEVEL, data);
        commit(SET_STATUS, 'success');
      }
    } catch (error) {
      commit(SET_DATA_CATEGORY, {});
      commit(SET_DATA_LEVEL, {});
      commit(SET_STATUS, 'error');
    }
  },
  async fetchCategories({ commit }: { commit: Commit }) {
    commit(SET_STATUS, 'loading');
    try {
      const results = await api.categories.getAllCategories();
      const { data } = results.data;

      commit(SET_DATA_CATEGORY, data);
      commit(SET_STATUS, 'success');
    } catch (error) {
      commit(SET_DATA_CATEGORY, {});
      commit(SET_STATUS, 'error');
    }
  },
};
