export interface IAuthService {
  phoneNumber: string;
  loginType: string;
  otp: string;
  authId: string;
  tokenId: string;
  refreshToken: string;
  accessToken: string;
}

export enum IOTPStatus {
  IDLE = 'IDLE',
  VALID = 'VALID_OTP',
  INVALID = 'INVALID_OTP',
  EXPIRED = 'OTP_EXPIRED',
}

export interface IToken {
  [key: string]: string
}

export interface EmailPayload {
  email: string;
  password: string;
}
