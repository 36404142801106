import { CartItem, GetCartByIdData, CalculateCartOrderData } from '@kunciehub/api-v2-web-js-client';

export type LoadState = 'idle' | 'loading' | 'error' | 'success';
export interface State {
  cartId: string;
  cartToken: string;
  cartInfo: GetCartByIdData;
  cartItems: CartItem[];
  loadState: LoadState;
  orderData: CalculateCartOrderData;
}

const CartData = {
  summary: {
    total_coupon_discount: {
      currency: 'IDR',
      value: 0,
    },
    total_items: 0,
    total_net_price: {
      currency: 'IDR',
      value: 0,
    },
    total_product_price: {
      currency: 'IDR',
      value: 0,
    },
    total_strikethrough_price: {
      currency: 'IDR',
      value: 0,
    },
  },
  items: [],
};

export default {
  cartId: '',
  cartToken: '',
  cartInfo: CartData,
  cartItems: [],
  loadState: 'idle',
  orderData: CartData,
} as State;
