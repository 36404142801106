import type { State } from './state';

export default {
  getUserMeta(state: State) {
    return state.userMeta;
  },
  getUserProfile(state: State) {
    return state.userProfile;
  },
  getStatus(state: State) {
    return state.status;
  },
  isLoading(state: State) {
    return state.status === 'loading';
  },
  isSuccess(state: State) {
    return state.status === 'success';
  },
  isError(state: State) {
    return state.status === 'error';
  },
  isRegisteredUser(state: State) {
    return state.registeredUser;
  },
};
