import type { ICategory, ICategories } from '@/interfaces/premium/CategoryInterface';
import type { State } from './state';

export default {
  getCategories(state: State) {
    return state.dataCategories;
  },
  getNavbarCategories(state: State) {
    const categoriesArray = state.dataCategories;

    const mappedCategories = categoriesArray.map(data => {
      const childArray = data.children;
      const mappedChild = childArray?.map(val => ({
        id: val.id,
        name: val.name,
        slug: val.slug,
      }));

      return {
        id: data.id,
        name: data.name,
        slug: data.slug,
        child: mappedChild,
      };
    });

    return mappedCategories;
  },
  getFilteredCategoryBySlug: (state: State, getters: any) => (slug: string) => {
    const categoriesArray: ICategories[] = getters.getListAllCategories;
    const filteredCategory = categoriesArray.find(category => category.slug === slug);

    return filteredCategory;
  },
  getCategoriesIdBySlug: (state: State, getters: any) => (slug: string) => {
    const filteredCategory = getters.getFilteredCategoryBySlug(slug);
    return filteredCategory?.id;
  },
  getCategoriesNameBySlug: (state: State, getters: any) => (slug: string) => {
    const filteredCategory = getters.getFilteredCategoryBySlug(slug);
    return filteredCategory?.name;
  },
  getLevels(state: State) {
    return state.dataLevel;
  },
  getStatus(state: State) {
    return state.status;
  },
  getListAllCategories(state: State) {
    const categories = state.dataCategories;

    return categories.reduce<ICategory[]>((a, c) => {
      const mapped = {
        id: c.id,
        name: c.name,
        slug: c.slug,
      };

      const child = c.children?.reduce<ICategory[]>((x, y) => {
        const mappedChild = {
          id: y.id,
          name: y.name,
          slug: y.slug,
        };
        x.push(mappedChild);
        return x;
      }, []);

      a.push(mapped, ...(child ? (child as ICategory[]) : []));
      return a;
    }, []);
  },
};
