const divideHours = 3600;
const divideMinuteSecond = 60;
const maxPoint = 10;

export const changeZero = (time: number | string): string => {
  if (Number(time) < maxPoint) {
    return `0${time}`;
  }
  return time.toString();
};

export type FormatDuration = 'text' | 'date' | 'short' | 'basic';

export default (duration: number, { format = 'short' as FormatDuration, leadingZero = false } = {}): string => {
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let modHours = 0;
  if (duration > divideHours) {
    hours = Math.floor(duration / divideHours);
    modHours = duration % divideHours;
  } else {
    modHours = duration;
  }

  minutes = Math.floor(modHours / divideMinuteSecond);
  seconds = modHours % divideMinuteSecond;

  const leadingZeroHours = leadingZero ? changeZero(hours) : hours;
  const leadingZeroMinutes = leadingZero ? changeZero(minutes) : minutes;

  if (format === 'date') {
    return `${hours > 0 ? `${leadingZeroHours}:` : ''}${leadingZeroMinutes}:${changeZero(seconds)}`;
  }
  if (format === 'text') {
    const hour = hours > 0 ? `${leadingZeroHours} jam` : '';
    const minute = minutes > 0 ? ` ${leadingZeroMinutes} menit` : '';

    return hour + minute;
  }
  if (hours > 0) {
    return format === 'basic'
      ? `${leadingZeroHours}:${changeZero(minutes)}`
      : `${leadingZeroHours}:${changeZero(minutes)} jam`;
  }
  return format === 'basic'
    ? `${leadingZeroMinutes}:${changeZero(seconds)}`
    : `${leadingZeroMinutes}:${changeZero(seconds)} min`;
};
