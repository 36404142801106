import axiosInstance from '@/api/axios';
import {
  AuthApi,
  AuthsApi,
  CartCouponsApi,
  CartItemsApi,
  CartsApi,
  CategoriesApi,
  CertificateApi,
  Configuration,
  ConfigurationParameters,
  CouponsApi,
  CoursesApi,
  DocumentsApi,
  LessonsApi,
  MentorsApi,
  MyCertificatesApi,
  MyCoursesApi,
  MySkillApi,
  MySubscriptionsApi,
  OnboardApi,
  OrdersApi,
  PaymentChannelsApi,
  PaymentsApi,
  ProductCatalogApi,
  ProductsApi,
  QuizzesApi,
  RegistrationCodeApi,
  SubscriptionsApi,
  TopicApi,
  UserprofilesApi,
  VideoplaybackApi,
  VoucherCodeApi,
  WebinarApi,
} from '@kunciehub/api-v2-web-js-client';
import { App } from 'vue';

const basePath = process.env.VUE_APP_APIV2_HOST;

const configParams = (): ConfigurationParameters => ({
  baseOptions: {
    headers: {
      'Content-Type': 'application/json',
      'X-Login-Type': 'web',
    },
  },
});

const apiConfig = new Configuration(configParams());

export const setupAPI = () => ({
  auths: new AuthApi(apiConfig, basePath, axiosInstance),
  authorizations: new AuthsApi(apiConfig, basePath, axiosInstance),
  cartCoupons: new CartCouponsApi(apiConfig, basePath, axiosInstance),
  cartItems: new CartItemsApi(apiConfig, basePath, axiosInstance),
  carts: new CartsApi(apiConfig, basePath, axiosInstance),
  categories: new CategoriesApi(apiConfig, basePath, axiosInstance),
  certificates: new CertificateApi(apiConfig, basePath, axiosInstance),
  coupons: new CouponsApi(apiConfig, basePath, axiosInstance),
  courses: new CoursesApi(apiConfig, basePath, axiosInstance),
  documents: new DocumentsApi(apiConfig, basePath, axiosInstance),
  lessons: new LessonsApi(apiConfig, basePath, axiosInstance),
  mentors: new MentorsApi(apiConfig, basePath, axiosInstance),
  myCertificates: new MyCertificatesApi(apiConfig, basePath, axiosInstance),
  myCourses: new MyCoursesApi(apiConfig, basePath, axiosInstance),
  mySkill: new MySkillApi(apiConfig, basePath, axiosInstance),
  mySubscriptions: new MySubscriptionsApi(apiConfig, basePath, axiosInstance),
  onboards: new OnboardApi(apiConfig, basePath, axiosInstance),
  orders: new OrdersApi(apiConfig, basePath, axiosInstance),
  paymentChannel: new PaymentChannelsApi(apiConfig, basePath, axiosInstance),
  payments: new PaymentsApi(apiConfig, basePath, axiosInstance),
  productCatalog: new ProductCatalogApi(apiConfig, basePath, axiosInstance),
  products: new ProductsApi(apiConfig, basePath, axiosInstance),
  quizzes: new QuizzesApi(apiConfig, basePath, axiosInstance),
  registrationCode: new RegistrationCodeApi(apiConfig, basePath, axiosInstance),
  subscriptions: new SubscriptionsApi(apiConfig, basePath, axiosInstance),
  topics: new TopicApi(apiConfig, basePath, axiosInstance),
  users: new UserprofilesApi(apiConfig, basePath, axiosInstance),
  videoPlayback: new VideoplaybackApi(apiConfig, basePath, axiosInstance),
  voucherCode: new VoucherCodeApi(apiConfig, basePath, axiosInstance),
  webinar: new WebinarApi(apiConfig, basePath, axiosInstance),
});

export const setupMockAPI = () => ({});

export type ApiModules = ReturnType<typeof setupAPI>;

export default {
  install: (app: App) => {
    const modules = setupAPI();
    app.config.globalProperties.$api = modules;
  },
};
