import { GetCartByIdData, CalculateCartOrderData } from '@kunciehub/api-v2-web-js-client';
import { CartCredentialPayload, setCartId, setCartToken } from '@/utils/helpers/cart';
import type { LoadState, State } from './state';

export const SET_CART_CREDENTIAL = 'SET_CART_CREDENTIAL';
export const SET_CART_INFO = 'SET_CART_INFO';
export const SET_LOAD_STATE = 'SET_LOAD_STATE';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';

export default {
  [SET_CART_CREDENTIAL](state: State, payload: CartCredentialPayload) {
    state.cartId = payload.id;
    state.cartToken = payload.token;
    setCartId(payload.id);
    setCartToken(payload.token);
  },
  [SET_CART_INFO](state: State, payload: GetCartByIdData) {
    state.cartInfo = payload;
  },
  [SET_LOAD_STATE](state: State, payload: LoadState) {
    state.loadState = payload;
  },
  [SET_ORDER_DATA](state: State, payload: CalculateCartOrderData) {
    state.orderData = payload;
  },
};
